import { Injectable } from "@angular/core";
import { Service } from "../../../shared/service/service";
import { HttpClient } from "@angular/common/http";
import { Response } from "../../../shared/model/response";
import { MarketAnalysisService } from "./market-analysis.service";
import { BehaviorSubject, Observable } from "rxjs";
import { analysis, stepsInfo } from "../models/analysis";
import * as _ from "lodash";
import { AnalysisChart } from "../models/market-characteristics/analysis-chart.model";
import { MatDialog } from "@angular/material/dialog";
import { CopyableInfoDialogComponent } from "../../moco-dashboard/copyable-info-dialog/copyable-info-dialog.component";
import { FormGroup } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { RenameAnalysisComponent } from "../../moco-dashboard/rename-analysis/rename-analysis.component";
import { map } from "rxjs/operators";

export class AnalysisButton {
  isEnableEditButton?: boolean;
  editAnalysis?: boolean;
}
@Injectable({
  providedIn: "root",
})
export class MarketAnalysisStore extends Service {
  public analysisButton: BehaviorSubject<AnalysisButton> = new BehaviorSubject({
    isEnableEditButton: false,
    editAnalysis: false,
  });

  public videoPlayer: BehaviorSubject<any> = new BehaviorSubject({
    isPlayable: false,
    step: ""
  });

  public updateChart: BehaviorSubject<any> = new BehaviorSubject({
    isUpdate: false,
    data: [],
  });
  public updateChartDataSet: BehaviorSubject<any> = new BehaviorSubject({
    type: "",
    isRealTime: false,
    graph: [],
  });

  public stepperChange: BehaviorSubject<any> = new BehaviorSubject({});

  public marketCharacteristics: BehaviorSubject<any> = new BehaviorSubject({});
  public analysisChartsSubject: BehaviorSubject<any> = new BehaviorSubject({});
  public analysisChartsPageSubject: BehaviorSubject<any> = new BehaviorSubject(
    {}
  );
  public loadingSubject: BehaviorSubject<any> = new BehaviorSubject({});

  public readonly analysisCharts: Observable<any[]> =
    this.analysisChartsSubject.asObservable();
  public readonly analysisChartsPage: Observable<any[]> =
    this.analysisChartsPageSubject.asObservable();
  public readonly loading: Observable<boolean> =
    this.loadingSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private marketAnalysisService: MarketAnalysisService,
    private dialog?: MatDialog
  ) {
    super();
  }
  currentStep(key) {
    return this.getItemFromStorage(key);
  }

  setCurrentStep(key, analysis) {
    this.setItemToStorage(key, analysis);
  }

  storeAnalysis(value) {
    return this.post(value).subscribe((resposne: Response) => {});
  }

  getAnalysis(data) {
    this.marketCharacteristics.next({});
    return this.marketAnalysisService
      .getAnalysis(data)
      .subscribe((resposne: any) => {
        this.marketCharacteristics.next(resposne);
      });
  }

  getAnalysisCharts(
    q = "",
    date = "",
    end = "",
    anyId = "",
    page = 1,
    size = 5,
    silent = false
  ) {
    if (!silent) {
      this.loadingSubject.next(true);
    }
    return this.marketAnalysisService
      .getAnalysisCharts(q, date, end, anyId, page, size)
      .subscribe((resposne: any) => {
        if (!silent) {
          this.loadingSubject.next(false);
        }

        this.analysisChartsPageSubject.next(resposne?.analysisPage);
        let arr = resposne.data;
        if (arr !== undefined && arr.length) {
          arr = arr.map((attr) => new AnalysisChart(attr));
        }
        // if(arr.length){
        //   arr.sort((a,b) => (a['createdStrtotime'] > b['createdStrtotime'] ? -1 : 1));
        // }

        this.analysisChartsSubject.next(arr);
      });
  }

  storeAllAnalysis(response, mode, stateInfo = null) {
    analysis["aMode"] = mode;
    if (stateInfo) {
      analysis["currentTab"] = stateInfo["currentTab"];
      analysis["current"] = stateInfo["current"];
      analysis["stepsInfo"] = stateInfo["stepsInfo"];
    }

    if (!stateInfo && response.analysis.stepNumber > -1) {
      analysis["current"] = response.analysis.stepNumber - 1;
      analysis["currentTab"] = stepsInfo[analysis["current"]]["tab"];
    }

    analysis["parentAnalysis"] = response.analysis;
    analysis["analysis"] = response.analysis;
    analysis["marketCharacteristics"]["location"] = response.location;
    analysis["marketCharacteristics"]["attractiveness"] =
      response.attractiveness;

    analysis["marketFit"]["attractiveness"] = response.marketFit;
    analysis["moAnalysis"]["graph"] = response.analysis.moAnalysis;
    analysis["mocoAnalysis"]["graph"] = response.analysis.moAnalysis;
    analysis["costs"]["estimated"] = response.categories;
    analysis["costs"]["goal"] = response.analysis.costGoal;
    return analysis;
  }

  mapGraphDataToLocation(locations, moAnalysis, type = "attractiveness") {
    return moAnalysis.map((graph: any) => {
      /**check if moAnalysis already exist in case of edit */
      const filteredGraph = _.filter(locations, { name: graph.name });
      /**Set graph data if moAnalysis already exit */
      if (filteredGraph.length) {
        const location = filteredGraph[0];

        if (type === "attractiveness") {
          graph.attractiveness = location.attractiveness;
          graph.marketSize = location.marketSize;
        }

        if (type === "marketFit") {
          graph.marketFit = location.marketFit;
        }
      }
      return graph;
    });
  }

  analysisFromStorage() {
    return this.getItemFromStorage("analysis");
  }

  getValue(value) {
    return value ? value : 0;
  }

  isViewMode() {
    const analysis = this.getItemFromStorage("analysis");
    if (analysis && analysis["aMode"] === "view") {
      return true;
    }

    return false;
  }

  copyAbleConfirmation(analysis) {
    this.dialog.open(CopyableInfoDialogComponent, {
      data: analysis,
      width: "400px",
    });
  }

  renameAnalysis(analysis) {
    const dialogRef = this.dialog.open(RenameAnalysisComponent, {
      data: analysis,
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((response) => {
      console.log(response);
    });
  }

  patchCopyableInfo(form: FormGroup, component = null) {
    const patchCopyData = this.getItemFromStorage("stored-info");
    if (patchCopyData) {
      console.log(patchCopyData);

      this.patchValue(form, patchCopyData, component);

      setTimeout(() => {
        this.removePreparedData();
      }, 1000);
      return patchCopyData;
    }

    return;
  }

  removePreparedData() {
    environment.storageType.removeItem("stored-info");
  }

  patchValue(form, value, component = null) {
    if (
      value["tab"] === "mf-attractiveness" ||
      value["tab"] === "attractiveness"
    ) {
      let attr = value["data"]["attractiveness"].map((attr) => {
        delete attr.id;
        return attr;
      });
      form.patchValue({
        attractiveness: attr,
      });
    } else if (value["tab"] === "location") {
      let location = value["data"]["location"].map((attr) => {
        delete attr.id;
        return attr;
      });
      form.patchValue({
        location: location,
      });
      console.log(location);
    } else if (
      value["tab"] === "rate-every-market" ||
      value["tab"] === "mf-rate-every-market"
    ) {
      if (
        component === "compare-markets" ||
        component === "mf-compare-market"
      ) {
        let cmData = value["child"]["data"]["compareMarkets"].map((el) => {
          delete el.id;
          return el;
        });

        form.patchValue({
          compareMarkets: cmData,
        });
      } else if (
        component === "rate-every-market" ||
        component === "mf-rate-every-market"
      ) {
        let rateEveryMarket = value["data"]["rateMarkets"].map((el) => {
          delete el.id;
          el.country = el.country.map((el) => {
            delete el.id;
            return el;
          });
          return el;
        });

        form.patchValue({
          rateMarkets: rateEveryMarket,
        });
      }
    } else if (value["tab"] === "your-goal") {
      form.patchValue(value["data"]);
    } else if (value["tab"] === "estimated-costs") {
      let categories = value["data"]["categories"].map((category) => {
        delete category.id;
        category.subCategories = category.subCategories.map((sc) => {
          delete sc.id;
          if (!sc.name) {
            // sc.isDisabled = false;
          }
          sc.countries = sc.countries.map((c) => {
            delete c.id;
            return c;
          });
          return sc;
        });
        return category;
      });

      let unforeseen = value["data"]["unforeseen"];
      unforeseen.countries = unforeseen.countries.map((country) => {
        delete country.id;
        return country;
      });

      form.patchValue({
        categories: categories,
        unforeseen: unforeseen,
      });
    } else if (value["tab"] === "analysis-report") {
      form.patchValue(value["data"]);
    }
  }

  getRecentAnalysis() {
    this.loadingSubject.next(true);
    let url = `${environment.apiUrl}api/recent-analysis`;
    return this.httpClient.get(url).pipe(
      map((response: Response) => {
        this.loadingSubject.next(false);
        return response;
      })
    );
  }
}
