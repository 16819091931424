export class GraphConfig {
  label: {
    xLabel: string;
    yLabel: string;
  };
  showPointLabel: boolean;
  height: number;
  width: number;
  play: boolean
}

export const graphConfig = {
  label: {
    xLabel: 'Market Fit',
    yLabel: 'Market attractiveness'
  },
  showPointLabel: true,
  height: 260,
  width: 373,
  play: false,
};
