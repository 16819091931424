import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-check-data-loss-modal',
  templateUrl: './check-data-loss-modal.component.html',
  styleUrls: ['./check-data-loss-modal.component.scss']
})
export class CheckDataLossModalComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();

  analysisName: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CheckDataLossModalComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.analysisName = this.data.analysisName;
  }

  cancel() {
    this.dialogRef.close();
  }

  saveAndExit() {
    this.submitClicked.emit(true);
    this.dialogRef.close(true);
  }
}
