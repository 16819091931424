import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthorizationService } from '../../service/authorization.service';

@Component({
  selector: 'app-password-reset-email',
  templateUrl: './password-reset-email.component.html',
  styleUrls: ['./password-reset-email.component.scss']
})
export class PasswordResetEmailComponent implements OnInit {
  passwordRestForm: FormGroup;
  error: string = '';

  constructor(public authorizationService: AuthorizationService) {
    this.createPasswordResetForm();
  }

  ngOnInit(): void {}

  createPasswordResetForm() {
    this.passwordRestForm = new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  sendMail(value: string) {
    this.authorizationService.sendMail(value);

    console.log(value);
  }
}
