import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { GraphData } from '../components/mo-analysis-graph/graph-data.model';

@Injectable({
  providedIn: 'root'
})
export class D3GraphService {

  constructor() { }

  
  dataset = {
    children: [
      {
        name: 'ABC',
        children: [
          { Name: 'Olives', Count: 20104.333333333333334, X: 70, Y: 100 },
          { Name: 'Tea', Count: 12080.666666666666668, X: 320, Y: 100 },
          {
            Name: 'Mashed Potatoes',
            Count: 14107.333333333333336,
            X: 150,
            Y: 90
          },
          {
            Name: 'Boiled Potatoes',
            Count: 15034.66666666666667,
            X: 255,
            Y: 140
          },
          { Name: 'Tomato', Count: 25000.66666666666667, X: 200, Y: 300 }
        ]
      }
    ]
  };

  renderChart() {
    let diameter = 600;
    let height = 220;
    let width = 373;
    let width2 = 200;
    let color = d3.scaleOrdinal(d3.schemeCategory10);

    let bubble = d3
      .pack()
      .size([width, height])
      .padding(5);

    let svg = d3
      .select('.bubblechart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('class', 'bubble');

    let nodes = d3.hierarchy(this.dataset).sum(function(d: any) {
      return d.Count;
    });
    //  console.log(bubble(nodes).descendants());
    let node = svg
      .selectAll('.node')
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function(d) {
        return !d.children;
      })
      .append('g')
      .attr('class', 'node')
      .attr('transform', function(d:any) {
        console.log(d.data);

        return 'translate(' + d.data.X + ',' + d.data.Y + ')';
      })
      .style('fill', function(d, i: any) {
        return color(i);
      });

    node.append('title').text(function(d: any) {
      return d.Name + ': ' + d.Count;
    });

    node
      .append('circle')
      .attr('x', function(d) {
        return d.x;
      })
      .attr('y', function(d) {
        return d.y;
      })
      .attr('r', function(d) {
        return d.r;
      })
      .style('fill', function(d, i: any) {
        return color(i);
      });

    node
      .append('text')
      .attr('dy', '.2em')
      .style('text-anchor', 'middle')
      .text(function(d: any) {
        return d.data.Name.substring(0, d.r / 3);
      })
      .attr('font-family', 'sans-serif')
      .attr('font-size', function(d) {
        return d.r / 5;
      })
      .attr('fill', 'white');

    node
      .append('text')
      .attr('dy', '1.3em')
      .style('text-anchor', 'middle')
      .text(function(d: any) {
        return d.data.Count;
      })
      .attr('font-family', 'Gill Sans')
      .attr('font-size', function(d) {
        return d.r / 5;
      })
      .attr('fill', 'white');

    // d3.select(self.frameElement)
    //   .style("height", height + "px")
    //   .style("width", width2 + "px");;
  }
  
  data = {
    children: [
      {
        name: 'ABC',
        children: []
      }
    ]
  };

  prepaireData(_data) {
    let data = _data.map((data: GraphData) => {
      let _data = {
        Name: data.name,
        Count: data.pointRadius,
        X: data.x,
        Y: data.y,
        color: data.color
      };
      return _data;
    })

    this.data['children'][0]['children'] = data;

    let nodes = d3.hierarchy(this.data).sum(function(d: any) {
      return d.Count;
    });
    return nodes;
  }
}
