import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  OAuthService,
  JwksValidationHandler,
  AuthConfig
} from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NotifyService } from '../../shared/service/notify.service';
import { Service } from '../../shared/service/service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends Service {
  isLoggedIn = new BehaviorSubject(false);

  public redirectUrl: string;
  // Behaviour subjects
  private loadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private logOutLoadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject(
    false
  );
  private isAuthenticatedSubject: BehaviorSubject<any> = new BehaviorSubject(
    false
  );
  // Exposed API
  public readonly loading: Observable<
    Boolean
  > = this.loadingSubject.asObservable();
  public readonly logOutLoading: Observable<
    Boolean
  > = this.logOutLoadingSubject.asObservable();
  public readonly isAuthenticated: Observable<
    any
  > = this.isAuthenticatedSubject.asObservable();

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private httpClient: HttpClient,
    private notify: NotifyService
  ) {
    super();
  }

  public login(account: string, password: string) {
    const config = new AuthConfig();
    this.loadingSubject.next(true);
    this.isAuthenticatedSubject.next(false);
    this.oauthService.setStorage(this.getAuthStorage());
    config.clientId = ``;
    config.tokenEndpoint = `${environment.apiUrl}api/oauth/token`;
    config.scope = '';
    config.oidc = false;
    config.requireHttps = false;
    config.dummyClientSecret = ``;
    config.customTokenParameters = [];
    this.oauthService.configure(config);

    return this.oauthService
      .fetchTokenUsingPasswordFlow(account, password)
      .then(token => {
        this.isLoggedIn.next(true);
        // Redirect the user
        this.loadingSubject.next(false);
        this.isAuthenticatedSubject.next(true);
        const redirect = this.redirectUrl ? this.redirectUrl : '/dashboard';
        this.router.navigate([redirect]);
        return true;
      })
      .catch(err => {
        this.loadingSubject.next(false);
        this.isAuthenticatedSubject.next('error');
        return err;
      });
  }

  public logout() {
    const logOutUrl = `${environment.apiUrl}api/logout`;
    this.logOutLoadingSubject.next(true);

    return this.httpClient.post(logOutUrl, {}).subscribe(
      (response: any) => {
        if (response.message === 'success') {
          this.logOutLoadingSubject.next(false);
          this.deleteAccessToken();
        }
      },
      (err: HttpErrorResponse) => {
        this.logOutLoadingSubject.next(false);
        if (err.statusText === 'Unauthorized') {
          this.deleteAccessToken();
        }
      }
    );
  }

  deleteAccessToken(): void {
    const config = new AuthConfig();
    config.customTokenParameters = [];
    this.oauthService.configure(config);
    this.oauthService.logOut(true);
    // const storage = this.getAuthStorage();
    // storage.removeItem('analysis');
    // storage.removeItem('user-role');
    // window.location.href='/login';
    console.log('deleteAccessToken---');
    this.router.navigate(['/login']);
  }

  public register(value) {
    const url = `${environment.apiUrl}api/register`;
    this.loadingSubject.next(true);
    console.log(url);
    return this.httpClient.post(url, value);
  }

  public resetPassword(value) {
    const url = `${environment.apiUrl}api/reset-password`;
    return this.httpClient.post(url, value).subscribe(
      (response: any) => {
        this.notify.notifySuccess(response.message);

        if (response.code === 200) {
          this.router.navigate(['/login']);
        }
      },
      (err: HttpErrorResponse) => {
        if(err.status == 404) {
          this.notify.notifyError("Email id does not exist.");
        }
      }
    );
  }

  public sendMail(value) {
    const url = `${environment.apiUrl}api/send-mail`;

    return this.httpClient.post(url, value).subscribe(
      (response: any) => {
        this.notify.notifySuccess(response.message);
      },
      (err: HttpErrorResponse) => {}
    );
  }
}
