import { Component, OnInit,Renderer2,ElementRef, OnDestroy, ChangeDetectorRef, ViewChild } from "@angular/core";

import { FormBuilder } from '@angular/forms';
import { MediaMatcher } from '@angular/cdk/layout';
import moment from 'moment';
import { AuthorizationService } from "../../authentication/service/authorization.service";
import { SettingsService } from "../../shared/service/settings.service";
import { BehaviorSubject, from } from "rxjs";
import { environment } from "../../../environments/environment";
import { MarketAnalysisStore } from "../../moco/market-analysis/services/market-analysis.store";
import { AnalysisChart } from "../../moco/market-analysis/models/market-characteristics/analysis-chart.model";
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.scss"],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  @ViewChild('sidenav')
  public sidenav: MatSidenav;

  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('dropdowntogler') dropdowntogler: ElementRef;

  panelOpenState = true;
  public sidebarMinimized = false;
  public navItems = [];
  public navItemsDefault = [];
  public navItemsFinal = [];
  public totalNotifications = 0;
  public notifications = [];

  loggedIn: BehaviorSubject<boolean>;

  constructor(
    public auth: AuthorizationService,
    public settingService: SettingsService,
    public marketAnalysisStore: MarketAnalysisStore,
    private _formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private renderer: Renderer2
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 901px)');

    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.renderer.listen('window', 'click',(e:Event)=>{
      /**
       *        * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      let target: any = e.target
     if(target?.parentElement !== this.dropdowntogler?.nativeElement){
      if(this.dropdown.nativeElement.classList.contains('open')) {
        this.dropdown.nativeElement.classList.remove('open')
        this.dropdown.nativeElement.classList.remove('show')
      }
     }
 });

  }
  shouldRun = true; // /(^|.)(stackblitz|webcontainer).(io|com)$/.test(window.location.host);
  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });

  apiUrl = environment.apiUrl + "storage/";
  oldPics = "assets/img/avatars/6.png";
  pics = "assets/img/avatars/6.png";
  allAnlysis: AnalysisChart[] = [];
  showNav = false;
  breadcrumbTitle: string = "";

  ngOnInit() {

    this.settingService.getSettings();
    this.loggedIn = this.auth.isLoggedIn;

    this.settingService.settings.subscribe((response) => {
      /**
       * Display nav bar accourding to roles
       */

      if (Object.keys(response).length) {
        if (response.notifications) {
          this.totalNotifications = response.notifications.length;
          this.notifications = response.notifications;
        }

        this.marketAnalysisStore.setItemToStorage("user-role", response.role);
        this.pics =
          response?.pics !== undefined && response?.pics != ""
            ? this.apiUrl + response?.pics
            : this.oldPics;
        this.navItemsDefault =
          this.settingService.displayNavBarAccourdingToRole(response.role);
        this.allAnlysis = response["navAnalysis"]; // analysis; //navAnalysis
        this.navItemsFinal = [];
        this.navItems = [];
        this.navItems = this.navItemsDefault;
        if (
          (response["showStats"] == "C" || response["showStats"] == "coach") &&
          this.allAnlysis?.length
        ) {
          if (this.marketAnalysisStore.isCoach()) {
            this.navItems = this.prepareCoachOrg(
              this.allAnlysis,
              this.navItems
            );
            this.navItems = [
              ...this.navItems,
              ...this.settingService.navBelow(),
            ];
          } else {
            this.navItems = this.prepareAnalysis(
              this.allAnlysis,
              this.navItems
            );
          }
        }
        this.navItemsFinal = this.navItems;

      }
      this.showNav = true;
    });
  }
  private prepareCoachOrg(allAnlysis, navItems = []) {
    allAnlysis.map((org) => {
      let nav = [
        {
          name: "Analysis not found",
          icon: "icon-info",
          url: "javascript.void(0)",
        },
      ];
      if (org.analyses.length) {
        nav = this.prepareAnalysis(org.analyses, [], org);
      }
      let navItem = {
        name: org.name,
        icon: "icon-user",
        url: "/analysis/" + org.id,
        children: nav,
      };
      navItems.push(navItem);
    });
    return navItems;
  }

  private prepareAnalysis(allAnlysis, navItems = [], org = null) {
    let orgQs = org ? '/' + org.id : '';
    allAnlysis.map((res) => {
      let viewType = "view";
      let viewTypeName = "View most recent";
      if (this.settingService.isCompany() && res?.status === "in_progress") {
        viewType = "new";
        viewTypeName = "Complete analysis";
      }

      let newNav = {
        name: res?.name,
        icon: "icon-flag",
        url: "/analysis/" + res?.id,
        children: [
          {
            name: viewTypeName,
            url: "/get-analysis/" + viewType + "/" + res?.id + orgQs,
          },
          {
            name: "History",
            url: "/admin/analyses/" + res?.id,
            // href: '/admin/analyses/' + res?.id
          },
        ],
      };

      if (res?.status === "in_progress") {
        newNav = {
          name: res?.name,
          icon: "icon-flag",
          url: "/analysis/" + res?.id,
          // href: '/analysis/' + res?.id,
          children: [
            {
              name: viewTypeName,
              url: "/get-analysis/" + viewType + "/" + res?.id + orgQs,
            },
          ],
        };
      }

      navItems.push(newNav);
    });

    return navItems;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  myAccount() { }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  humanizeDate(date) {
    return moment(date).fromNow();
  }

  markAsRead(notification) {
    console.log(notification);
  }

  ngAfterViewInit() {
    if (this.mobileQuery.matches) {
      this.sidenav.close();
    }
  }

  toggleSidemenu(itemEl: HTMLElement, toogleBtn: HTMLElement) {
    itemEl.classList.toggle("active");
    toogleBtn.classList.toggle("active");
  }

  toggleDropdownMenu(dropdownmenu: HTMLElement) {
    dropdownmenu.classList.toggle("open");
    dropdownmenu.classList.toggle("show");
  }
}
