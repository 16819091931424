import { Injectable } from "@angular/core";
import { Service } from "../../../shared/service/service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Response } from "../../../shared/model/response";
import { map, catchError } from "rxjs/operators";
import { Country } from "../models/market-characteristics/country.model";
import { Attractiveness } from "../models/market-characteristics/attractiveness.model";
import { Router } from "@angular/router";
import { of, empty, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MarketAnalysisService extends Service {
  public analysisRenamed: BehaviorSubject<any> = new BehaviorSubject("");

  constructor(private httpClient: HttpClient, private router: Router) {
    super(`${environment.apiUrl}api/analysis`, httpClient);
  }
  currentStep(key) {
    return this.getItemFromStorage(key);
  }

  setCurrentStep(key, analysis) {
    this.setItemToStorage(key, analysis);
  }

  storeAnalysis(value) {
    return this.post(value);
  }

  getAnalysis(data) {
    return this.get(data).pipe(
      map((response: Response) => {
        const analysis = response.data;
        const data = {
          location: analysis.locations.map((location) => new Country(location)),
          attractiveness: analysis.attractiveness.map(
            (attr) => new Attractiveness(attr)
          ),
          marketFit: analysis.marketFit,
          categories: analysis.categories,
          analysis: analysis.analysis,
          moAnalysis: analysis.moAnalysis,
          newAnalysis: analysis.newAnalysis,
          steps: analysis.steps,
        };

        try {
          if (data.steps.length) {
            this.setItemToStorage("analysisSteps", data.steps);
          }
        } catch (error) {}
        return data;
      })
    );
  }

  getAnalysisCharts(
    q = "",
    date = "",
    end = "",
    anyId = "",
    page = 1,
    size = 5
  ) {
    let url = `${environment.apiUrl}api/analysis-charts?page=${page}&size=${size}`;
    if (anyId !== "" && anyId !== null) {
      url = url + `&id=${anyId}`;
    }
    if (q != "") {
      url = url + `&q=${q}`;
    }
    if (date != "" && end != "") {
      url = url + `&d=${date}&end=${end}`;
    }
    return this.httpClient.get(url).pipe(
      map((response: Response) => {
        return response.data;
        // return response.data.data.map(
        //   analysisChart => new AnalysisChart(analysisChart)
        // );
      })
    );
  }

  changeStepper() {
    let _analysis = this.getItemFromStorage("analysis");

    // Set next step to compare-markets
    _analysis["current"] = +_analysis["current"] + 1;
    this.setCurrentStep("analysis", _analysis);
  }

  checkIfStorageHaveAnalysis() {
    const _analysis = this.getItemFromStorage("analysis");

    /**Check if analysis already created or not */
    if (!_analysis || (_analysis && !_analysis["analysis"])) {
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/analysis"]);
    }
  }

  exit(url = "/dashboard") {
    this.removeItem("analysis");
    this.router.navigate([url]);
  }

  checkForFirstRow(attractiveness, index) {
    const attr = attractiveness.at(index);

    if (attr && attr.value.parameterOfHomeMarket && attr.value.weightFactor) {
      return true;
    }

    return false;
  }

  changeDataForPatch(data) {
    if (data?.length) {
      let modified = data.map((res) => {
        return { id: res?.id };
      });
      return modified;
    }
    return data;
  }

  checkAnalysisName(value) {
    let url = `${environment.apiUrl}api/check-name`;
    return this.httpClient
      .post(url, {
        name: value,
      })
      .pipe(
        map((response: Response) => {
          return response.data;
        })
      );
  }

  renameAnalysisName(value) {
    let url = `${environment.apiUrl}api/rename-analysis`;
    return this.httpClient.post(url, value).pipe(
      map((response: Response) => {
        return response.data;
      })
    );
  }

  markAsRead(notification) {
    console.log(notification);
    let url = `${environment.apiUrl}api/markasread`;
    return this.httpClient
      .post(url, { id: notification })
      .pipe(
        map((response: Response) => {
          return response.data;
        })
      )
      .subscribe((response) => {
        console.log(response);
      });
  }
}
