import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-timmer',
  templateUrl: './timmer.component.html',
  styleUrls: ['./timmer.component.scss']
})
export class TimmerComponent implements OnInit {
  timeCounter: string = '0:00:00';

  @Input() startDateTime;
  constructor() {}

  ngOnInit(): void {
    this.timmer(this.startDateTime);
  }

  startTime(time) {
    return new Date(time).getTime();
  }

  currentTime() {
    return new Date().getTime();
  }

  timmer(startDateTime) {
    console.log(startDateTime);
    if (!startDateTime) {
      return;
    }
    const startTime = this.startTime(startDateTime);
    const self = this;
    const x = setInterval(function() {
      const now = self.currentTime();
      const t = now - startTime;
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((t % (1000 * 60)) / 1000);
      let hPrefix = '';
      if (hours.toString().length === 1) {
        hPrefix = '0';
      }

      let mPrefix = '';
      if (minutes.toString().length === 1) {
        mPrefix = '0';
      }

      let sPrefix = '';
      if (seconds.toString().length === 1) {
        sPrefix = '0';
      }

      self.timeCounter = `${hPrefix + hours}:${mPrefix + minutes}:${sPrefix +
        seconds}`;
      if (t < 0) {
        clearInterval(x);
        self.timeCounter = '0:00:00';
      }
    }, 1000);
  }
}
