import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Response } from '../../../../shared/model/response';

import { NotifyService } from '../../../../shared/service/notify.service';
import { CategoryAdmin } from '../../models/category-admin.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryAdminService {
  constructor(private httpClient: HttpClient,
    private notify: NotifyService) {}

  getCategorys(q = '') {
    const url = `${environment.apiUrl}api/categorys?q=${q}`;
    return this.httpClient.get(url).pipe(
      map((response: Response) => {
        return response.data.map(category => new CategoryAdmin(category));
      })
    );
  }
  
  saveCategorys(q = '',value) {
    const url = `${environment.apiUrl}api/categorys-admin?q=${q}`;
    return this.httpClient.post(url,value).pipe(
      map((response: Response) => {
        this.notify.notifySuccess(response.message);
        return response.data.map(category => new CategoryAdmin(category));
      })
    );
  }
}
