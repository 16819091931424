export interface CompareMarketInterface {
  id: string;
  score0: string;
  score25: string;
  score50: string;
  score75: string;
  score100: string;
  cmAccuracy: number;
}
export class CompareMarket {
  id: string;
  score0: string;
  score25: string;
  score50: string;
  score75: string;
  score100: string;
  cmAccuracy: number;

  constructor(
    cm: CompareMarketInterface = {
      id: '',
      score0: '',
      score25: '',
      score50: '',
      score75: '',
      score100: '',
      cmAccuracy: 0
    }
  ) {
    this.id = cm.id;
    this.score0 = cm.score0;
    this.score25 = cm.score25;
    this.score50 = cm.score50;
    this.score75 = cm.score75;
    this.score100 = cm.score100;
    this.cmAccuracy = cm.cmAccuracy;
  }
}
