import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EventData } from '../../../models/analysis';
import { MarketAnalysisService } from '../../../services/market-analysis.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MarketAnalysisStore } from '../../../services/market-analysis.store';

@Component({
  selector: 'app-your-goal',
  templateUrl: './your-goal.component.html',
  styleUrls: ['./your-goal.component.scss']
})
export class YourGoalComponent implements OnInit {
  @Output() onStepperChange: EventEmitter<EventData> = new EventEmitter<
    EventData
  >();
  @Input() type: string = 'your-goal';

  initialSteps: EventData = {
    stepNumber: 7,
    locations: 0,
    stateChanged: false
  };

  report;
  currentTab = 'costs';
  costsData;

  costForm: FormGroup;
  isFormValueChanged: boolean = false;
  prevData: any;

  constructor(
    private marketAnalysis: MarketAnalysisService,
    private marketAnalysisStore: MarketAnalysisStore
  ) {
    this.createCostForm();
  }

  ngOnInit(): void {
    if(this.type === "your-goal" ) {
      this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "your-goal"});
    }

    this.onStepperChange.emit(this.initialSteps);
    this.costsData = this.marketAnalysis.getItemFromStorage('analysis');
    if (this.costsData[this.currentTab]) {
      this.costForm.patchValue(this.costsData[this.currentTab]['goal']);
      this.marketAnalysisStore.patchCopyableInfo(this.costForm);

      this.prevData = this.costForm.value;
    }

    this.costForm.valueChanges.subscribe(res => {
      this.isFormValueChanged = true;
      if (this.costForm.dirty) {
        this.initialSteps.isFromDirty = true;
        this.initialSteps.isRefreshOnCancel = true;
        this.onStepperChange.emit(this.initialSteps);
      }
    });
  }

  ngOnDestroy() {
    if(this.type === "your-goal" ) {
      this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
    }
  }

  createCostForm() {
    this.costForm = new FormGroup({
      name: new FormControl('', [Validators.required])
    });
  }

  get name() {
    return this.costForm.get('name');
  }
  submitted: boolean = false;
  storeCostGoal(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }
    // if (!this.isFormValueChanged) {
    //   this.changeToNext();
    //   return false;
    // }
    this.submitted = true;
    console.log('goal', this.costForm.value);
    if (!this.costForm.valid) {
      return false;
    }

    const market = {
      goal: this.costForm.value,
      type: 'costGoal',
      prevData: this.prevData,
      nextData: this.costForm.value,
      analysisId: this.costsData['analysis']['id'],
      mode: this.costsData['aMode'],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1,
      isStoreSilently: isStoreSilently
    };
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      if (isRedirect) {
        this.marketAnalysis.exit();
        return;
      }
      this.changeToNext(response.data.analysis.costGoal, isStoreSilently);
    });
  }

  changeToNext(data = null, isStoreSilently = false) {
    let _analysis;
    if (data) {
      this.costsData[this.currentTab]['goal'] = data;
    }

    if (!isStoreSilently) {
      this.costsData['currentTab'] = 'costs';
      this.costsData['current'] = +this.costsData['current'] + 1;
    }

    _analysis = this.costsData;

    this.marketAnalysis.setCurrentStep('analysis', _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  storeDataSilently() {
    this.initialSteps.isFormChanged = true;
    this.onStepperChange.emit(this.initialSteps);
    this.storeCostGoal(false, true);
  }
}
