import { RoleService } from '../../shared/service/role.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

export class Service extends RoleService {
  constructor(private url?: string, private http?: HttpClient) {
    super();
  }

  get(queryString?): Observable<any> {
    return this.http.get(`${this.url}?${queryString}`).pipe(
      tap((stories: any) => {
        return stories;
      }),
      catchError(this.handleError)
    );
  }

  post(resource) {
    return this.http.post(this.url, resource).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  patch(resource) {
    return this.http.patch(this.url + '/' + resource.id, { isRead: true }).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  show(id) {
    return this.http.get(this.url + '/detail/' + id).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  _delete(id) {
    return this.http.delete(this.url + '/' + id).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    if (error.status === 400) return throwError(error.json());

    if (error.status === 404) return throwError(error);

    return throwError(error);
  }

  objToQs(data) {
    let str = [];
    for (let p in data)
      if (data.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
      }
    return str.join('&');
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
      );
    }
    throw new Error("Bad Hex");
  }
}
