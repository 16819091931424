// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  border-bottom: 1px solid #efefef !important;
  padding: 0.75rem !important;
}

.table td {
  min-width: 250px;
}

.same-height {
  width: 600px;
  min-height: 600px;
  margin: auto;
}
@media only screen and (max-width: 991.98px) {
  .same-height {
    width: auto;
    height: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/moco/market-analysis/components/mo-analysis/mo-analysis.component.scss"],"names":[],"mappings":"AAEA;EACI,2CAAA;EACA,2BAAA;AADJ;;AAOI;EACI,gBAAA;AAJR;;AAQA;EACI,YAAA;EACA,iBAAA;EACA,YAAA;AALJ;AAOI;EALJ;IAMQ,WAAA;IACA,YAAA;EAJN;AACF","sourcesContent":["@import \"../../../../../scss/variables\";\r\n\r\n.card-header {\r\n    border-bottom: 1px solid $inputbg !important;\r\n    padding: .75rem !important\r\n}\r\n\r\n\r\n.table {\r\n\r\n    td {\r\n        min-width: 250px;\r\n    }\r\n}\r\n\r\n.same-height {\r\n    width: 600px;\r\n    min-height: 600px;\r\n    margin: auto;\r\n\r\n    @media #{$mq-991-up} {\r\n        width: auto;\r\n        height: auto;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
