export interface MocoAnalysisInterface {
  name: string;
  marketFit: number;
  attractiveness: number;
  marketSize: number;
  color: string;
  total: number;
  totalTax: number;
}

export class MocoAnalysis {
  name: string;
  marketFit: number;
  attractiveness: number;
  marketSize: number;
  color: string;
  total: number;
  opportunity: number;
  constructor(
    analysis: MocoAnalysisInterface = {
      name: "",
      marketFit: 0,
      attractiveness: 0,
      marketSize: 0,
      color: "",
      total: 0,
      totalTax: 0,
    }
  ) {
    this.name = analysis.name;
    this.marketFit = analysis.marketFit;
    this.attractiveness = analysis.attractiveness;
    this.marketSize = analysis.marketSize;
    this.color = analysis.color;
    this.total = analysis.total ? analysis.total : 0;
    this.opportunity = (analysis.attractiveness + analysis.marketFit) / 2;
  }
}
