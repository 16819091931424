import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal'; //BsModalRef
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  constructor(public modalService: BsModalService,
    // public bsModalRef: BsModalRef,
    private dialog?: MatDialog,
  ) { }

  open(component, id, type = '', title = '') {
    const initialState = {
      identifier: id,
      type: type,
      title: title
    };

    //this.modalService.show(component, { initialState });

    let widthType = '600px';
    if (type === 'analysis') {
      widthType = '550px';
    }

    return this.dialog.open(component, {
      width: widthType,
      data: initialState
    });

  }

  close() {
    //  this.bsModalRef.hide()
    this.modalService.hide(1);
    document.body.classList.remove('modal-open');
  }
}
