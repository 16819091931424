import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { OAuthService } from "angular-oauth2-oidc";
import { Service } from "../../shared/service/service";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private route: ActivatedRoute
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.oauthService.setStorage(new Service().getAuthStorage());
    const token = this.oauthService.getAccessToken();
    let org = this.route.snapshot.paramMap.get("org");

    console.log({org});
    this.route.queryParams.subscribe((params) => {
      org = params.org;
    });
    if (token) {
      let reqHead = req.headers.append("Authorization", "Bearer " + token);
      if (org) {
        reqHead = reqHead.append("organization", org);
      }
      req = req.clone({
        headers: reqHead,
      });
    }
    return next.handle(req);
  }
}
