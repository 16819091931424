import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { MarketAnalysisStore } from "../../services/market-analysis.store";
import {
  EventData,
  stepsInfo,
  CurrentStepInfo,
  component,
} from "../../models/analysis";
import { MarketAnalysisService } from "../../services/market-analysis.service";
import { LocationComponent } from "../market-characteristics/location/location.component";
import { McAttractivenessComponent } from "../market-characteristics/mc-attractiveness/mc-attractiveness.component";
import { McCompareMarketsComponent } from "../market-characteristics/mc-compare-markets/mc-compare-markets.component";
import { RateEveryMarketComponent } from "../market-characteristics/rate-every-market/rate-every-market.component";
import { MfAttractivevenessComponent } from "../market-fit/mf-attractiveness/mf-attractiveness.component";
import { MfCompareMarketComponent } from "../market-fit/mf-compare-market/mf-compare-market.component";
import { MfRateEveryMarketComponent } from "../market-fit/mf-rate-every-market/mf-rate-every-market.component";
import { MoAnalysisComponent } from "../mo-analysis/mo-analysis.component";
import { YourGoalComponent } from "../costs/your-goal/your-goal.component";
import { EstimatedCostsComponent } from "../costs/estimated-costs/estimated-costs.component";
import { MocoAnalysisComponent } from "../moco-analysis/moco-analysis.component";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { CheckDataLossModalComponent } from "../check-data-loss-modal/check-data-loss-modal.component";
import { LoaderService } from "../../../../shared/components/loader/loader.service";
import { Subscription } from "rxjs";
import { AnalysisReportComponent } from "../analysis-report/analysis-report.component";
import { EditConfirmationModalComponent } from "../edit-confirmation-modal/edit-confirmation-modal.component";
import { LoggerService } from "../../../../logger/logger.service";
import * as _ from "lodash";
import { environment } from "../../../../../environments/environment";
import { SettingsService } from "../../../../shared/service/settings.service";
import { VideoPlayerComponent } from "../../../../shared/components/video-player/video-player.component";

import { Overlay } from "@angular/cdk/overlay";
import * as $ from "jquery";



@Component({
  selector: "app-market-characteristics",
  templateUrl: "./market-analysis.component.html",
  styleUrls: ["./market-analysis.component.scss"],
})
export class MarketAnalysisComponent implements OnInit, OnDestroy {
  /** Initialize child component */
  @ViewChild(LocationComponent) private lc: LocationComponent;
  @ViewChild(McAttractivenessComponent) private mca: McAttractivenessComponent;
  @ViewChild(McCompareMarketsComponent) private mcmc: McCompareMarketsComponent;
  @ViewChild(RateEveryMarketComponent) private remc: RateEveryMarketComponent;
  @ViewChild(MfAttractivevenessComponent)
  private mfa: MfAttractivevenessComponent;
  @ViewChild(MfCompareMarketComponent)
  private mfcc: MfCompareMarketComponent;
  @ViewChild(MfRateEveryMarketComponent)
  private mfremc: MfRateEveryMarketComponent;

  @ViewChild(MoAnalysisComponent)
  private moac: MoAnalysisComponent;

  @ViewChild(YourGoalComponent)
  private ygc: YourGoalComponent;

  @ViewChild(EstimatedCostsComponent)
  private ecc: EstimatedCostsComponent;

  @ViewChild(MocoAnalysisComponent)
  private mocoAnalysis: MocoAnalysisComponent;

  @ViewChild(AnalysisReportComponent) private arc: AnalysisReportComponent;
  /**Initialize variables */
  _analysis;

  isNavigateTo: boolean = false;

  info: CurrentStepInfo = {
    tolalLocations: 0,
    stepNumber: 0,
    isSubmitDisabled: false,

    currentTab: "",
    currentStep: "location",
    currentStateInfo: "",
    prevStep: "",
    mode: "new",
    isContentCollapsed: false,
    isFormChanged: true,
    maxStepNumber: 0,
  };

  isRefreshOnCancel: boolean = true;
  isFormDirty: boolean = false;
  isCostDisabled: boolean = true;
  analysisId: string = "";
  displayAnalysisEditButton: boolean = false;
  silentlyStoreLoader: Subscription;

  stepAnalysis; //:Steps[];

  navigationSubscription: Subscription;
  settingServiceSubject: Subscription;

  constructor(
    private marketAnalysisStore: MarketAnalysisStore,
    public marketAnalysis: MarketAnalysisService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private cdRef: ChangeDetectorRef,
    private logger: LoggerService,
    public settingService: SettingsService,
    private overlay: Overlay
  ) {}

  ngOnInit(): void {
    /**refresh component without reload */
    this.isRefreshOnCancel = true;

    this.settingServiceSubject = this.settingService.settings.subscribe(
      (response) => {
        this.isCostDisabled = response.isCostDisabled;
        let analysis = this.getAnalysis();
        console.log(this.isCostDisabled, this.getAnalysis());
        if(this.isCostDisabled && analysis['current'] > 6) {
          this.changeTab(6);
          
        }
      }
    );

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.info.isFormChanged = true;
    this._analysis = this.getAnalysis();
    if (!this._analysis) {
      this.marketAnalysisStore.removeItem("analysis");
      this.router.navigate(["/dashboard"]);
    }

    this.setCurrentStep();
    let aId = this.route.snapshot.paramMap.get("id");
    let mode = this.route.snapshot.paramMap.get("mode");
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._analysis = this.getAnalysis();
        aId = this.route.snapshot.paramMap.get("id");
        mode = this.route.snapshot.paramMap.get("mode");
      }
    });

    if (aId) {
      this.analysisId = aId;
    } else if (this._analysis && this._analysis["analysis"]) {
      /**Set title */

      this.analysisId = this._analysis["analysis"]["id"];
      this.info.currentTab = this._analysis["currentTab"];
      this.info.mode = this._analysis["aMode"];

      if (this.info.mode === "view") {
        this.marketAnalysisStore.analysisButton.next({
          isEnableEditButton: true,
        });
      }

      if (!this.isNavigateTo && this.info.currentTab !== "costs") {
        this.callGetAnalysis();
      }
    }
    /**store analysis to storage */

    /**Check params  */
    if (!this.info.isSubmitDisabled && !aId) {
      // this.marketAnalysis.checkIfStorageHaveAnalysis();
    }

    this.stepAnalysis =
      this.marketAnalysisStore.getItemFromStorage("analysisSteps");

    this.marketAnalysisStore.analysisButton.subscribe((response) => {
      this.displayAnalysisEditButton = response.isEnableEditButton;
    });

    this.autoPlayVideoAccourdingToTotalNumberOfVisility();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  copyableData: boolean = false;
  counter = 0;

  changeState(event: EventData) {
    this._analysis = this.getAnalysis();
    this.info.tolalLocations = event.locations;
    this.info.stepNumber = event.stepNumber;
    this.info.isSubmitDisabled = event.isSubmitDisabled;
    this.isFormDirty = event.isFromDirty;
    this.info.isFormChanged = true;
    if (event.stateChanged) {
      this.setCurrentStep();
    }

    if (this.isFormDirty) {
      if (event.isRefreshOnCancel === false) {
        this.isRefreshOnCancel = false;
      } else {
        this.isRefreshOnCancel = true;
      }
      this.confirmEdit();
    }
  }
  isEditConfirmModalOpened: boolean = false;

  confirmEdit() {
    if (
      this.info.mode === "view" &&
      !this.isEditConfirmModalOpened &&
      this.marketAnalysis.isCompany()
    ) {
      this.isEditConfirmModalOpened = true;
      const dialogRef = this.dialog.open(EditConfirmationModalComponent, {
        data: {},
        hasBackdrop: false,
        backdropClass: "edit-analysis-backdrop",
        width: "600px",
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.prepareDataForCopy();
          this.editAnalysis();
        } else {
          this.isEditConfirmModalOpened = false;
          if (this.isRefreshOnCancel) {
            this.router.navigated = false;
            this.router.navigate(["/analysis"], { skipLocationChange: true });
          }
        }
      });
    }
  }
  setCurrentStep() {
    this._analysis = this.getAnalysis();
    if (this._analysis) {
      this.info.currentTab = this._analysis["currentTab"];
      /**Current step now turned to prev step */
      // this.info.prevStep = this._analysis[this.info.currentTab]['prevStep'];
      /**check if current step is last step */

      /**Next step is now current step */
      let _stepsInfo = stepsInfo[this._analysis["current"]];

      this.info.currentStep = _stepsInfo.name;
      this.info.currentStateInfo = _stepsInfo.title;
      this.setContentCollapsed();
    }
  }

  previous() {
    /**Current step now turned to prev step */
    this.changeSteps(this._analysis["current"] - 1);
    /**don't call this api in view mode */
    if (this.info.mode !== "view") {
      /**back from estimated cost */
      if (this.info.currentStep === "your-goal") {
        this.ecc.storeEstimatedCosts();
      }
      this.callGetAnalysis();
    }
  }

  skipNext() {
    /**Next step is now current step */
    this.changeSteps(this._analysis["current"] + 1);
  }

  changeSteps(stepNumber: number) {
    this._analysis = this._analysis = this.getAnalysis();
    this.info.currentTab = this._analysis["currentTab"];
    /**Current step now turned to prev step */
    /**Next step is now current step */
    this._analysis["current"] = stepNumber;

    /**prevent back */
    if (this._analysis["current"] < 0) {
      return false;
    }

    console.log(
      this.isTabDisabled(),
      this._analysis["current"],
      stepsInfo.length
    );
    /**prevent next */

    // if(this.isTabDisabled() === (this._analysis["current"] > 5)) {
    //   this.marketAnalysisStore.removeItem("analysis");
    //   this.router.navigate(["/dashboard"]);
    //   return
    // }
    // if (!this.isTabDisabled() && this._analysis["current"] >= stepsInfo.length) {
    //   console.log("INININN");
    //   this.marketAnalysisStore.removeItem("analysis");
    //   this.router.navigate(["/dashboard"]);
    //   return;
    // }

    let _stepsInfo = stepsInfo[this._analysis["current"]];

    this.info.currentStep = _stepsInfo.name;
    this.info.currentStateInfo = _stepsInfo.title;
    this._analysis["currentTab"] = this.info.currentTab = _stepsInfo["tab"];

    /**don't call this api in view mode */
    if (!this.marketAnalysis.hasSuperAdmin()) {
      this.storeCurrentStepNo(stepNumber);
    }
    this.setContentCollapsed();

    this.marketAnalysisStore.setItemToStorage("analysis", this._analysis);

    this.marketAnalysisStore.stepperChange.next(this.info);
  }

  saveAndExit() {
    if (this.info.mode === "view") {
      this.marketAnalysisStore.removeItem("analysis");
      this.marketAnalysisStore.analysisButton.next({
        isEnableEditButton: false,
      });
      this.router.navigate(["/dashboard"]);
    }

    if (this.info.mode === "copy") {
      this.next(true, true);
    }
    this.logger.log({
      prevUrl: "/analysis",
      nextUrl: "/dashboard",
      ...this.info,
    });
  }

  next(isRedirect = false, isStoreSilently = false, callGetApi = null) {
    switch (this.info.currentStep) {
      case "location":
        this.lc.storeLocation(isRedirect, isStoreSilently);
        break;
      case "attractiveness":
        this.mca.storeAttractiveness(isRedirect, isStoreSilently);
        break;
      case "compare-markets":
        this.mcmc.storeCompareMarket(isRedirect, isStoreSilently);
        break;
      case "rate-every-market":
        this.remc.storeMarketRate(isRedirect, isStoreSilently);
        break;
      case "mf-attractiveness":
        this.mfa.storeMarketFitAttractiveness(isRedirect, isStoreSilently);
        break;
      case "mf-compare-market":
        this.mfcc.storeCompareMarket(isRedirect, isStoreSilently);
        break;
      case "mf-rate-every-market":
        this.mfremc.storeMarketRate(isRedirect, isStoreSilently);
        break;
      case "mo-analysis":
        this.marketAnalysisStore.getAnalysis(
          `q=${this.info.currentStep}&_a=${this.analysisId}`
        );
        if (this.isTabDisabled()) {
          isRedirect = true;
          isStoreSilently = true;
        }

        this.moac.storeMoAnalysisGraphData(isRedirect, isStoreSilently);
        break;
      case "your-goal":
        this.ygc.storeCostGoal(isRedirect, isStoreSilently);
        break;
      case "estimated-costs":
        this.ecc.storeEstimatedCosts(isRedirect, isStoreSilently);
        break;
      case "moco-analysis":
        this.mocoAnalysis.storeFinalAnalysis(isRedirect);
        break;
      case "analysis-report":
        this.arc.saveAnalysisReport(isRedirect, isStoreSilently);
        this.logger.log({
          prevUrl: "/analysis",
          nextUrl: "/dashboard",
          ...this.info,
        });
        break;
      // storeFinalAnalysis
      default:
        break;
    }
    if (!callGetApi) {
      this.callGetAnalysis();
    }
  }

  changeTab(step: number) {
    console.log(this._analysis, this.info.stepNumber, step);
    if (this.isTabSkippAble(step)) {
      if (this.info.stepNumber === 8) {
        this.ecc.storeEstimatedCosts(false, true);
      }
      this.callGetAnalysis();
      this.changeSteps(step - 1);
    }
  }

  isTabSkippAble(step: number) {
    if (this.info.mode === "view") {
      return true;
    } else if (this.info.mode === "copy") {
      return true;
    } else if (this.info.mode === "new") {
      let analysis = this.getAnalysis();
      let stepNumber = this.info.stepNumber;
      if (stepNumber === 7) {
        stepNumber = 8;
      }
      if (analysis) {
        if (step <= analysis["analysis"]["maxStepNumber"]) {
          return true;
        } else if (
          analysis["analysis"]["maxStepNumber"] <= stepNumber &&
          step <= stepNumber
        ) {
          analysis["analysis"]["maxStepNumber"] = stepNumber;
          this.setItemToStorage(analysis);
          return true;
        }
      }
    }

    return false;
  }

  isShowWarningPopup: boolean = true;

  editAnalysis() {
    console.log("editAnalysis");

    this.isShowWarningPopup = false;
    this.info.mode = "copy";
    this._analysis["aMode"] = this.info.mode;
    this.marketAnalysisStore.getAnalysis(
      `q=${this.info.currentStep}&_a=${this.analysisId}&mode=copy`
    );
    const stateInfo = this.getAnalysis();
    this.marketAnalysisStore.marketCharacteristics.subscribe((response) => {
      if (Object.keys(response).length) {
        // this._analysis['analysis'] = response.analysis;
        let _analysis = this.marketAnalysisStore.storeAllAnalysis(
          response,
          "copy",
          stateInfo
        );
        this.marketAnalysis.setItemToStorage("analysis", _analysis);
        // setTimeout(() => {
        //   /**reload is mendatory to patch data to form  */
        //   window.location.reload();
        // }, 1);
        window.location.href = "/analysis";
        // this.router.navigate(['/analysis']);
      }
    });
  }

  showContent() {
    this.info.isContentCollapsed = !this.info.isContentCollapsed;
    try {
      this._analysis["stepsInfo"][this._analysis["current"]]["isCollapsed"] =
        this.info.isContentCollapsed;
    } catch (error) {}

    this.marketAnalysis.setItemToStorage("analysis", this._analysis);
  }

  setContentCollapsed() {
    try {
      this.info.isContentCollapsed =
        this._analysis["stepsInfo"][this._analysis["current"]]["isCollapsed"];
    } catch (error) {}
  }

  getAnalysis() {
    return this.marketAnalysisStore.getItemFromStorage("analysis");
  }

  setItemToStorage(_analysis) {
    this.marketAnalysis.setItemToStorage("analysis", _analysis);
  }
  storeCurrentStepNo(stepNumber: number) {
    const market = {
      type: "storeStepNo",
      analysisId: this.analysisId,
      stepNumber: stepNumber + 1,
      isStoreSilently: true,
      mode: this._analysis["aMode"],
    };
    let analysis = this.getAnalysis();
    analysis["current"] = market.stepNumber;
    this.setItemToStorage(analysis);
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {});
  }

  checkUnsavedInfo(nextUrl: string = "") {
    this._analysis = this.getAnalysis();

    if (this._analysis) {
      const dialogRef = this.dialog.open(CheckDataLossModalComponent, {
        data: {
          analysisName: this._analysis["analysis"]["name"],
        },
        width: "600px",
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.loaderService.show();
          this.next(false, true, "dontcall");

          this.logger.log({
            prevUrl: "/analysis",
            nextUrl: "/dashboard",
            ...this.info,
          });

          this.silentlyStoreLoader =
            this.loaderService.silentlyStoreLoader.subscribe((response) => {
              this.isNavigateTo = true;
              this.marketAnalysisStore.removeItem("analysis");
              this.loaderService.hide();

              if (!response) {
                this.redirectToUrl(nextUrl);
              }
            });
        }
      });
    } else {
      this.isNavigateTo = true;
      this.redirectToUrl(nextUrl);
    }
  }

  ngOnDestroy() {
    this.isNavigateTo = false;
    this.info.isFormChanged = false;
    if (this.silentlyStoreLoader) {
      this.silentlyStoreLoader.unsubscribe();
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    if (this.isShowWarningPopup) {
      $event.returnValue = true;
    }
  }
  marketAnalysisStoreSubject;
  videoCounterArray: any[];
  callGetAnalysis() {
    this.marketAnalysisStore.getAnalysis(
      `q=${this.info.currentStep}&_a=${this.analysisId}&auto=0&mode=`
    );
    this.marketAnalysisStoreSubject =
      this.marketAnalysisStore.marketCharacteristics.subscribe((response) => {
        if (Object.keys(response).length) {
          this.stepAnalysis = response.steps;
          
        }
      });

    // if(this.marketAnalysisStoreSubject) {
    //   this.marketAnalysisStoreSubject.unsubscribe();
    // }
  }

  getStepText(stepAnalysis, currentTab) {
    let currentStepText = this.getStep(stepAnalysis, currentTab);
    return currentStepText.length ? currentStepText[0]["title"] : "";
  }

  getStep(stepAnalysis, currentTab) {
    let currentStep = currentTab.split("-").join("").toLowerCase();

    if (currentStep === "rateeverymarket") {
      currentStep = "comparemarkets";
    }

    if (currentStep === "mfrateeverymarket") {
      currentStep = "mfcomparemarket";
    }

    return stepAnalysis.filter(
      (step) => step.name.toLowerCase() === currentStep
    );
  }

  redirectToUrl(url) {
    this.isShowWarningPopup = false;
    window.location.href = url;
  }

  prepareDataForCopy() {
    let currentChildComp = _.filter(component, {
      tab: this.info.currentStep,
    })[0];
    console.log(currentChildComp);
    const formData =
      this[currentChildComp.instance][currentChildComp.form].value;
    console.log("currentStep", currentChildComp, formData);
    currentChildComp["data"] = formData;
    /**Check if parent component have multiple child component */
    if (currentChildComp["child"]) {
      const childFormData =
        this[currentChildComp.instance][currentChildComp.child.instance][
          currentChildComp.child.form
        ].value;
      currentChildComp["child"]["data"] = childFormData;
    }

    this.marketAnalysis.setItemToStorage("stored-info", currentChildComp);
  }

  isTabDisabled(): boolean {
    return this.isCostDisabled;
  }

  stepData(currentStep) {
    let step = this.getStep(this.stepAnalysis, currentStep);
    if (step.length) {
      step = step[0];
    }

    return step;
  }

  isVideoPlayerDialogOpen: boolean = false;
  videoPlayerDialogRef: any;
  playVideoImmediately() {
    if(!this.isVideoPlayAble()) {
      return
    }
    
    let data = {
      currentStep: this.info.currentStep,
      stepsInfo: this.stepAnalysis,
    };
    this.videoPlayerDialogRef = this.dialog.open(VideoPlayerComponent, {
      width: "558px",
      height: "385px",
      data: data,
      hasBackdrop: false,
      panelClass: ['animate__animated','animate__slideInRight', 'video-player-panel'],
      backdropClass: "video-player-panel-backdrop",
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
    this.videoPlayerDialogRef.afterOpened().subscribe((response) => {
      this.isVideoPlayerDialogOpen = true;
    });

    this.videoPlayerDialogRef.afterClosed().subscribe((response) => {
      let stepData = this.stepData(this.info.currentStep);
      this.isVideoAvailable = true;

      if(!stepData.videoLink) {
        this.isVideoAvailable = false;
        // make true to hide player button
      }
      this.isVideoPlayerDialogOpen = false;
    });
  }

  videoPlayerSubject: any;
  isVideoAvailable: boolean = false;

  autoPlayVideoAccourdingToTotalNumberOfVisility() {
     
    this.videoPlayerSubject = this.marketAnalysisStore.videoPlayer.subscribe(
      (response: any) => {
        if (!this.isVideoPlayerDialogOpen && response.isPlayable) {
          if(this.marketAnalysisStoreSubjectForVideoPlayer) {
            this.marketAnalysisStoreSubjectForVideoPlayer.unsubscribe();
          }
            this.getVideoCounter(response.step)
        }
      }
    );

    return;
    
  }
  marketAnalysisStoreSubjectForVideoPlayer;
  getVideoCounter(step) {
    this.marketAnalysisStoreSubjectForVideoPlayer =
      this.marketAnalysisStore.marketCharacteristics
      .subscribe((response:any) => {
        if (Object.keys(response).length) {
          if (response.analysis && response.analysis.steps.length) {

            let modifiedLabel = step.split("-").join("");
            let _label = modifiedLabel;
            if (_label === "comparemarkets") {
              modifiedLabel = "rateEveryMarket";
            } else if (_label === "mfattractiveness") {
              modifiedLabel = "marketFitAttractiveness";
            } else if (_label === "mfcompareMarket") {
              modifiedLabel = "mfRateEveryMarket";
            } else if (_label === "moanalysis") {
              modifiedLabel = "moAnalysisGraph";
            } else if (_label === "yourgoal") {
              modifiedLabel = "costgoal";
            } else if (_label === "estimatedcosts") {
              modifiedLabel = "estimatedcost";
            }
            let currentStep = _.filter(response.analysis.steps, (s) => {
              return s.name.toLowerCase() == modifiedLabel.toLowerCase();
            });
            

            console.log('this.isVideoPlayerDialogOpen', this.isVideoPlayerDialogOpen, );
            const notification = $('.ngx-toastr').hasClass("toast-error");
            console.log(notification);
            let stepData = this.stepData(this.info.currentStep);
            this.isVideoAvailable = true;

            if(!stepData.videoLink) {
              this.isVideoAvailable = false;
              // make true to hide player button
            }
            // autoplay video commented
            // if (!notification) {
            //     setTimeout(() => {
            //     if (currentStep.length) {
            //       let _currentStep = currentStep[0];
            //       if (_currentStep.video_view_counter <= 1) {
            //         if(!this.isVideoPlayerDialogOpen) {
                      
            //             this.videoPlayerDialogRef?.close();
            //             this.playVideoImmediately();
                      
            //         }
            //       }
            //     }

            //     if(currentStep.length === 0) {
            //       if(!this.isVideoPlayerDialogOpen) {
            //           this.videoPlayerDialogRef?.close();
            //           this.playVideoImmediately();
                    
            //       }
            //     }
            //   }, 3000);
            // }
          }
        }
      });
  }

  isVideoPlayAble() {
    return this.settingService.isCompany() && this.info.mode === 'new';
  } 
}
