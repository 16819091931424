import { Attribute, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MarketAnalysisStore } from "../../../moco/market-analysis/services/market-analysis.store";
declare const UIMorphingButton;
import * as _ from "lodash";
import { stepsInfo } from "../../../moco/market-analysis/models/analysis";
import * as $ from "jquery";
import { NotifyService } from "../../service/notify.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CheckDataLossModalComponent } from "../../../moco/market-analysis/components/check-data-loss-modal/check-data-loss-modal.component";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit {
  
  
  currentStepTitle: string = "";
  marketAnalysisStoreSubject = null;
  videoPlayerSubject = null;
  constructor(
    private marketAnalysisStore: MarketAnalysisStore,
    public sanitizer: DomSanitizer,
    public notify: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CheckDataLossModalComponent>,
    private cdr: ChangeDetectorRef,
  ) {}

  videoLink: any;
  _stepsInfo: any[];
  stepData: any;

  ngOnInit(): void {
    $(".cdk-global-overlay-wrapper").css("background", "rgba(0, 0, 0, 0)");
    // console.log(this.data);
    this._stepsInfo = this.data.stepsInfo;
    this.videoPlayerSubject = this.marketAnalysisStore.videoPlayer.subscribe(response => {
      
      // if (this.videoPlayerSubject) {
      //   this.videoPlayerSubject.unsubscribe();
      // }
      if(response.isPlayable) {
        this.data.currentStep = response.step;
        
        this.stepData = this.getStep();
        console.log(this.stepData);
        this.videoLink = this.buildLink(this.stepData);
        if(!this.stepData.videoLink) {
          this.dialogRef.close();
        }
        // console.log("PlayerResponse", response, this.stepData);
      }
    });

  }

  ngOnChanges() {
    // this.isDisplayerOpen = true
    // if(!this.stepData.videoLink) {
    //   this.isDisplayerOpen = false
    // }
    // this.initPlayerBtn();
    // console.log(this.getStep());
    // this.getLatestAnalysis();
  }

  getStep() {
    let currentStep = this.data.currentStep.split("-").join("").toLowerCase();

    if (currentStep === "rateeverymarket") {
      currentStep = "comparemarkets";
    }

    if (currentStep === "mfrateeverymarket") {
      currentStep = "mfcomparemarket";
    }

    if (this._stepsInfo) {
      let step = this._stepsInfo.filter(
        (step) => step.name.toLowerCase() === currentStep
      );
      // console.log(step);
      if (step.length) {
        this.currentStepTitle = step[0]["label"];
        return step[0];
      }

      return 
    }
  }

  


  buildLink(data) {
    let url = data.videoLink;
    if (data.videoType === "youtube") {
      url = `https://www.youtube.com/embed/${data.videoLink}?autoplay=0`;
    } else if (data.videoType === "vimeo") {
      url = `https://player.vimeo.com/video/${data.videoLink}?autoplay=0`;
    } else if (data.videoType === "other") {
      return "";
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  
  // playVideo() {
  //   let self = this;
  //   setTimeout(() => {
  //     self.playVideoImmediately();
  //   }, 2000);
  // }

  // playVideoImmediately() {
  //   this.videoLink = "";
    
  //   if (this.stepData.videoLink) {
      
  //     this.videoLink = this.buildLink(this.stepData);
  //     console.log("this.playerButton", this.playerButton);
  //     if (this.playerButton) {
  //       let isOpen =
  //         this.playerButton.nativeElement.parentElement.classList.contains(
  //           "open"
  //         );
  //       if (!isOpen) {
  //         let el: HTMLElement = this.playerButton.nativeElement;
  //         el.click();
  //       }
  //       console.log("clicked", isOpen);
  //     }
  //   }
  // }
  close() {
    this.dialogRef.close();
  }

  


  ngOnDestroy() {
    // console.log("ngOnDestroy");
    if(this.videoPlayerSubject) {
      this.videoPlayerSubject.unsubscribe();
    }

    if(this.marketAnalysisStoreSubject) {
      this.marketAnalysisStoreSubject.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
}
