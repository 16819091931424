import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  EventData,
  attractiveness,
  trafficLights
} from '../../../models/analysis';
import { MarketAnalysisService } from '../../../services/market-analysis.service';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Attractiveness } from '../../../models/market-characteristics/attractiveness.model';
import { Response } from '../../../../../shared/model/response';
import { MarketAnalysisStore } from '../../../services/market-analysis.store';
import { SettingsService } from '../../../../../shared/service/settings.service';
import { NotifyService } from '../../../../../shared/service/notify.service';
import { GraphService } from '../../../../../shared/components/real-time-graph/graph.service';

@Component({
  selector: 'app-mf-attractiveness',
  templateUrl: './mf-attractiveness.component.html',
  styleUrls: ['./mf-attractiveness.component.scss']
})
export class MfAttractivevenessComponent implements OnInit {
  /**Define step number */
  @Output() onStepperChange: EventEmitter<EventData> = new EventEmitter<
    EventData
  >();
  trafficLights = trafficLights;
  currentTab = 'marketFit';
  isFormValueChanged: boolean = false;

  marketFitStepFiveData;
  maxWeightFactor: number = 1;
  totalWeightFactor: number;
  leftWeighFactor: number;
  addNewParameterProgress: boolean = false;
  attr = attractiveness;
  prevData: any;

  initialSteps: EventData = {
    stepNumber: 4,
    locations: 0,
    stateChanged: false,
    isSubmitDisabled: true
  };

  /**From group */
  marketFitStep1From: FormGroup;
  constructor(
    private marketAnalysis: MarketAnalysisService,
    public marketAnalysisStore: MarketAnalysisStore,
    private settingsService: SettingsService,
    private notify: NotifyService,
    private graphService: GraphService
  ) {}

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "mf-attractiveness"});

    this.onStepperChange.emit(this.initialSteps);
    this.createAttractivenessForm();

    /**check local storage attractiveness data*/
    this.marketFitStepFiveData = this.marketAnalysis.getItemFromStorage(
      'analysis'
    );

    // store attractiveness for before process
    // if (!this.marketFitStepFiveData['marketFit']['attractiveness'].length) {
    //   console.log(this.attractiveness.value);
    //   this.addDefaultParameter();
    //   // this.storeMarketFitAttractiveness(false, true);
    // }

    this.marketAnalysisStore.marketCharacteristics.subscribe(response => {
      if (Object.keys(response).length) {
        this.patchValueToForm(response.marketFit);
        this.marketAnalysisStore.patchCopyableInfo(this.marketFitStep1From);
        // calculate again
        this.calculateWeightFactor();

        if (!response.marketFit.length) {
          this.storeMarketFitAttractiveness(false, true);
        }

        this.prevData = this.marketFitStep1From.value.attractiveness;
      }
    });

    /**Let's calculate weight factor */
    this.calculateWeightFactor();

    this.marketFitStep1From.valueChanges.subscribe(res => {
      this.isFormValueChanged = true;
      if (this.marketFitStep1From.dirty) {
        this.initialSteps.isFromDirty = true;
        this.initialSteps.isRefreshOnCancel = true;

        this.onStepperChange.emit(this.initialSteps);
      }
    });
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  createAttractivenessForm() {
    this.marketFitStep1From = new FormGroup({
      attractiveness: new FormArray([])
    });
    /**initialize parameter */
  }

  accuracy(index) {
    return parseInt(this.attractiveness.at(index).get('accuracy').value);
  }

  get attractiveness() {
    return this.marketFitStep1From.get('attractiveness') as FormArray;
  }

  patchValueToForm(value) {
    this.attractiveness.clear();
    if (value.length) {
      value.forEach((attr: Attractiveness) => {
        if (!attr.parameterOfHomeMarket) {
          attr.weightFactor = '';
        }

        this.pushParameter();
      });
      this.marketFitStep1From.patchValue({
        attractiveness: value
      });

      this.calculateWeightFactor();
    } else {
      /**add five parameter by default */
      this.addDefaultParameter();
    }
  }

  addDefaultParameter() {
    this.pushParameter();
    this.pushParameter();
    this.pushParameter();
  }
  pushParameter() {
    this.attractiveness.push(
      new FormGroup({
        id: new FormControl(),
        userId: new FormControl(),
        accuracy: new FormControl(3),
        parameterOfHomeMarket: new FormControl(),
        source: new FormControl(),
        weightFactor: new FormControl()
      })
    );
  }
  actionAble: 'parameter' | 'saveData' = 'saveData';

  addParameter() {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }

    this.pushParameter();
    this.addNewParameterProgress = true;
    this.actionAble = 'parameter';
    this.storeMarketFitAttractiveness(false, true);
  }

  deleteParameter(index: number) {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }

    const attrId = this.attractiveness.at(index).get('id').value;

    this.removeParameter(index);

    if (attrId) {
      this.settingsService.delete(attrId, 'marketFitAttractiveness');
      this.settingsService.isDeleting.subscribe(response => {
        if (!response) {
          // this.removeParameter(index);
        }
      });
    } else {
      // this.removeParameter(index);
    }
  }

  removeParameter(index: number) {
    this.attractiveness.removeAt(index);
    const attr = this.attractiveness.value;
    console.log(attr);
    this.marketFitStepFiveData[this.currentTab]['attractiveness'] = attr;
    const _analysis = this.marketFitStepFiveData;
    this.calculateWeightFactor();

    this.marketAnalysis.setCurrentStep('analysis', _analysis);
  }

  /**calculate weight factor */

  calculateWeightFactor() {
    const attractiveness = this.marketFitStep1From.value.attractiveness;
    const self = this;
    this.totalWeightFactor = _.round(
      _.sumBy(attractiveness, function(attr: Attractiveness) {
        return self.marketAnalysisStore.getValue(attr.weightFactor);
      }),
      2
    );
    this.leftWeighFactor = this.maxWeightFactor - this.totalWeightFactor;
    if (
      this.totalWeightFactor <= this.maxWeightFactor &&
      this.totalWeightFactor === this.maxWeightFactor
    ) {
      this.initialSteps.isSubmitDisabled = false;
    } else {
      this.initialSteps.isSubmitDisabled = true;
    }
    this.onStepperChange.emit(this.initialSteps);
  }

  convertToAbs(value) {
    return Math.abs(value).toFixed(2);
  }
  /**Store Attractiveness */
  storeMarketFitAttractiveness(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }

    let weightFactorInfo = `Oops! The total weigh isn’t exactly 1. You need to
                  ${
                    this.totalWeightFactor <= this.maxWeightFactor
                      ? 'add'
                      : 'deduct'
                  }
                  ${this.convertToAbs(this.leftWeighFactor)}`;
    if (!isStoreSilently && this.initialSteps.isSubmitDisabled) {
      this.notify.notifyError(weightFactorInfo, 'Weigh factor'); //info
      return;
    }
    // if (!this.isFormValueChanged) {
    //   this.changeToNext();
    //   return false;
    // }
    // Set next step to compare-markets
    let filteredAttr = [];
    const attr = this.marketFitStep1From.value.attractiveness;
    if (attr.length) {
      attr.forEach((att: Attractiveness, key) => {
        /**lets first push first five elemnt  */
        if (key <= 4) {
          filteredAttr.push(att);
        } else if (att.parameterOfHomeMarket) {
          filteredAttr.push(att);
        }
      });
    }

    const market = {
      // filter only valid record
      attractiveness: this.attractiveness.value,
      prevData: this.prevData,
      nextData: this.marketFitStep1From.value.attractiveness,
      type: 'marketFitAttractiveness',
      analysisId: this.marketFitStepFiveData['analysis']['id'],
      mode: this.marketFitStepFiveData['aMode'],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1,
      isStoreSilently: isStoreSilently,
      action: this.actionAble
    };

    this.marketAnalysis
      .storeAnalysis(market)
      .subscribe((response: Response) => {
        this.marketFitStep1From.patchValue({
          attractiveness: this.marketAnalysis.changeDataForPatch(
            response.data.marketFit
          )
        });
        this.changeToNext(response.data.marketFit, isStoreSilently);

        if (response.data.action === 'parameter') {
          this.addNewParameterProgress = false;
        }
        if (isRedirect) {
          this.marketAnalysis.exit();
          return;
        }
      });
  }

  changeToNext(attractiveness = null, isStoreSilently = false) {
    let _analysis;

    if (this.marketFitStepFiveData) {
      if (attractiveness) {
        this.marketFitStepFiveData[this.currentTab][
          'attractiveness'
        ] = attractiveness;
      }

      if (!isStoreSilently) {
        this.marketFitStepFiveData['currentTab'] = 'mfScoresAttractiveness';
        this.marketFitStepFiveData['current'] =
          +this.marketFitStepFiveData['current'] + 1;
      }

      _analysis = this.marketFitStepFiveData;
    }

    this.marketAnalysis.setCurrentStep('analysis', _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);

    if (isStoreSilently) {
      this.marketAnalysisStore.updateChart.next({
        isUpdate: true,
        data: []
      });
    }
  }

  checkForFirstRow(attractiveness, index: number) {
    return this.marketAnalysis.checkForFirstRow(attractiveness, index);
  }

  storeDataSilently() {
    this.initialSteps.isFormChanged = true;
    this.onStepperChange.emit(this.initialSteps);
    this.actionAble = 'saveData';

    this.storeMarketFitAttractiveness(false, true);
  }

  emitformDirty() {
    this.initialSteps.isRefreshOnCancel = false;
    this.initialSteps.isFromDirty = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  renderGraph() {
    this.marketFitStepFiveData['marketFit']['attractiveness'].map(attr => {
      let filtered = _.filter(this.marketFitStep1From.value, { id: attr.id });
      if (filtered.length) {
        let _filtered = filtered[0];
        attr.weightFactor = _filtered.weightFactor;
      }
      return attr;
    });

    let location = this.graphService.moAnalysisGraphData(
      this.marketFitStepFiveData
    );

    this.marketAnalysisStore.updateChart.next({
      isUpdate: true,
      data: location
    });
  }
}
