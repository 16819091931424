import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Response } from '../shared/model/response';
import { Logger } from './logger';
import { Service } from '../shared/service/service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService extends Service {
  constructor(private httpClient: HttpClient) {
    super(`${environment.apiUrl}api/log`, httpClient);
  }

  getLoginfo(userId) {
    return this.httpClient
      .get(`${environment.apiUrl}api/logs?a=${userId}`)
      .pipe(
        map((response: Response) => {
          const data = response.data;
          return data?.map(log => new Logger(log));
        })
      );
  }

  log(data) {
    this.post(data).subscribe(response => {});
  }
}
