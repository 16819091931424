import { Injectable } from '@angular/core';
import { Service } from '../../../../shared/service/service';

import { BehaviorSubject, Observable } from 'rxjs';
import { SectorAdmin } from '../../models/sector-admin.model';
import { SectorAdminService } from './sector-admin.service';
import { NotifyService } from '../../../../shared/service/notify.service';

@Injectable({
  providedIn: 'root'
})
export class SectorAdminStore extends Service {
  sectors: BehaviorSubject<SectorAdmin[]> = new BehaviorSubject([]);
  public loadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject(true);

  public readonly loadingSector: Observable<
    Boolean
  > = this.loadingSubject.asObservable();

  constructor(private sectorService: SectorAdminService ,
    private notify: NotifyService) {
    super();
  }

  getSectors(value) {
    this.loadingSubject.next(true);
    return this.sectorService.getSectors(value).subscribe(response => {
      this.sectors.next(response);
      this.loadingSubject.next(false);
    });
  }

  saveSectors(value) {
    return this.sectorService.saveSectors('', value).subscribe(response => {      
      this.sectors.next(response);
    });
  }

  delSectors(value) {
    return this.sectorService.saveSectors('delete', value).subscribe(response => {      
      this.sectors.next(response);
    });
  }
}
