import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Response } from '../model/response';
import { Settings } from '../model/settings.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotifyService } from './notify.service';
import { Router } from '@angular/router';
import { ExceptionService } from './exception.service';
import { AuthorizationService } from '../../authentication/service/authorization.service';
import { ConfirmService } from './confirm.service';
import { Service } from './service';
import { CompanyService } from '../../moco/company/services/company.service';
import { LocationAdminStore } from '../../moco/moco-admin/services/location-admin/location-admin.store';
import { CategoryAdminStore } from '../../moco/moco-admin/services/category-admin/category-admin.store';
import { MarketAnalysisStore } from '../../moco/market-analysis/services/market-analysis.store';
import { SectorAdminStore } from '../../moco/moco-admin/services/sector-admin/sector-admin.store';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends Service {
  private apiUrl: string = `${environment.apiUrl}api`;
  public isDeleting = new BehaviorSubject(false);
  public settingSubject: BehaviorSubject<any> = new BehaviorSubject({});
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public actveUser = new BehaviorSubject({});

  public readonly settings: Observable<
    any
  > = this.settingSubject.asObservable();
  public readonly loadingSettings: Observable<
    Boolean
  > = this.loadingSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private notify: NotifyService,
    public router: Router,
    private auth: AuthorizationService,
    private exception: ExceptionService,
    public confirm: ConfirmService,
    private companyService: CompanyService,
    public locationAdminStore: LocationAdminStore,
    public categoryAdminStore: CategoryAdminStore,
    public marketAnalysisStore: MarketAnalysisStore,
    public sectorAdminStore: SectorAdminStore,
    private dialogRef: MatDialog
  ) {
    super();
  }

  getSettings() {
    this.loadingSubject.next(true);
    return this.httpClient
      .get(`${this.apiUrl}/settings`)
      .pipe(
        map((response: Response) => {
          const bs = response.data;
          return new Settings(bs);
        })
      )
      .subscribe(
        response => {
          this.loadingSubject.next(false);
          this.settingSubject.next(response);
          this.actveUser.next(response);
          /**
           * store role to local storage
           */
          this.setItemToStorage('user-role', response.role);

          this.auth.isLoggedIn.next(true);
        },
        (err: HttpErrorResponse) => {
          this.loadingSubject.next(false);
          this.exception.handleError(err);
        }
      );
  }

  resendConfirmationMail(data = null) {
    return this.httpClient
      .post(`${this.apiUrl}/confirmation-mail`, data)
      .subscribe(
        response => {
          this.notify.notifySuccess(
            'Verification mail sent, check your email.',
            'Verification'
          );
        },
        err => {
          this.exception.handleError(err);
        }
      );
  }

  delete(identifier, type, dialogRefs: any = '') {
    const _type = type === 'team-via-company' ? 'user' : type;
    this.isDeleting.next(true);
    return this.httpClient
      .post(`${this.apiUrl}/delete/${_type}`, { identifier })
      .subscribe(
        (response: Response) => {
          // dialogRefs.close();
          //this.confirm.close();
          // if(type == 'analysis-width-history'){
          this.dialogRef.closeAll();
          // }

          if (type == 'analysis' || type == 'analysis-width-history') {
            let q = '';
            let projectId = '';
            let pageIndex = 1;
            let pageSize = 5;

            if (identifier?.typeRequest !== undefined) {
              q = identifier?.typeRequest;
            }
            if (identifier?.projectId !== undefined) {
              projectId = identifier?.projectId;
            }
            if (identifier?.typeRequest !== undefined) {
              q = identifier?.typeRequest;
            }
            if (identifier?.projectId !== undefined) {
              projectId = identifier?.projectId;
            }
            if (identifier?.pageIndex !== undefined) {
              pageIndex = identifier?.pageIndex;
            }
            if (identifier?.pageSize !== undefined) {
              pageSize = identifier?.pageSize;
            }

            this.marketAnalysisStore.getAnalysisCharts(
              q,
              '',
              '',
              projectId,
              pageIndex,
              pageSize
            );
            this.getSettings();
          }

          if (type == 'Location' || type == 'synonym') {
            this.locationAdminStore.getLocations('');
          }
          if (type == 'Sector') {
            this.sectorAdminStore.getSectors('');
          }

          if (type == 'user' || type == 'organization-status') {
            this.companyService.getCompaniesAll('', false, this.companyService.getCoach());
          }
          if (type == 'category' || type == 'sub-category') {
            this.categoryAdminStore.getCategorys('');
          }

          if (
            type !== 'mcAttractiveness' &&
            type !== 'marketFitAttractiveness' &&
            type !== 'estimatedCost'
          ) {
            let msg = response.message;

            if (type == 'analysis-width-history') {
              type = 'Analysis';
            }

            if(this.companyService.getCoach()) {
              type = "Coach status"
            }
            this.notify.notifySuccess(msg, type);
          }

          if (
            !response.data &&
            (type === 'mcAttractiveness' ||
              type === 'marketFitAttractiveness' ||
              type === 'estimatedCost')
          ) {
            this.isDeleting.next(true);
            this.notify.notifyError(
              'There is some problem, Please try again later.',
              'Delete'
            );
          } else {
            this.isDeleting.next(false);
          }
        },
        err => {
          this.isDeleting.next(true);
          this.exception.handleError(err);
        }
      );
  }
}
