import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { EventData, scoreList, trafficLights } from "../../../models/analysis";
import { MarketAnalysisService } from "../../../services/market-analysis.service";
import { MarketAnalysisStore } from "../../../services/market-analysis.store";
import { Country } from "../../../models/market-characteristics/country.model";
import { Attractiveness } from "../../../models/market-characteristics/attractiveness.model";
import { FormGroup, FormArray, FormControl } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { MfCompareMarketComponent } from "../mf-compare-market/mf-compare-market.component";
import { MarketFitAnalysisGraph } from "../../../../../shared/components/mo-analysis-graph/graph-data.model";

@Component({
  selector: "app-mf-rate-every-market",
  templateUrl: "./mf-rate-every-market.component.html",
  styleUrls: ["./mf-rate-every-market.component.scss"],
})
export class MfRateEveryMarketComponent implements OnInit {
  @Output()
  onStepperChange: EventEmitter<EventData> = new EventEmitter<EventData>();
  @ViewChild(MfCompareMarketComponent)
  private mfcc: MfCompareMarketComponent;

  initialSteps: EventData = {
    stepNumber: 5,
    locations: 0,
    stateChanged: false,
  };
  isFormValueChanged: boolean = false;

  trafficLights: any[] = trafficLights;

  locations: Country[] = [];
  attractiveness: Attractiveness[] = [];
  rateEveryMarketForm: FormGroup;
  rateMarketsData;

  currentTab = "marketFit";
  prevData: any;

  constructor(
    private marketAnalysis: MarketAnalysisService,
    private marketAnalysisStore: MarketAnalysisStore,
    sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "mf-rate-every-market"});
    this.onStepperChange.emit(this.initialSteps);
    this.createRateEveryMarketForm();
    this.rateMarketsData = this.marketAnalysisStore.getItemFromStorage(
      "analysis"
    );

    this.marketAnalysisStore.marketCharacteristics.subscribe((response) => {
      if (Object.keys(response).length) {
        this.locations = response.location;
        this.attractiveness = response.marketFit;
        this.patchRateEveryMarketForm(this.attractiveness);
        this.marketAnalysisStore.patchCopyableInfo(
          this.rateEveryMarketForm,
          "mf-rate-every-market"
        );

        this.prevData = this.rateEveryMarketForm.value.rateMarkets;
      }
    });

    this.rateEveryMarketForm.valueChanges.subscribe((res) => {
      this.isFormValueChanged = true;
      if (this.rateEveryMarketForm.dirty) {
        this.initialSteps.isFromDirty = true;
        this.initialSteps.isRefreshOnCancel = true;

        this.onStepperChange.emit(this.initialSteps);
      }
    });
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  changeState(event: EventData) {
    if (event.isFromDirty) {
      this.initialSteps.isFromDirty = true;
      this.initialSteps.isRefreshOnCancel = true;
      this.onStepperChange.emit(this.initialSteps);
    }
  }

  createRateEveryMarketForm() {
    this.rateEveryMarketForm = new FormGroup({
      rateMarkets: new FormArray([]),
    });
  }

  get rateMarkets() {
    return this.rateEveryMarketForm.get("rateMarkets") as FormArray;
  }

  getName(index: number) {
    return this.rateMarkets.at(index).get("parameterOfHomeMarket").value;
  }

  getCountryName(index, countryIndex) {
    return this.getCountry(index).at(countryIndex).get("name").value;
  }

  calculateAttractiveness(countryIndex, countryName) {
    let score = 0;
    const rateMarkets = this.rateMarkets.value;

    rateMarkets.forEach((rate: any) => {
      let scoreFilled = 0;
      try {
        scoreFilled = rate.country[countryIndex].scoreFilled;
      } catch (error) {
        scoreFilled = 0;
      }
      score = score + scoreFilled * rate.weightFactor;
    });
    /**set location with absolute no and total attractivenes */
    const moAnalysis = this.rateMarketsData["moAnalysis"]["graph"];
    this.locations.map((location: any) => {
      if (location.name === countryName) {
        location.marketFit = score;
      }
      return location;
    });
    this.locations = this.marketAnalysisStore.mapGraphDataToLocation(
      this.locations,
      moAnalysis,
      "marketFit"
    );
    return score.toFixed(2);
  }

  accuracy(index, countryIndex) {
    return parseInt(
      this.getCountry(index).at(countryIndex).get("accuracy").value
    );
  }

  getCountry(index) {
    return this.rateMarkets.at(index).get("country") as FormArray;
  }

  /**implement select */
  scoreList = scoreList;
  selectedItems = [
    {
      id: 0,
      itemName: 0,
    },
  ];
  settings = {
    singleSelection: true,
    text: "Select score",
    classes: "score-selection-container",
  };

  patchRateEveryMarketForm(attractiveness: Attractiveness[]) {
    this.rateMarkets.clear();
    attractiveness.forEach((attr: Attractiveness, key: number) => {
      this.rateMarkets.push(
        new FormGroup({
          id: new FormControl(attr.id),
          weightFactor: new FormControl(attr.weightFactor),
          parameterOfHomeMarket: new FormControl(attr.parameterOfHomeMarket),
          country: new FormArray([]),
        })
      );
      this.setCountryForm(key);
    });
  }

  setCountryForm(index) {
    const countryForm = this.getCountry(index);
    this.locations.forEach((location: Country) => {
      let rem = [];
      if (this.attractiveness[index].rateEveryMarket) {
        rem = this.attractiveness[index].rateEveryMarket.filter(
          (rem) => rem.name.toLowerCase() === location.name.toLowerCase()
        );
      }
      if (rem.length) {
        location = rem[0];
        location.scoreFilled = +location.scoreFilled;
      }
      countryForm.push(
        new FormGroup({
          id: new FormControl(location.id),
          name: new FormControl(location.name),
          // absoluteNumber: new FormControl(location.absoluteNumber),
          scoreSelected: new FormControl([
            {
              id: 0,
              itemName: 0,
            },
          ]),
          scoreFilled: new FormControl(location.scoreFilled),
          accuracy: new FormControl(location.accuracy ? location.accuracy : 3),
        })
      );
    });
  }

  storeMarketRate(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }
    // if (!this.isFormValueChanged) {
    //   this.changeToNext();
    //   return false;
    // }
    /**store market call here */
    if (!isStoreSilently) {
      this.mfcc.storeCompareMarket(isRedirect, isStoreSilently);
    }
    const market = {
      rateEveryMarket: this.rateMarkets.value,
      prevData: this.prevData,
      nextData: this.rateEveryMarketForm.value.rateMarkets,
      graph: this.locations,
      type: "mfRateEveryMarket",

      mode: this.rateMarketsData["aMode"],
      analysisId: this.rateMarketsData["analysis"]["id"],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1,
      isStoreSilently: isStoreSilently,
    };
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      const data = {
        attractiveness: response.data.marketFit,
        moAnalysis: response.data.analysis.moAnalysis,
      };
      if (isRedirect) {
        this.marketAnalysis.exit();
        return;
      }
      this.changeToNext(data, isStoreSilently);
    });
  }

  changeToNext(data = null, isStoreSilently = false) {
    let _analysis;
    if (this.rateMarketsData) {
      if (data) {
        this.rateMarketsData[this.currentTab]["attractiveness"] =
          data.attractiveness;

        this.rateMarketsData["moAnalysis"]["graph"] = data.moAnalysis;
      }

      if (!isStoreSilently) {
        this.rateMarketsData["currentTab"] = "moAnalysis";
        this.rateMarketsData["current"] = +this.rateMarketsData["current"] + 1;
      }

      _analysis = this.rateMarketsData;
    }

    this.marketAnalysis.setCurrentStep("analysis", _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  storeDataSilently() {
    this.initialSteps.isFormChanged = true;
    this.onStepperChange.emit(this.initialSteps);
    this.storeMarketRate(false, true);
  }

  renderGraph() {
    this.locations.forEach((el, index) => {
      this.calculateAttractiveness(index, el.name);
    });
    let location = this.locations.map(
      (analysis: any) => new MarketFitAnalysisGraph(analysis)
    );

    this.marketAnalysisStore.updateChart.next({
      isUpdate: true,
      data: location,
    });
  }
}
