import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { ChartType, ChartOptions, Chart } from "chart.js";
import { GraphData } from "./graph-data.model";
import * as _ from "lodash";
import { GraphConfig } from "./graph-config";
import { MarketAnalysisStore } from "../../../moco/market-analysis/services/market-analysis.store";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { GraphService } from "../real-time-graph/graph.service";
import { D3GraphService } from "../../service/d3-graph.service";
import * as d3 from 'd3';

declare const $;

@Component({
  selector: "app-mo-analysis-graph",
  templateUrl: "./mo-analysis-graph.component.html",
  styleUrls: ["./mo-analysis-graph.component.scss"],
})
export class MoAnalysisGraphComponent implements OnInit {
  @Input() dataset: GraphData[];
  @Input() config: GraphConfig;
  @Input() isRealTime;
  @Input() type: string = "mo-analysis";
  @Input() isAnalysisListing = false;
  chart: any;

  // scatter
  public scatterChartOptions: ChartOptions;
  user: any = null;

  public scatterChartData: any[] = [];
  public scatterChartType: ChartType = "scatter";
  updateChartDataSetSubscription: Subscription;
  xTickValue: any = {
    min: 0,
    minRotation: 45,
  };
  xLabel = "";
  yLabel = "";
  constructor(
    public marketAnalysisStore: MarketAnalysisStore,
    public router: Router,
    public d3GraphService: D3GraphService,
    private container: ElementRef
  ) {}
  scale;
  ngOnInit() {
    // this.d3GraphService.renderChart();
    // this.initChart();
    // console.log(this.d3GraphService.prepaireData(this.dataset));

    try {
      let moAnalysisData = this.marketAnalysisStore.getItemFromStorage(
        "analysis"
      );
      if (
        moAnalysisData &&
        moAnalysisData["analysis"] &&
        moAnalysisData["analysis"]["user"]
      ) {
        this.user = moAnalysisData["analysis"]["user"];
      }
    } catch (error) {
      this.user = null;
    }

    if (this.type === "mo-analysis") {
      this.xLabel = "Market fit";
      this.yLabel = "Market attractiveness";
    } else if (this.type === "moco-analysis") {
      this.xLabel = "Cost in 1 year";
      this.yLabel = "Market Opportunity";
    } else {
      this.xLabel = this.config.label.xLabel;
      this.yLabel = this.config.label.yLabel;
    }
    this.setChartData();

    let self = this;
    this.scatterChartOptions = <any>{
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        align: "start",
        fullWidth: true,
        display: false,
        labels: {
          fontSize: 11,
          usePointStyle: true,
        },
      },

      // events: [],
      // animation: {
      //   duration: 1,
      //   onComplete: function(chartR) {
      //     let chart = chartR.chart;
      //     let easing = chartR.easing;
      //     var chartInstance = this.chart,
      //       ctx = chartInstance.ctx;
      //     ctx.font = Chart.helpers.fontString(
      //       Chart.defaults.global.defaultFontSize,
      //       Chart.defaults.global.defaultFontStyle,
      //       Chart.defaults.global.defaultFontFamily
      //     );

      //     ctx.textAlign = 'center';
      //     ctx.textBaseline = 'bottom';

      //     this.data.datasets.forEach(function(dataset, i) {
      //       var meta = chartInstance.controller.getDatasetMeta(i);
      //       meta.data.forEach(function(bar, index) {
      //         let dataLegend = bar['_chart']['legend']['legendItems'];
      //         var data = dataLegend[bar['_datasetIndex']];
      //         // console.log(
      //         //   'p',
      //         //   bar['_datasetIndex'],
      //         //   bar._model.x,
      //         //   bar._model.y
      //         // );
      //         ctx.fillStyle = '#000000';
      //         if (self.config.showPointLabel) {
      //           ctx.fillText(data?.text, bar._model.x - 1, bar._model.y);
      //         }
      //       });
      //     });
      //   },
      //   onProgress: function(chartR) {}
      // },
      hover: {
        mode: null,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        backgroundColor: "#fff",
        borderColor: "#ddd",
        borderWidth: 1,
        bodyFontColor: "#23282c",
        bodyFontSize: 14,
        cornerRadius: 10,
        xPadding: 7,
        yPadding: 10,
        mode: "point",

        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem["datasetIndex"]]["label"];
            return (
              label +
              ": (" +
              tooltipItem.xLabel +
              ", " +
              tooltipItem.yLabel +
              ")"
            );
          },
        },
        //  beforeTooltipDraw:function(){},
      },
      scales: <any>{
        xAxes: [
          {
            ticks: this.xTickValue,
            scaleLabel: {
              display: true,
              labelString: this.xLabel,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              max: 100,
              stepSize: 25,
              // callback: value => this.fooA[value],
            },
            scaleLabel: {
              display: true,
              labelString: this.yLabel,
            },
          },
        ],
      },
    };
    if (this.isRealTime !== "false") {
      this.updateChartDataSetSubscription = this.marketAnalysisStore.updateChartDataSet.subscribe(
        (response) => {
          if (
            Object.keys(response).length &&
            response.isRealTime &&
            response.graph.length
          ) {
            console.log(response);
            this.dataset = response.graph;
            this.setChartData(true);
          }
        }
      );
    }
    // setTimeout(() => {
    //   this.setChartData(100);
    //   this.chart.chart.update();
    // }, 1000);
  }

  initChart() {
    let canvas = d3.select(this.container.nativeElement)
    .select('canvas');
    console.log(document.querySelector(".graph-container"));
    let bubble = d3
    .pack()
    // .size([width, height])
    .padding(0);;
    let nodes = this.d3GraphService.prepaireData(this.dataset);

    canvas.selectAll('.node')
      .data(bubble(nodes).descendants());

    console.log(nodes);
     
  }
  setChartData(isUpdate = false) {
    this.scatterChartData = [];
    if (this.dataset.length) {
      this.scale = {
        y: {
          min: 0,
          max: 0,
        },
        x: {
          min: _.minBy(this.dataset, function (d) {
            return d.x;
          })?.x,
          max: _.maxBy(this.dataset, function (d) {
            return d.x;
          })?.x,
        },
      };
      /**subtract or add some value  */
      this.scale.x.max = this.scale.x.max + 5;

      this.scale.x.min = this.scale.x.min - 5;

      if (this.type === "mo-analysis") {
        this.scale.x.min = 0;
        this.scale.x.max = 100;
        this.xTickValue = {
          max: 100,
          stepSize: 20,
          min: 0,
          minRotation: 45,
        };
      }

      const pointRadius = _.round(
        _.sumBy(this.dataset, function (d) {
          return d.pointRadius;
        })
      );
      this.dataset.forEach((element: GraphData) => {
        let circleRadius = (element.pointRadius / pointRadius) * 100;
        circleRadius = circleRadius - (circleRadius) / 100;
        // console.log(element);
        let pointBorderWidth = +((circleRadius * 30) / 100);
        let pointBorderColor = this.lightenDarkenColor(element.color, 50);
        this.scatterChartData.push({
          data: [{ x: element.x, y: element.y }],
          label: element.name,
          pointRadius: circleRadius,
          backgroundColor: this.hexToRgbA(element.color),
          pointBackgroundColor: this.hexToRgbA(element.color),
          // pointHoverBackgroundColor: element.color,
          // pointHoverBorderColor: element.color,
          // hitRadius: circleRadius / 2,
          // pointHitRadius: circleRadius / 2,
          // pointBorderWidth: pointBorderWidth,
          pointBorderColor: this.hexToRgbA(element.color),
        });
      });

      if (isUpdate) {
        if (this.chart) {
          this.chart.update();
        }
      }
    }
  }
  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  lightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    const color =
      (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    return color;
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.8)"
      );
    }
    throw new Error("Bad Hex");
  }

  ngOnDestroy() {
    if (this.updateChartDataSetSubscription) {
      this.updateChartDataSetSubscription.unsubscribe();
    }
  }

  roundNumber(number, type = "up") {}

  getTrimedName(country) {
    return country.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
  }
}
