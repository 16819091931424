import { Component, OnInit, HostListener } from "@angular/core";
import { AnalysisChart } from "../../../market-analysis/models/market-characteristics/analysis-chart.model";
import { MarketAnalysisService } from "../../../market-analysis/services/market-analysis.service";
import { MarketAnalysisStore } from "../../../market-analysis/services/market-analysis.store";
import { SettingsService } from "../../../../shared/service/settings.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { FormGroup, FormControl, AbstractControl } from "@angular/forms";
import {
  GraphConfig,
  graphConfig,
} from "../../../../shared/components/mo-analysis-graph/graph-config";
import { ConfirmComponent } from "../../../../shared/components/confirm/confirm.component";
import { ConfirmService } from "../../../../shared/service/confirm.service";
import { environment } from "../../../../../environments/environment";
//import 'rxjs/add/operator/filter';

@Component({
  selector: "app-admin-analysis",
  templateUrl: "./admin-analysis.component.html",
  styleUrls: ["./admin-analysis.component.scss"],
})
export class AdminAnalysisComponent implements OnInit {
  chartConfig: GraphConfig = graphConfig;
  isShowAnanlysisForm: boolean = false;
  totUser = 0;
  totAnylse = 0;
  showStats = "";
  viewType = "grid";
  allUsers: [] = [];
  selectedDate = "";
  selectedOrg = "";
  loadingSilently = false;
  public selectedDateValue: AbstractControl;

  allAnlysis: AnalysisChart[] = [];
  selectedDateEnd: any;
  q = "";
  public projectId: string;
  paginationLoader: boolean;

  matSelectNos: number[] = [3, 6, 12, 18];
  pageLength = 15;
  pageIndex: any = 1;
  pageSize: any = 6;
  pageIndexPage = 0;
  subsPage;
  lastPage: number;
  subsData;
  constructor(
    public marketAnalysis: MarketAnalysisService,
    public marketAnalysisStore: MarketAnalysisStore,
    public settingsService: SettingsService,
    public confirm: ConfirmService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  selectedItems = [];
  userForm: FormGroup;
  analysisPage: any = {};
  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get("id");
    this.chartConfig.height = 260;
    this.chartConfig.width = 373;
    // console.log(conpanyId);
    let today: any = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    //this.selectedDate = today;
    //this.selectedDateEnd = today;

    this.createRegistrationForm();

    if (
      this.marketAnalysis.isAdmin() ||
      this.projectId !== null ||
      this.marketAnalysis.isCompany() ||
      this.marketAnalysis.isCoach()
    ) {
      if (this.marketAnalysis.isCoach()) {
        this.q = "coach";
      } else {
        this.q = "a";
      }
      // if (this.marketAnalysis.isCompany()) {
      //   this.q = 'company';
      // }

      // this.router.events.pipe(filter(e => e instanceof NavigationEnd).subscribe((e) => {
      //   this.marketAnalysisStore.getAnalysisCharts(this.q,'','',this.projectId);
      // });

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.log("nav changed");
          this.projectId = this.route.snapshot.paramMap.get("id");
          this.marketAnalysisStore.getAnalysisCharts(
            this.q,
            "",
            "",
            this.projectId,
            this.pageIndex,
            this.pageSize
          );
          this.subsData = this.marketAnalysisStore.analysisCharts.subscribe(
            (res) => {
              this.allAnlysis = res;
              if (this.allAnlysis.length) {
                this.allUsers = this.allAnlysis[0].allUsers;
              }
              //this.selectedDateValue='';
            }
          );

          this.managePage();
        }
      });
      this.route.queryParams.subscribe((params) => {
        this.selectedOrg = params.a;
        if (params.a) {
          this.q = this.selectedOrg;
        }
      });
      console.log(this.q, this.selectedOrg);

      this.marketAnalysisStore.getAnalysisCharts(
        this.q,
        "",
        "",
        this.projectId,
        this.pageIndex,
        this.pageSize
      );
      this.subsPage = this.marketAnalysisStore.analysisCharts.subscribe(
        (res) => {
          this.allAnlysis = res;
          if (this.allAnlysis.length) {
            this.allUsers = this.allAnlysis[0].allUsers;
          }
          //this.selectedDateValue='';
        }
      );
      this.managePage();
    }

    this.marketAnalysis.analysisRenamed.subscribe((response) => {
      console.log("response_renamed", response);
      if (response.status) {
        this.allAnlysis = this.allAnlysis.map((analysis) => {
          if (analysis.id === response.id) {
            analysis.name = response.name;
          }
          return analysis;
        });
      }
    });
  }

  managePage(status = false) {
    this.marketAnalysisStore.analysisChartsPageSubject.subscribe((res) => {
      this.analysisPage = res;
      this.lastPage = res?.last_page;
      this.pageLength = res?.total;
      this.pageIndex = res?.current_page;
      this.pageSize = res?.per_page;
      this.loadingSilently = status;
    });
  }

  createRegistrationForm() {
    this.userForm = new FormGroup({
      selectedDateValue: new FormControl(""),
      settingSection: new FormControl(""),
    });
  }

  createOrUpdate(value) {}

  changeView(type) {
    if (type !== "refresh") {
      this.viewType = type;
    }
    this.pageIndex = 1;
    this.pageSize = 6;
    this.marketAnalysisStore.getAnalysisCharts(
      "a",
      "",
      "",
      this.projectId,
      this.pageIndex,
      this.pageSize
    );
    this.managePage();

    this.selectedOrg = "";
    this.userForm.reset();
  }

  settings = {
    singleSelection: true,
    text: "Select organisation",
    classes: "select--list",
  };

  orgChange(event) {
    console.log(event);
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: { a: event.id },
    });

    if (event != null && event?.id !== undefined) {
      this.pageIndex = 1;

      this.selectedOrg = event.id;
      let org = "";
      if (this.selectedDate !== "") {
        org = this.selectedDate;
      }
      // this.marketAnalysisStore.getAnalysisCharts(
      //   event.id,
      //   org,
      //   this.selectedDateEnd,
      //   this.projectId,
      //   this.pageIndex,
      //   this.pageSize
      // );
      this.managePage();
    }
  }

  public daterange: any = {};
  public options: any = {
    locale: { format: "YYYY-MM-DD" },
    alwaysShowCalendars: false,
  };

  selectedDateFunction(value: any, datepicker?: any) {
    // this is the date  selected
    this.pageIndex = 1;
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    // console.log(value);
    // console.log(value.start.format('YYYY-MM-DD'));
    // console.log(value.end.format('YYYY-MM-DD'));

    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;

    let val: any = value.start.format("YYYY-MM-DD");
    let end = value.end.format("YYYY-MM-DD");
    if (val != null && val !== undefined) {
      this.selectedDate = val;
      this.selectedDateEnd = end;
      let org = "a";
      if (this.selectedOrg !== "") {
        org = this.selectedOrg;
      }

      this.marketAnalysisStore.getAnalysisCharts(
        org,
        val,
        end,
        this.projectId,
        this.pageIndex,
        this.pageSize
      );
      this.managePage();
    }
  }

  selectDate(event) {
    console.log(event);
    let value = event.target.value;
    if (value != null && value !== undefined) {
      this.selectedDate = value;
      let org = "a";
      if (this.selectedOrg !== "") {
        org = this.selectedOrg;
      }
      this.marketAnalysisStore.getAnalysisCharts(
        org,
        value,
        this.selectedDateEnd,
        this.projectId,
        this.pageIndex,
        this.pageSize
      );
      this.managePage();
    }
  }

  delAnalysis(gdata) {
    gdata["typeRequest"] = this.q;
    gdata["projectId"] = this.projectId;
    gdata["pageIndex"] = this.pageIndex;
    gdata["pageSize"] = this.pageSize;

    this.confirm.open(ConfirmComponent, gdata, "analysis", gdata.name);
    this.userForm.reset();
    this.selectedOrg = "";
  }

  goHistory(gdata) {
    // this.router.navigate(['/admin/analyses/'+gdata.mainId])
    window.location.href =
      "/admin/analyses/" + gdata.mainId + "?org=" + gdata.user._id;
  }

  viewEdit(gdata) {
    if (gdata?.status === "in_progress" && this.marketAnalysis.isCompany()) {
      this.router.navigate(["/analysis/new/" + gdata.id], {
        relativeTo: this.route,
        queryParams: { org: gdata.user._id },
      });
    } else {
      this.router.navigate(["/analysis/view/" + gdata.id], {
        relativeTo: this.route,
        queryParams: { org: gdata.user._id },
      });
    }
  }

  actionEdit(u = "", l = "") {}

  gotoPage(page = 1) {
    this.marketAnalysisStore.getAnalysisCharts(
      this.q,
      this.selectedDate,
      this.selectedDateEnd,
      this.projectId,
      page,
      this.pageSize
    );
    this.managePage();
  }

  //change table data on pagination
  onPaginateChange(event) {
    this.paginationLoader = true;
    this.pageIndex = event.pageIndex + 1;
    this.pageIndexPage = event.pageIndex;
    this.pageSize = event.pageSize;

    // length: 24
    // pageIndex: 1
    // pageSize: 5
    // previousPageIndex: 0
    this.marketAnalysisStore.getAnalysisCharts(
      this.q,
      this.selectedDate,
      this.selectedDateEnd,
      this.projectId,
      this.pageIndex,
      this.pageSize
    );
    console.log(event);
    console.log("paginationLoader openOrder", this.paginationLoader);
    this.managePage();

    //localStorage.setItem('openOrderPaginationPage',  this.pageIndex.toString() );
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.offsetHeight / 2 +
        document.documentElement.offsetHeight / 3
    ) {
      // you're at the bottom of the page

      let tot = this.allAnlysis.length;
      console.log(
        "load",
        this.viewType,
        !this.loadingSilently,
        this.pageSize,
        this.pageLength,
        tot
      );

      if (
        this.viewType === "grid" &&
        !this.loadingSilently &&
        this.pageIndex < this.lastPage
      ) {
        let org = "a";
        if (this.selectedOrg !== "") {
          org = this.selectedOrg;
        } else if (this.selectedOrg === "" && this.marketAnalysis.isCoach()) {
          org = "coach";
        }

        this.loadingSilently = true;
        this.pageIndex = this.pageIndex + 1;
        this.pageSize = 6; //this.pageSize+6;
        this.getAnalysisCharts(
          org,
          this.selectedDate,
          this.selectedDateEnd,
          this.projectId,
          this.pageIndex,
          this.pageSize,
          false
        );

        console.log(
          "End",
          window.innerHeight,
          window.scrollY,
          document.body.offsetHeight
        );
      }
    }
    //     //In chrome and some browser scroll is given to body tag
    //     let offsetHeight =document.documentElement.offsetHeight;
    // let pos = (document.documentElement.scrollTop || document.body.scrollTop); // +offsetHeight
    // let max = document.documentElement.scrollHeight;
  }

  getAnalysisCharts(
    q = "",
    date = "",
    end = "",
    anyId = "",
    page = 1,
    size = 5,
    silent = false
  ) {
    return this.marketAnalysis
      .getAnalysisCharts(q, date, end, anyId, page, size)
      .subscribe((resposne: any) => {
        let arr = resposne.data;
        this.analysisPage = resposne.analysisPage;

        this.pageLength = this.analysisPage.total;
        this.lastPage = this.analysisPage.last_page;
        this.pageIndex = this.analysisPage.current_page;
        this.pageSize = this.analysisPage.per_page;
        console.log("analysisChartsPageCOmpo", this.analysisPage);
        this.loadingSilently = silent;

        if (arr !== undefined && arr.length) {
          arr = arr.map((attr) => new AnalysisChart(attr));
          this.allAnlysis = this.allAnlysis.concat(arr); //  =arr;
          console.log("arr", this.allAnlysis);
        }
      });
  }

  ngOnDestroy() {
    console.log("Admi analysis foo destroy");
    this.marketAnalysisStore.getAnalysisCharts().unsubscribe();
    if (this.subsPage !== undefined) {
      this.subsPage.unsubscribe;
    }

    if (this.subsData !== undefined) {
      this.subsData.unsubscribe;
    }
  }

  renameAnalysis(analysis) {
    this.marketAnalysisStore.renameAnalysis(analysis);
  }

  getLogo(url) {
    if (url) {
      return `${environment.apiUrl}storage/${url}`;
    }

    return "";
  }
}
