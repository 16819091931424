import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../../authentication/guard/auth.guard';
import { MarketAnalysisComponent } from './components/market-analysis/market-analysis.component';
import { ViewAnalysisComponent } from './components/view-analysis/view-analysis.component';
import { UnsavedInfoGuard } from '../../shared/guard/unsaved-info.guard';

const routes: Routes = [
  {
    path: '',
    component: MarketAnalysisComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedInfoGuard],
    data: {
      title: '',
      breadcrumb: ''
    }
  },
  {
    path: ':mode/:id',
    component: ViewAnalysisComponent,
    canActivate: [AuthGuard],
    data: {
      title: '',
      breadcrumb: ''
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketAnalysisRoutingModule {}
