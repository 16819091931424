import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { EventData } from "../../models/analysis";
import { MarketAnalysisService } from "../../services/market-analysis.service";
import {
  GraphData,
  MarketFitAnalysisGraph,
} from "../../../../shared/components/mo-analysis-graph/graph-data.model";

import {
  GraphConfig,
  graphConfig,
} from "../../../../shared/components/mo-analysis-graph/graph-config";
import { Attractiveness } from "../../models/market-characteristics/attractiveness.model";
import * as _ from "lodash";
import { MarketAnalysisStore } from "../../services/market-analysis.store";

@Component({
  selector: "app-mo-analysis",
  templateUrl: "./mo-analysis.component.html",
  styleUrls: ["./mo-analysis.component.scss"],
})
export class MoAnalysisComponent implements OnInit {
  @Output()
  onStepperChange: EventEmitter<EventData> = new EventEmitter<EventData>();
  graphData: GraphData[] = [];
  marketFitAnalysis: any[] = [];

  initialSteps: EventData = {
    stepNumber: 6,
    locations: 0,
    stateChanged: false,
  };

  currentTab = "moAnalysis";
  moAnalysisData;
  isFormValueChanged: boolean = true;

  chartConfig: GraphConfig = graphConfig;

  constructor(
    private marketAnalysis: MarketAnalysisService, 
    private marketAnalysisStore: MarketAnalysisStore
  ) {}

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "mo-analysis"});

    this.chartConfig.label.yLabel = "Market attractiveness";
    this.chartConfig.label.xLabel = "Market fit";
    this.chartConfig.showPointLabel = true;
    this.chartConfig.height = 410;
    this.chartConfig.width = 560;
    this.onStepperChange.emit(this.initialSteps);
    this.moAnalysisData = this.marketAnalysis.getItemFromStorage("analysis");
    this.makeGraphData(this.moAnalysisData);
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  makeGraphData(moAnalysisData) {
    const locations = moAnalysisData.marketCharacteristics.location;
    const colorList = moAnalysisData.analysis.colorList;

    for (let index = 0; index < locations.length; index++) {
      let location = locations[index];

      const filteredLocation = _.filter(
        moAnalysisData["moAnalysis"]["graph"],
        function (e) {
          return (
            e.name.toLowerCase() === locations[index]["name"].toLowerCase()
          );
        }
      );

      if (filteredLocation.length) {
        location = filteredLocation[0];
      }
      let color;
      try {
        color = colorList[index];
      } catch (error) {
        color = location.color;
      }
      let attrValue = this.calculateAttractiveness(location.name);
      this.marketFitAnalysis.push({
        name: location.name,
        color: color,
        attractiveness: parseInt(attrValue.attractiveness),
        marketSize: attrValue.marketSize,
        marketFit: attrValue.marketFit,
      });
    }

    this.graphData = this.marketFitAnalysis.map(
      (analysis) => new MarketFitAnalysisGraph(analysis)
    );
    if (!moAnalysisData.moAnalysis.graph.length) {
      this.storeMoAnalysisGraphData(false, true);
    }
  }

  calculateAttractiveness(countryName) {
    let score = 0;
    let marketFit = 0;
    let marketSize = 0;

    this.moAnalysisData.marketCharacteristics.attractiveness.forEach(
      (rate: Attractiveness, key) => {
        let rateEveryMarket = rate.rateEveryMarket.filter(
          (rem) => rem.name.toLowerCase() === countryName.toLowerCase()
        );
        let filteredRate;
        if (rateEveryMarket.length) {
          filteredRate = rateEveryMarket[0];
        }
        let scoreFilled = 0;

        if (filteredRate?.scoreFilled) {
          scoreFilled = parseInt(filteredRate?.scoreFilled);
        }

        if (key === 0) {
          if (filteredRate?.absoluteNumber) {
            marketSize = filteredRate.absoluteNumber;
          }
        }

        if (rate.parameterOfHomeMarket) {
          score = score + scoreFilled * rate.weightFactor;
        }
      }
    );

    this.moAnalysisData.marketFit.attractiveness.forEach(
      (rate: Attractiveness, key) => {
        let rateEveryMarket = rate.rateEveryMarket.filter(
          (rem) => rem.name.toLowerCase() === countryName.toLowerCase()
        );
        let filteredRate;
        if (rateEveryMarket.length) {
          filteredRate = rateEveryMarket[0];
        }

        if (rate.parameterOfHomeMarket) {
          let scoreFilled = 0;
          if (filteredRate?.scoreFilled) {
            scoreFilled = parseInt(filteredRate?.scoreFilled);
          }

          marketFit = marketFit + scoreFilled * rate.weightFactor;
        }
      }
    );

    return {
      marketSize: marketSize,
      marketFit: marketFit,
      attractiveness: score.toFixed(2),
    };
  }

  storeMoAnalysisGraphData(isRedirect = false, isStoreSilently = false) {
    console.log("this.isFormValueChanged", this.isFormValueChanged);
    if (!this.isFormValueChanged) {
      this.changeToNext();
      return false;
    }

    const market = {
      graph: this.marketFitAnalysis,
      type: "moAnalysisGraph",
      analysisId: this.moAnalysisData["analysis"]["id"],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1,
    };
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      if (isRedirect) {
        this.marketAnalysis.exit();
        return;
      }
      this.changeToNext(response.data.moAnalysis, isStoreSilently);
    });
  }

  changeToNext(graphData = null, isStoreSilently = false) {
    let _analysis;

    if (graphData) {
      this.moAnalysisData[this.currentTab]["graph"] = graphData;
    }

    if (!isStoreSilently) {
      this.moAnalysisData["currentTab"] = "costs";
      this.moAnalysisData["current"] = +this.moAnalysisData["current"] + 1;
    }

    _analysis = this.moAnalysisData;

    this.marketAnalysis.setCurrentStep("analysis", _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);
  }
}
