import { Injectable } from '@angular/core';
import { Service } from '../../../../shared/service/service';
import { LocationService } from './location.service';
import { BehaviorSubject } from 'rxjs';
import { Country } from '../../models/market-characteristics/country.model';

@Injectable({
  providedIn: 'root'
})
export class LocationStore extends Service {
  locations: BehaviorSubject<Country[]> = new BehaviorSubject([]);

  constructor(private locationService: LocationService) {
    super();
  }

  getLocations(value) {
    return this.locationService.getLocations(value).subscribe(response => {
      this.locations.next(response);
    });
  }
}
