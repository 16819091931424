import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Response } from '../../../../shared/model/response';
import { SectorAdmin } from '../../models/sector-admin.model';
import { NotifyService } from '../../../../shared/service/notify.service';

@Injectable({
  providedIn: 'root'
})
export class SectorAdminService {
  constructor(private httpClient: HttpClient,
    private notify: NotifyService) {}

  getSectors(q = '') {
    const url = `${environment.apiUrl}api/sectors?q=${q}`;
    return this.httpClient.get(url).pipe(
      map((response: Response) => {
        return response.data.map(sector => new SectorAdmin(sector));
      })
    );
  }
  
  saveSectors(q = '',value) {
    const url = `${environment.apiUrl}api/sectors-admin?q=${q}`;
    return this.httpClient.post(url,value).pipe(
      map((response: Response) => {
        this.notify.notifySuccess(response.message);
        return response.data.map(sector => new SectorAdmin(sector));
      })
    );
  }
}
