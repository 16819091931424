import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketAnalysisRoutingModule } from './market-analysis.routing';
import { LocationComponent } from './components/market-characteristics/location/location.component';
import { SharedModule } from '../../shared/shared.module';
import { McAttractivenessComponent } from './components/market-characteristics/mc-attractiveness/mc-attractiveness.component';
import { McCompareMarketsComponent } from './components/market-characteristics/mc-compare-markets/mc-compare-markets.component';
import { StaticStepInfoComponent } from './components/static-step-info/static-step-info.component';
import { RateEveryMarketComponent } from './components/market-characteristics/rate-every-market/rate-every-market.component';
import { MarketAnalysisComponent } from './components/market-analysis/market-analysis.component';
import { MarketFitComponent } from './components/market-fit/market-fit.component';
import { MfAttractivevenessComponent } from './components/market-fit/mf-attractiveness/mf-attractiveness.component';
import { MfRateEveryMarketComponent } from './components/market-fit/mf-rate-every-market/mf-rate-every-market.component';
import { MfCompareMarketComponent } from './components/market-fit/mf-compare-market/mf-compare-market.component';
import { MoAnalysisComponent } from './components/mo-analysis/mo-analysis.component';
import { YourGoalComponent } from './components/costs/your-goal/your-goal.component';
import { EstimatedCostsComponent } from './components/costs/estimated-costs/estimated-costs.component';
import { MocoAnalysisComponent } from './components/moco-analysis/moco-analysis.component';
import { ViewAnalysisComponent } from './components/view-analysis/view-analysis.component';
import { CheckDataLossModalComponent } from './components/check-data-loss-modal/check-data-loss-modal.component';
import { AnalysisReportComponent } from './components/analysis-report/analysis-report.component';
import { EditConfirmationModalComponent } from './components/edit-confirmation-modal/edit-confirmation-modal.component';

@NgModule({
  declarations: [
    LocationComponent,
    McAttractivenessComponent,
    McCompareMarketsComponent,
    StaticStepInfoComponent,
    RateEveryMarketComponent,
    MarketAnalysisComponent,
    MarketFitComponent,
    MfAttractivevenessComponent,
    MfRateEveryMarketComponent,
    MfCompareMarketComponent,
    MoAnalysisComponent,
    YourGoalComponent,
    EstimatedCostsComponent,
    MocoAnalysisComponent,
    ViewAnalysisComponent,
    CheckDataLossModalComponent,
    AnalysisReportComponent,
    EditConfirmationModalComponent
  ],
  imports: [CommonModule, SharedModule, MarketAnalysisRoutingModule],
  //entryComponents: [CheckDataLossModalComponent, EditConfirmationModalComponent]
})
export class MarketAnalysisModule {}
