import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Response } from "../../../shared/model/response";
import { Service } from "../../../shared/service/service";

@Injectable({
  providedIn: "root",
})
export class EstimatedCostService extends Service {
  constructor(private httpClient: HttpClient, public readonly fb: FormBuilder) {
    super(`${environment.apiUrl}api/analysis`, httpClient);
  }

  locations: any[];

  getEstimatedCostForm(data) {
    return this.get(data).pipe(
      map((response: Response) => {
        console.log(response);
        const data = response.data;
        const categories = data.categories;
        this.locations = data.locations;

        return this.fb.group({
          categories: this.fb.array(
            categories.map((category) => this.generateCategory(category))
          ),
          locations: this.fb.array(
            this.locations.map((location) => this.generateLocation(location))
          ),
          unforeseen: this.fb.group({
            tax: [30],
            countries: this.fb.array(
              this.locations.map((location) =>
                this.generateUnforeSeenLocation(location)
              )
            ),
          }),
        });
      })
    );
  }
  generateLocation(location) {
    return this.fb.group({
      name: location.name,
    });
  }
  generateCategory(category) {
    const categoryGroup = this.fb.group({
      id: [category.id],
      name: [category.name],
      toogleAble: [category.toogleAble],
      subCategories: this.fb.array(
        category.subCategories.map((subCategory) =>
          this.generateSubCategory(subCategory)
        )
      ),
    });
    return categoryGroup;
  }

  generateSubCategory(subCategory) {
    let locations = this.locations;
    let graphs = subCategory.countries;
    if (subCategory.countries.length === this.locations.length) {
      locations = subCategory.countries;
      graphs = [];
    }
    const subCategoryGroup = this.fb.group({
      id: [subCategory.id],
      name: [subCategory.name],
      isDisabled: [subCategory.isDisabled],
      readOnly: [subCategory.isDisabled],
      autofocus: [false],
      isCustom: [subCategory.isCustom],
      countries: this.fb.array(
        locations.map((location) =>
          this.generateSubCategoryCountry(location, graphs)
        )
      ),
    });

    return subCategoryGroup;
  }

  generateSubCategoryCountry(location, oldLocations = []) {
    if (oldLocations.length) {
      location = this.getModifiedLocation(location, oldLocations);
    }
    // console.log(location);
    const countryGroup = this.fb.group({
      id: [location.id],
      name: [location.name],
      cost: [location?.cost],
      totalTax: [location?.totalTax],
      total: [location?.total],
      autofocus: [location?.autofocus],
    });
    return countryGroup;
  }

  generateUnforeSeenLocation(location) {
    location = this.getModifiedLocation(location);
    return this.fb.group({
      id: [],
      name: [location.name],
      attractiveness: [location?.attractiveness],
      color: [location?.color],
      marketFit: [location?.marketFit],
      marketSize: [location?.marketSize],
      totalTax: [0],
      total: [0],
      opportunity: [(location.attractiveness + location.marketFit) / 2],
    });
  }

  getModifiedLocation(location, graphs = []) {
    const estimatedCostData = this.getItemFromStorage("analysis");
    if (!graphs.length) {
      graphs = estimatedCostData["moAnalysis"]["graph"];
    }
    let filterLocation = graphs.filter(
      (lc) => lc.name.toLowerCase() === location.name.toLowerCase()
    );
    if (filterLocation.length) {
      location = filterLocation[0];
    }

    return location;
  }

  calculated = {
    totalTax: 0,
    total: 0,
  };
  tax: number = 0;

  calculateTotal(estimatedCostForm, countryIndex) {
    this.calculated["total"] = 0;
    this.calculated["totalTax"] = 0;
    // console.log(estimatedCostForm.value);
    const _categories = estimatedCostForm.value.categories;
    _categories.forEach((category, key) => {
      const subCategories = category.subCategories;
      subCategories.forEach((subCat, sKey) => {
        const countries = subCat.countries;
        if (countries[countryIndex]) {
          this.calculated["total"] =
            this.calculated["total"] +
            this.getParsedCost(+countries[countryIndex].cost);

          this.calculated["totalTax"] =
            this.calculated["totalTax"] +
            this.getParsedCost(+countries[countryIndex].cost);
        }
      });
    });

    // const unforeseenCountry = this.unforeseenCountry;
    let calculatedTax = 0;
    let _tax = estimatedCostForm.controls["unforeseen"].get("tax").value;
    let tax = 0;

    if (_tax) {
      tax = parseFloat(_tax);
    }

    calculatedTax = (this.calculated["totalTax"] * tax) / 100;

    // if (type === "totalTax") {
    this.calculated["totalTax"] = calculatedTax;
    // }

    // if (type === "total") {
    this.calculated["total"] =
      this.calculated["total"] + this.calculated["totalTax"];
    estimatedCostForm.controls["unforeseen"].controls["countries"]
      .at(countryIndex)
      .patchValue({
        totalTax: calculatedTax,
        total: this.calculated["total"],
      });
    // }

    return this.calculated;
  }

  getParsedCost(cost) {
    return cost ? cost : 0;
  }
}
