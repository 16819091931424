/**
 * Top menubar for all type of member
 */
export const navItemsForTop: any[] = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-home",
    
    class: "show",
  },
];

/**
 * Additional navbar for super admin add more menu to super admin
 */
export const navItemsForSuperAdmin: any[] = [
  // {
  //   title: true,
  //   name: 'Log'
  // },
  // {
  //   name: 'Activity log',
  //   url: '/logs/activity',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Access log',
  //   url: '/logs/access',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Error log',
  //   url: '/logs/error',
  //   icon: 'icon-drop'
  // },
  // {
  //   title: true,
  //   name: 'Users'
  // },
  {
    title: true,
    name: "Manage",
  },
  {
    name: "Organizations",
    url: "/company",
    icon: "icon-drop",
  },
  {
    name: "Coach",
    url: "/coach/list",
    icon: "icon-drop",
  },
  {
    name: "Locations",
    url: "/admin/locations",
    icon: "icon-location-pin",
  },
  {
    name: "Categories",
    url: "/admin/categories",
    icon: "fa fa-code-fork",
  },
  {
    name: "Sectors",
    url: "/admin/sectors",
    icon: "fa icon-flag",
  },
  {
    name: "Steps Text",
    url: "/admin/stepstext",
    icon: "icon-drop",
  },
  {
    name: "Analysis",
    url: "/admin/analyses",
    href: "/admin/analyses",
    icon: "icon-chart",
  },
];

/**
 * Navbar for super admin and admin add more menu
 */

export const navItemsForAdminAndSuperAdmin: any[] = [
  // {
  //   title: true,
  //   name: 'Analyze'
  // },
  // {
  //   name: 'Reports',
  //   url: '/reports',
  //   icon: 'icon-drop'
  // },
  // {
  //   title: true,
  //   name: 'Company'
  // },
  // {
  //   name: 'List company',
  //   url: '/company',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Add company',
  //   url: '/company/new',
  //   icon: 'icon-drop'
  // }
  // {
  //   title: true,
  //   name: 'Team',
  //
  // },
];

/**
 * Comman nav menu for all role
 */

export const navItemsForAll: any[] = [
  // add here for all type of member
  // {
  //   name: 'Projects',
  //   url: '/projects',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Tasks',
  //   url: '/tasks',
  //   icon: 'icon-speedometer'
  // },

  // place setting always to bottom
  {
    name: "Settings",
    url: "/settings",
    icon: "icon-settings",
  },
];

export const navItemsForCoach = [
  {
    title: true,
    name: "Manage",
  },
  {
    name: "Organizations",
    url: "/company",
    icon: "icon-drop",
  },
  // {
  //   name: "Analysis",
  //   url: "/admin/analyses",
  //   href: "/admin/analyses",
  //   icon: "icon-chart",
  // },
];

export const navItemsForCoachBelow = [
  {
    name: "Analysis",
    url: "/admin/analyses",
    href: "/admin/analyses",
    icon: "icon-chart",
  },
];
