import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, of, empty } from 'rxjs';
import { MarketAnalysisService } from '../../market-analysis/services/market-analysis.service';
import {
  startWith,
  debounceTime,
  switchMap,
  catchError,
  tap
} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MarketAnalysisStore } from '../../market-analysis/services/market-analysis.store';

@Component({
  selector: 'app-rename-analysis',
  templateUrl: './rename-analysis.component.html',
  styleUrls: ['./rename-analysis.component.scss']
})
export class RenameAnalysisComponent implements OnInit {
  formGroup: FormGroup;
  duplicateChecker: Subscription;
  nameCheckerMessage = '';
  loading: boolean = false;
  storeAble: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RenameAnalysisComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private marketAnalysisService: MarketAnalysisService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.formGroup = new FormGroup({
      analysisName: new FormControl(this.data.name)
    });

    this.duplicateChecker = this.formGroup
      .get('analysisName')
      .valueChanges.pipe(
        tap(value => {
          this.loading = true;
          return value;
        }),
        startWith(''),
        debounceTime(1000),
        switchMap(value => {
          this.nameCheckerMessage = '';
          this.storeAble = false;
          if (value) {
            this.loading = true;
            this.nameCheckerMessage = 'Checking for duplicate analysis...';
            return this.marketAnalysisService.checkAnalysisName(value).pipe(
              catchError((error: HttpErrorResponse) => {
                console.log(error);
                this.loading = false;
                this.nameCheckerMessage = '';
                if (error.status === 422) {
                  this.nameCheckerMessage =
                    'The name already exists, Please try a different name.';
                }
                return empty();
              })
            );
          }
          // don't call
          return of();
        })
      )

      .subscribe(
        response => {
          console.log(response);
          this.nameCheckerMessage = 'Great, this analysis title is available to use.';
          this.loading = false;
          this.storeAble = true;
        },
        err => {
          throw new Error(err);
        }
      );

    // this.duplicateChecker
  }

  copyAsNewAnalysis() {
    if (!this.storeAble) {
      return;
    }
    let data = {
      name: this.formGroup.value.analysisName,
      id: this.data.id
    };

    this.marketAnalysisService.renameAnalysisName(data).subscribe(response => {
      console.log(response);
      this.marketAnalysisService.analysisRenamed.next(response);
      this.dialogRef.close(this.formGroup.value);
    });
  }

  ngOnDestroy() {
    if (this.duplicateChecker) {
      this.duplicateChecker.unsubscribe();
    }
  }
}
