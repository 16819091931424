export interface SectorInterface {
  id: string;
  name: string;
}

export class Sector {
  id: string;
  name: string;
  itemName: string;

  constructor(
    sector: SectorInterface = {
      id: '',
      name: ''
    }
  ) {
    this.id = sector.id;
    this.name = sector.name;
    this.itemName = sector.name;
  }
}
