// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  border-bottom: 1px solid #efefef !important;
  padding: 0.75rem !important;
}

.legends .item {
  padding: 0px 1px;
  border: none;
  font-size: 13px;
  font-weight: 500;
}

.base-logo {
  font-size: 0.8em;
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/mo-analysis-graph/mo-analysis-graph.component.scss"],"names":[],"mappings":"AAOA;EACI,2CAAA;EACA,2BAAA;AANJ;;AAWI;EACI,gBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AARR;;AAYA;EACI,gBAAA;EACA,WAAA;AATJ","sourcesContent":["@import \"../../../../scss/variables\";\r\n\r\n\r\n// .graph-tooltip {\r\n//     background: #ffffff;\r\n// }\r\n\r\n.card-header {\r\n    border-bottom: 1px solid $inputbg !important;\r\n    padding: .75rem !important\r\n}\r\n\r\n.legends {\r\n\r\n    .item {\r\n        padding: 0px 1px;\r\n        border: none;\r\n        font-size: 13px;\r\n        font-weight: 500;\r\n    }\r\n}\r\n\r\n.base-logo {\r\n    font-size: 0.8em;\r\n    color: grey;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
