export interface CategoryAdminInterface {
  id: string;
  name: string;
  parent: any[];
  parentSelect: any[];
  subcats: any[];
  tags: string;
  toogleAble: boolean;
}

export class CategoryAdmin {
  id: string;
  name: string;
  parent: any[];
  tags: string;
  parentSelect: any[];
  subcats: any[];
  subCategories: any[];
  toogleAble: boolean;
  editable:boolean;
  newSubcat:string;
  subcatEdit:any;
  subcatEditId:string;
  editcat:boolean;

  constructor(
    data: CategoryAdminInterface = {
      id: '',
      name: '',
      parent: [],
      parentSelect: [],
      subcats: [],
      tags: '',
      toogleAble: false,
    }
  ) {
    this.editable=false;
    this.editcat=false;
    this.newSubcat=''; 
    this.subcatEditId='';
    this.subcatEdit={};
    this.id = data.id;
    this.name = data.name;
    this.parent = data.parent;
    this.parentSelect = data.parentSelect;
    this.subcats = data.subcats;
    this.tags = '';
    this.subCategories = this.subcats;
    this.toogleAble = data.toogleAble;
  }
}
