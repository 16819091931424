import { Directive, HostListener } from '@angular/core';
import $ from 'jquery';

@Directive({
  selector: '[keypress-input]'
})
export class InputIncrementDecrementDirective {
  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  }
}
