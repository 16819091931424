import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { EventData } from "../../../models/analysis";
import { MarketAnalysisStore } from "../../../services/market-analysis.store";
import { Country } from "../../../models/market-characteristics/country.model";
import { FormGroup, FormArray, FormControl } from "@angular/forms";
import { CategoryAdmin } from "../../../../moco-admin/models/category-admin.model";
import { NotifyService } from "../../../../../shared/service/notify.service";
import { MarketAnalysisService } from "../../../services/market-analysis.service";
import { SettingsService } from "../../../../../shared/service/settings.service";
import { Observable, Subscription } from "rxjs";
import $ from "jquery";
import { EstimatedCostService } from "../../../services/estimated-cost.service";

export const defaultTax = 30;

@Component({
  selector: "app-estimated-costs",
  templateUrl: "./estimated-costs.component.html",
  styleUrls: ["./estimated-costs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstimatedCostsComponent implements OnInit {
  @Output()
  onStepperChange: EventEmitter<EventData> = new EventEmitter<EventData>();
  marketAnalysisStoreSbs: Subscription;

  initialSteps: EventData = {
    stepNumber: 8,
    locations: 0,
    stateChanged: false,
    isFormChanged: false,
  };

  currentTab = "costs";
  categoriesList: CategoryAdmin[];
  locations: Country[];
  estimatedCostForm: FormGroup;

  isEditEnabled: boolean = false;
  estimatedCostData;
  prevData: any;

  constructor(
    private marketAnalysisStore: MarketAnalysisStore,
    private notify: NotifyService,
    private marketAnalysis: MarketAnalysisService,
    public costService: EstimatedCostService,
    private settingsService: SettingsService,
    private ref: ChangeDetectorRef
  ) {}

  form$: Observable<FormGroup>;

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "estimated-costs"});

    this.onStepperChange.emit(this.initialSteps);
    this.estimatedCostData = this.marketAnalysis.getItemFromStorage("analysis");
    this.form$ = this.costService.getEstimatedCostForm(
      `q=estimated-costs&_a=${this.estimatedCostData["analysis"]["id"]}&mode=`
    );

    // this.marketAnalysisStoreSbs = this.marketAnalysisStore.marketCharacteristics.subscribe(
    //   (resp) => {
    //     console.log(resp);
    //   }
    // );
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  toggleAble(category) {
    category.get("toogleAble").setValue(!category.get("toogleAble").value);
  }

  removeCategory(subCategories, index) {
    subCategories.removeAt(index);
  }

  removeSubCategory(category, subCatIndex) {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }
    const categories = category.value;
    const subCategory = category.controls["subCategories"].at(subCatIndex);
    const subCategoryId = subCategory.value.id;
    const data = {
      categoryId: categories.id,
      subCategoryId: subCategoryId,
    };
    console.log(data);
    category.controls["subCategories"].removeAt(subCatIndex);
    if (subCategoryId) {
      this.settingsService.delete(data, "estimatedCost");

      this.settingsService.isDeleting.subscribe((response) => {
        if (response) {
          // this.setToStorage(this.categories.value);
        }
      });
    }
  }

  inputSpinner(event, form, country, index, mode) {
    console.log('Event:: ', event);
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }

    if (event.type != "keydown" && event.type != "click") {
      return;
    }
    if (event.type == "keydown") {
      if (event.code == "ArrowDown") {
        mode = "down";
      } else if (event.code == "ArrowUp") {
        mode = "up";
      } else {
        return;
      }
    }

    if (mode == "up") {
      country.get("cost").setValue(country.get("cost").value + 1000);
    } else {
      country.get("cost").setValue(country.get("cost").value - 1000);
    }
    this.resetCursorPointer(form, index, false);
    country.get("autofocus").setValue(true);

    this.renderGraph(form);
  }

  renderGraph(form) {
    let timeOut = setTimeout(() => {
      this.marketAnalysisStore.updateChart.next({
        isUpdate: true,
        data: form.value.unforeseen.countries,
      });
    }, 10);
  }

  addSubCategory(
    category,
    index: number,
    isDisabled: boolean = true,
    isCustom = true
  ) {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }
    this.pushSubCategory(category, index, isDisabled, isCustom);
    // const subCategories = this.getSubCategories(index);
    // let lastIndex = subCategories.length - 1;
    // let lastSCIndex = `sc-${index}-${lastIndex}`;

    // this.resetCursorPointer(lastSCIndex, true);
  }

  pushSubCategory(
    category,
    index: number,
    isDisabled: boolean = true,
    isCustom: boolean = false
  ): void {
    const subCategories = category.controls["subCategories"];
    subCategories.push(
      new FormGroup({
        id: new FormControl(""),
        name: new FormControl(""),
        isDisabled: new FormControl(isDisabled),
        countries: this.costService.fb.array(
          this.costService.locations.map((location) =>
            this.costService.generateSubCategoryCountry(location)
          )
        ),
        readOnly: new FormControl(isDisabled),
        autofocus: new FormControl(true),
        isCustom: new FormControl(isCustom),
      })
    );

    // this.isOpenCategory(index, true);
  }

  storeEstimatedCosts(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */

    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }

    if (this.marketAnalysisStoreSbs) {
      this.marketAnalysisStoreSbs.unsubscribe();
    }

    // check for less than zero cost
    if (!isStoreSilently && this.estimatedCostForm.value) {
      let categories = this.estimatedCostForm.value.categories;

      for (let index = 0; index < categories.length; index++) {
        const category = categories[index];
        const subcategories = category.subCategories;

        for (let index = 0; index < subcategories.length; index++) {
          const subCategory = subcategories[index];
          const countries = subCategory.countries;
          for (let index = 0; index < countries.length; index++) {
            const country = countries[index];
            // console.log(country.cost, typeof country.cost);
            if (typeof country.cost === "number" && country.cost < -1) {
              this.notify.notifyError("Cost value will be greater than 0.");
              return;
            }
          }
        }
      }
    }

    /**check for enable update */
    let isEditEnabled: boolean = false;
    // this.categories.value.forEach((element) => {
    //   /**  */
    //   element.subCategories.forEach((subCat) => {
    //     if (!subCat.isDisabled) {
    //       isEditEnabled = true;
    //       return false;
    //     }
    //   });
    // });

    /**uncommnet it for plus button */
    // if (!isStoreSilently && !isEditEnabled) {
    //   this.notify.notifyError(
    //     'You have enabled the category edit, please save your changes',
    //     'Sub category edit'
    //   );
    //   return;
    // }

    console.log(this.estimatedCostForm);
    const market = {
      // filter only valid record
      estimatedCost: this.estimatedCostForm.value,
      prevData: this.prevData,
      nextData: this.estimatedCostForm.value,
      type: "estimatedCost",
      mode: this.estimatedCostData["aMode"],
      analysisId: this.estimatedCostData["analysis"]["id"],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1, // set next step
      isStoreSilently: isStoreSilently,
    };

    /**let's store the data */
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      if (!isStoreSilently) {
        this.estimatedCostForm.patchValue({
          categories: response.data.categories,
        });
      }

      const data = {
        categories: response.data.categories,
        graphs: response.data.moAnalysis,
        costGoal: response.data.analysis.costGoal,
      };
      if (isRedirect) {
        this.marketAnalysis.exit();
        return;
      }
      this.changeToNext(data, isStoreSilently);
    });
  }

  changeToNext(data = null, isStoreSilently = false) {
    let _analysis;
    if (!isStoreSilently) {
      this.estimatedCostData["current"] =
        +this.estimatedCostData["current"] + 1;

      this.estimatedCostData["currentTab"] = "mocoAnalysis";
    }
    if (data) {
      this.estimatedCostData[this.currentTab]["estimated"] = data.categories;
      this.estimatedCostData["mocoAnalysis"]["graph"] = data.graphs;
      this.estimatedCostData["costs"]["goal"] = data.costGoal;
    }

    _analysis = this.estimatedCostData;

    this.marketAnalysis.setCurrentStep("analysis", _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  emitformDirty() {
    this.initialSteps.isRefreshOnCancel = false;
    this.initialSteps.isFromDirty = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  calculateTotal(estimatedCostForm, countryIndex) {
    this.estimatedCostForm = estimatedCostForm;
    return this.costService.calculateTotal(estimatedCostForm, countryIndex);
  }

  resetCursorPointer(form, index = null, isSubCat = false) {
    const categories = form.value.categories;

    categories.map((category, i) => {
      category.subCategories = category.subCategories.map((sc, j) => {
        if (!isSubCat) {
          sc.countries = sc.countries.map((c, k) => {
            form.controls["categories"]
              .at(i)
              .controls["subCategories"].at(j)
              .controls["countries"].at(k)
              .get("autofocus")
              .setValue(false);
            return c;
          });
        }
        return sc;
      });
      return category;
    });
  }
}
