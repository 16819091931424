export interface StepsAdminInterface {
  id: string;
  name: string;
  tab:string;
  title:any;
  iscollapsed: boolean;
  videoLink: string;
  videoType: string;
  videoTitle: string;
  videoTitleShort: string;
}

export class StepsAdmin {
  id: string;
  name: string;
  tab:string;
  title:any;
  iscollapsed: boolean;
  videoLink: string;
  videoType: string;
  videoTitle: string;
  videoTitleShort: string;

  constructor(
    data: StepsAdminInterface = {
      id: '',
      name: '',
      title: '',
      tab: '',
      iscollapsed: false,
      videoLink: '',
      videoType: '',
      videoTitle: '',
      videoTitleShort: '',
    }
  ) {
   
    this.id = data.id;
    this.name = data.name;
    this.title = data.title;
    this.iscollapsed = data.iscollapsed;
    this.tab = data.tab;
    this.videoLink = data.videoLink;
    this.videoType = data.videoType;
    this.videoTitle = data.videoTitle;
    this.videoTitleShort = data.videoTitleShort;
  }
}
