import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../authentication/service/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {
  constructor(public router: Router, private auth: AuthorizationService) {}

  handleError(error: HttpErrorResponse) {
    if (error.status === 401 || error.statusText === 'Unknown Error') {
      this.auth.deleteAccessToken();
      this.router.navigate(['/login']);
     //this.auth.isLoggedIn.next(true);
    } else {
      this.auth.isLoggedIn.next(true);
    }
  }
}
