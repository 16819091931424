import * as moment from "moment";
import { Moco } from "../../../shared/model/moco";

export interface CompanyInterface {
  _id: string;
  name: string;
  email?: string;
  is_email_verified?: boolean;
  role?: string;
  created_at?: string;
  sector?: any;
  roles: any;
  personName: string;
  pics: any;
  status: string;
  last_login: string;
  organizations?: [];
  organizations_count?: number;
  total_analysis?: number;
  recently_updated_analysis_at: string;
  disabled_cost: boolean;
}

export interface RoleInterface {
  id: number;
  name: string;
}

export class Company extends Moco {
  id: string;
  email: string;
  name: string;
  isEmailVerified: boolean;
  role: string;
  roles: [];
  registeredDate: string;
  description?: string;
  sector: any;
  pics: any;
  personName: string;
  status: string;
  lastLoginAt: string;
  itemName: string;
  organizations: any[];
  organizationsCount: number;
  totalAnalysis: number;
  analysisUpdatedAt: string;
  disabled_cost: boolean;
  constructor(
    company: CompanyInterface = {
      _id: "",
      email: "",
      name: "",
      is_email_verified: false,
      role: "",
      created_at: "",
      roles: [],
      pics: "",
      personName: "",
      status: "",
      last_login: "",
      organizations: [],
      organizations_count: 0,
      total_analysis: 0,
      recently_updated_analysis_at: "",
      disabled_cost: false
    }
  ) {
    super();
    this.id = company._id;
    this.email = company.email;
    this.personName = company.personName;
    this.name = company.name ? company.name : company.personName;
    this.isEmailVerified = company.is_email_verified;
    this.role = company.role;
    // this.registeredDate = moment(user.created_at).format('MMMM Do YYYY');
    this.organizations = company.organizations;
    this.organizationsCount = company.organizations_count;

    this.description = company.name;
    this.sector = company.sector;
    this.roles = company.roles;
    this.itemName = company.name;
    this.pics = company.pics !== undefined ? company.pics : "";
    this.status = company.status === "active" ? company.status : "Inactive";
    this.lastLoginAt = this.toLocale(company?.last_login);
    this.totalAnalysis = company.total_analysis;
    this.analysisUpdatedAt = this.toLocale(
      company.recently_updated_analysis_at
    );
    this.disabled_cost = company.disabled_cost;
  }
}

export class Role {
  id: number;
  name: string;
  itemName: string;

  constructor(
    user: RoleInterface = {
      id: 0,
      name: "",
    }
  ) {
    this.id = user.id;
    this.name = user.name;
    this.itemName = user.name;
  }
}
