import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { EventData, analysis } from "../../models/analysis";
import { MarketAnalysisService } from "../../services/market-analysis.service";
import { MarketAnalysisStore } from "../../services/market-analysis.store";
import * as _ from "lodash";
import {
  GraphData,
  MarketFitAnalysisGraph,
  MocoAnalysisGraph,
} from "../../../../shared/components/mo-analysis-graph/graph-data.model";
import { MocoAnalysis } from "../../models/moco-analysis.model";
import {
  graphConfig,
  GraphConfig,
} from "../../../../shared/components/mo-analysis-graph/graph-config";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "app-analysis-report",
  templateUrl: "./analysis-report.component.html",
  styleUrls: ["./analysis-report.component.scss"],
})
export class AnalysisReportComponent implements OnInit {
  @Output()
  onStepperChange: EventEmitter<EventData> = new EventEmitter<EventData>();

  initialSteps: EventData = {
    stepNumber: 10,
    locations: 0,
    stateChanged: false,
    isFormChanged: false,
  };
  analysisReport;
  moAnalysisGraphData: GraphData[] = [];
  mocoAnalysisGraphData: GraphData[] = [];

  moChartConfig: GraphConfig = { ...graphConfig };
  mocoChartConfig: GraphConfig = { ...graphConfig };
  isCostFilled: any[] = [];
  reportForm: FormGroup;
  prevData: any;

  constructor(
    private marketAnalysisStore: MarketAnalysisStore,
    private marketAnalysis: MarketAnalysisService
  ) {}

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "analysis-report"});

    this.onStepperChange.emit(this.initialSteps);
    this.createReportForm();
    /**assign chart label */

    this.mocoChartConfig.showPointLabel = true;
    this.mocoChartConfig.height = 360;
    this.mocoChartConfig.width = 470;
    this.moChartConfig.showPointLabel = true;
    this.moChartConfig.height = 360;
    this.moChartConfig.width = 470;

    this.analysisReport = this.marketAnalysisStore.analysisFromStorage();

    if (
      this.analysisReport &&
      this.analysisReport["moAnalysis"]["graph"].length &&
      this.analysisReport["mocoAnalysis"]["graph"].length
    ) {
      const moAnalysis = this.analysisReport["moAnalysis"]["graph"];
      let mocoAnalysis = this.analysisReport["mocoAnalysis"]["graph"];
      /**check if cost is filled */
      this.isCostFilled = _.filter(mocoAnalysis, function (moco) {
        return moco.total;
      });

      let locations = this.analysisReport.marketCharacteristics.location;

      let moAnalysisGraphData = moAnalysis.map(
        (analysis) => new MarketFitAnalysisGraph(analysis)
      );
      
      mocoAnalysis = mocoAnalysis.map((analysis) => new MocoAnalysis(analysis));

      let mocoAnalysisGraphData = mocoAnalysis.map(
        (analysis) => new MocoAnalysisGraph(analysis)
      );

      // check markets
      locations.forEach((location) => {
        let moAnalysis = new MarketFitAnalysisGraph(location);
        const filteredLocation = _.filter(moAnalysisGraphData, function (e) {
          return e.name.toLowerCase() === location["name"].toLowerCase();
        });

        if (filteredLocation.length) {
          moAnalysis = filteredLocation[0];
        }
        this.moAnalysisGraphData.push(moAnalysis);
        
        let mocoAnalysis = new MocoAnalysisGraph(location);
        const mocoFilteredLocation = _.filter(
          mocoAnalysisGraphData,
          function (e) {
            return e.name.toLowerCase() === location["name"].toLowerCase();
          }
        );

        if (mocoFilteredLocation.length) {
          mocoAnalysis = mocoFilteredLocation[0];
        }
        this.mocoAnalysisGraphData.push(mocoAnalysis);
      });

      this.reportForm.patchValue(this.analysisReport["analysis"]);
      this.marketAnalysisStore.patchCopyableInfo(this.reportForm);
      this.prevData = this.reportForm.value;
    }

    this.reportForm.valueChanges.subscribe((res) => {
      if (this.reportForm.dirty) {
        this.initialSteps.isFromDirty = true;
        this.initialSteps.isRefreshOnCancel = true;
        this.onStepperChange.emit(this.initialSteps);
      }
    });
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  /**Detect compare market change */

  changeState(event: EventData) {
    if (event.isFromDirty) {
      this.initialSteps.isRefreshOnCancel = true;
      this.initialSteps.isFromDirty = true;
      this.onStepperChange.emit(this.initialSteps);
    }
  }

  createReportForm() {
    this.reportForm = new FormGroup({
      moAnalysisInfo: new FormControl(""),
      mocoAnalysisInfo: new FormControl(""),
    });
  }

  saveAnalysisReport(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }

    const market = {
      analysisReport: this.reportForm.value,
      type: "analysisReport",
      prevData: this.prevData,
      nextData: this.reportForm.value,
      analysisId: this.analysisReport["analysis"]["id"],
      mode: this.analysisReport["aMode"],
      stepNumber: this.initialSteps.stepNumber,
      isStoreSilently: isStoreSilently,
    };
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      if (Object.keys(response).length) {
        const analysis = response.data.analysis;
        this.analysisReport["analysis"]["moAnalysisInfo"] =
          analysis.moAnalysisInfo;
        this.analysisReport["analysis"]["mocoAnalysisInfo"] =
          analysis.mocoAnalysisInfo;
        this.marketAnalysis.setItemToStorage("analysis", this.analysisReport);
      }

      if (!isStoreSilently) {
        this.marketAnalysis.removeItem("analysis");
        this.marketAnalysis.exit();
        return;
      }
    });
  }

  storeDataSilently() {
    this.saveAnalysisReport(false, true);
  }
}
