import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { EventData, analysis } from "../../models/analysis";
import { MarketAnalysisService } from "../../services/market-analysis.service";
import { MocoAnalysis } from "../../models/moco-analysis.model";
import { GraphData } from "../../../../shared/components/mo-analysis-graph/graph-data.model";
import { Router } from "@angular/router";

import * as _ from "lodash";

import {
  GraphConfig,
  graphConfig,
} from "../../../../shared/components/mo-analysis-graph/graph-config";
import { MarketAnalysisStore } from "../../services/market-analysis.store";

@Component({
  selector: "app-moco-analysis",
  templateUrl: "./moco-analysis.component.html",
  styleUrls: ["./moco-analysis.component.scss"],
})
export class MocoAnalysisComponent implements OnInit {
  @Output()
  onStepperChange: EventEmitter<EventData> = new EventEmitter<EventData>();
  initialSteps: EventData = {
    stepNumber: 9,
    locations: 0,
    stateChanged: false,
  };
  mocoAnalysisData;
  currentTab = "costs";
  mocoAnalysis: any[] = [];
  graphData: GraphData[] = [];
  isCostFilled: any[] = [];

  // chartConfig = {
  //   label: {
  //     xLabel: `Cost in 1 year`,
  //     yLabel: 'Market Opportunity'
  //   }
  // };
  chartConfig: GraphConfig = graphConfig;
  constructor(
    private marketAnalysis: MarketAnalysisService,
    private marketAnalysisStore: MarketAnalysisStore,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "moco-analysis"});

    this.chartConfig.label.xLabel = "Cost in 1 year";
    this.chartConfig.label.yLabel = "Market Opportunity";
    this.chartConfig.showPointLabel = true;
    this.chartConfig.height = 410;
    this.chartConfig.width = 560;

    this.onStepperChange.emit(this.initialSteps);

    this.mocoAnalysisData = this.marketAnalysis.getItemFromStorage("analysis");
    this.mocoAnalysis = this.mocoAnalysisData["mocoAnalysis"]["graph"];
    console.log(this.mocoAnalysisData);
    /**check if cost is filled */
    this.isCostFilled = _.filter(this.mocoAnalysis, function (moco) {
      return moco.total;
    });
    if (this.mocoAnalysis) {
      this.mocoAnalysis = this.mocoAnalysis.map(
        (analysis) => new MocoAnalysis(analysis)
      );
    }
    if (this.mocoAnalysis.length) {
      let locations = this.mocoAnalysisData.marketCharacteristics.location;
      const colorList = this.mocoAnalysisData.analysis.colorList;
      locations.forEach((location, key) => {
        const filteredLocation = _.filter(this.mocoAnalysis, function (e) {
          return e.name.toLowerCase() === location["name"].toLowerCase();
        });
        let analysis = location;
        if (filteredLocation.length) {
          analysis = filteredLocation[0];
        }

        let color;
        try {
          color = colorList[key];
        } catch (error) {
          color = analysis.color;
        }
        this.graphData.push({
          name: analysis.name,
          color: color,
          y: analysis?.opportunity,
          x: analysis?.total,
          pointRadius: analysis?.marketSize,
        });
      });
    }
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  storeFinalAnalysis(isRedirect = false, isStoreSilently = false) {
    const market = {
      mocoAnalysis: true,
      type: "mocoAnalysis",
      analysisId: this.mocoAnalysisData["analysis"]["id"],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1,
      isStoreSilently: isStoreSilently,
    };

    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      console.log(response);
      if (response.code === 200) {
        if (isRedirect) {
          this.marketAnalysis.removeItem("analysis");
          this.router.navigate(["/dashboard"]);
          return;
        }
        this.changeToNext(null, isStoreSilently);
      }
    });
  }

  changeToNext(data = null, isStoreSilently = false) {
    let _analysis;
    if (!isStoreSilently) {
      this.mocoAnalysisData["current"] = +this.mocoAnalysisData["current"] + 1;
      this.mocoAnalysisData["currentTab"] = "analysisReport";
    }

    _analysis = this.mocoAnalysisData;

    this.marketAnalysis.setCurrentStep("analysis", _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);
  }
}
