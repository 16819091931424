import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { SettingsService } from "./shared/service/settings.service";
import { environment } from "../environments/environment";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: `
    <app-loader></app-loader>
    <div
      class="verification-container"
      *ngIf="
        (settingsService.loadingSettings | async) !== true &&
        (settingsService.settings | async).isEmailVerified === false
      "
    >
      <p class="text-center verification-text">
        Your account is not yet verified, please check your email and verify the
        account,
        <a class="resend-btn" (click)="resendEmail()">Resend email</a>
      </p>
    </div>

    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit, AfterViewChecked {
  location: Location;

  constructor(
    private router: Router,
    public settingsService: SettingsService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  resendEmail() {
    this.settingsService.resendConfirmationMail();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
