// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-light-bg {
  float: left;
  padding-right: 1.8125rem !important;
}
.alert-light-bg .badge {
  width: 30px;
  height: 30px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.alert-light-bg .step-header {
  width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/app/moco/market-analysis/components/static-step-info/static-step-info.component.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,mCAAA;AADJ;AAGI;EAAQ,WAAA;EACJ,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAAR;AAGI;EACI,UAAA;AADR","sourcesContent":["@import \"../../../../../scss/variables\";\r\n\r\n.alert-light-bg{ \r\n    float:left;\r\n    padding-right: 1.8125rem !important;\r\n\r\n    .badge{ width: 30px;\r\n        height: 30px;\r\n        float: left;\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: center;\r\n        margin-right: 10px;\r\n    }\r\n\r\n    .step-header {\r\n        width: 40%;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
