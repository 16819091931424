import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DefaultLayoutComponent } from "../containers";
import { AuthGuard } from "../authentication/guard/auth.guard";
import { MocoDashboardComponent } from "./moco-dashboard/moco-dashboard.component";
import { CompanyGuard } from "../authentication/guard/company.guard";
import { MyAccountComponent } from "../shared/components/my-account/my-account.component";
import { PasswordResetComponent } from "../authentication/component/password-reset/password-reset.component";
import { ViewAnalysisComponent } from "./market-analysis/components/view-analysis/view-analysis.component";
import { UnsavedInfoGuard } from "../shared/guard/unsaved-info.guard";
import { LogoutComponent } from "../authentication/component/logout/logout.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "password-reset/:user",
    component: PasswordResetComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "",
    },
    children: [
      {
        path: "logout",
        component: LogoutComponent,
        data: {
          title: "Logout Page",
        },
      },
      {
        path: "get-analysis/:mode/:id",
        component: ViewAnalysisComponent,
        canActivate: [AuthGuard],
        data: {
          title: "",
          breadcrumb: "",
        },
      },
      {
        path: "get-analysis/:mode/:id/:org",
        component: ViewAnalysisComponent,
        canActivate: [AuthGuard],
        data: {
          title: "",
          breadcrumb: "",
        },
      },
      {
        path: "company",
        loadChildren: () =>
          import("./company/company.module").then((m) => m.CompanyModule),
      },
      {
        path: "coach",
        loadChildren: () =>
          import("./company/company.module").then((m) => m.CompanyModule),
      },
      {
        path: "analysis",
        loadChildren: () =>
          import("./market-analysis/market-analysis.module").then(
            (m) => m.MarketAnalysisModule
          ),
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./moco-admin/moco-admin.module").then(
            (m) => m.MocoAdminModule
          ),
      },
      {
        path: "dashboard",
        component: MocoDashboardComponent,
        data: {
          title: "",
          breadcrumb: "Dashboard",
        },
      },
      {
        path: "settings",
        component: MyAccountComponent,
        data: {
          title: "Settings",
          breadcrumb: "Settings",
        },
      },
      {
        path: "my-account",
        component: MyAccountComponent,
        data: {
          title: "Settings",
          breadcrumb: "Settings",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class MocoRoutingModule {}
