import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Response } from '../../../../shared/model/response';
import { Country } from '../../models/market-characteristics/country.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(private httpClient: HttpClient) {}

  getLocations(q = '') {
    const url = `${environment.apiUrl}api/locations?q=${q}`;
    return this.httpClient.get(url).pipe(
      map((response: Response) => {
        return response.data.map(location => new Country(location));
      })
    );
  }
}
