import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../shared/service/settings.service';
import { AuthorizationService } from '../../service/authorization.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(
    public auth: AuthorizationService,
    public settingService: SettingsService
  ) {}

  ngOnInit(): void {
    this.logout();
  }

  logout() {
    this.auth.logout();
    this.settingService.settingSubject.next({});
  }
}
