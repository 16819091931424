import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { LocationAdminStore } from "../../services/location-admin/location-admin.store";
import { LocationAdmin } from "../../models/location-admin.model";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmService } from "../../../../shared/service/confirm.service";
import { ConfirmComponent } from "../../../../shared/components/confirm/confirm.component";

@Component({
  selector: "app-location-admin",
  templateUrl: "./location-admin.component.html",
  styleUrls: ["./location-admin.component.scss"],
})
export class LocationAdminComponent implements OnInit {
  locationForm: FormGroup;
  public name: AbstractControl;
  public synonyms: AbstractControl;
  locations: LocationAdmin[] = [];
  synonymsErroMsg = "";
  error = "";
  itemsAsObjects = [
    { value: 0, display: "Angular" },
    { value: 1, display: "React" },
  ];
  constructor(
    private fb: FormBuilder,
    public locationAdminStore: LocationAdminStore,
    public dialog: MatDialog,
    public confirm: ConfirmService
  ) {}
  submitted = false;
  ngOnInit(): void {
    //locationForm

    this.locationAdminStore.getLocations("");
    this.locationAdminStore.locations.subscribe((res) => {
      this.locations = res;
    });
  }

  delete(data) {
    this.confirm.open(ConfirmComponent, data.id, "Location", data.name);
  }

  delTag(location, tag) {
    location["remove"] = tag;
    this.confirm.open(ConfirmComponent, location, "synonym", tag);
  }

  sNo(i) {
    let c = i + 1;
    return c;
  }

  addLoction(data = null, id = 0) {
    const dialogRef = this.dialog.open(DialogLocationAdd, {
      data: {
        data: data,
        id: id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }
}

@Component({
  selector: "dialog-location-add",
  templateUrl: "dialog-location-add.html",
  styleUrls: ["./dialog-location-add.scss"],
})
export class DialogLocationAdd {
  locationForm: FormGroup;
  public name: AbstractControl;
  public synonyms: AbstractControl;
  locations: LocationAdmin[] = [];
  synonymsErroMsg = "";
  error = "";
  page = "Add";
  itemsAsObjects = [
    { value: 0, display: "Angular" },
    { value: 1, display: "React" },
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public locationAdminStore: LocationAdminStore,
    public dialog: MatDialog
  ) {}
  submitted = false;
  ngOnInit(): void {
    //locationForm

    if (parseInt(this.data.id) > 0) {
      this.page = "Update";
      this.name = this.data?.data?.name;
      this.synonyms = this.data?.data?.synonyms;
    }
    this.createLocationForm();
    if (parseInt(this.data.id) > 0 && this.data.data != null) {
      this.locationForm.patchValue(this.data.data);
    }
  }

  createLocationForm() {
    this.locationForm = this.fb.group({
      name: [
        "",
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
      synonyms: [""],
      //itemsAsObjects:['']
    });
    this.name = this.locationForm.controls["name"];
    this.synonyms = this.locationForm.controls["synonyms"];
  }

  get f() {
    return this.locationForm.controls;
  }
  onSubmit(value) {
    this.submitted = true;
    if (this.locationForm.invalid) {
      console.log("submit", value["synonyms"]);
      if (value["synonyms"] === "") {
        this.synonymsErroMsg = "synonyms must enter in tag form";
      }
      return;
    }
    if (this.locationForm.valid) {
      if (parseInt(this.data.id) > 0) {
        value["id"] = this.data.id;
      }
      this.locationAdminStore.saveLocations(value).subscribe(response => {      
        this.dialog.closeAll();
        this.locationAdminStore.getLocations("");
      });
    }
  }

  closeForm() {
    this.dialog.closeAll();
  }
}
