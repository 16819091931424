import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "../../authentication/guard/auth.guard";
import { LocationAdminComponent } from "./components/location-admin/location-admin.component";
import { CategoryAdminComponent } from "./components/category-admin/category-admin.component";
import { MoAnalysisGraphComponent } from "../../shared/components/mo-analysis-graph/mo-analysis-graph.component";
import { AdminAnalysisComponent } from "./components/admin-analysis/admin-analysis.component";
import { SectorComponent } from "./components/sector/sector.component";
import { StepsTextComponent } from "./components/steps-text/steps-text.component";

const routes: Routes = [
  {
    path: "locations",
    component: LocationAdminComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Locations",
      breadcrumb: "Locations",
    },
  },
  {
    path: "categories",
    component: CategoryAdminComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Categories",
      breadcrumb: "Categories",
    },
  },
  {
    path: "sectors",
    component: SectorComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Sectors",
      breadcrumb: "Sectors",
    },
  },
  {
    path: "stepstext",
    component: StepsTextComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Steps",
      breadcrumb: "Steps",
    },
  },

  {
    path: "analyses",
    component: AdminAnalysisComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Analysis",
      breadcrumb: "Analysis",
    },
  },
  {
    path: "analyses/:id",
    component: AdminAnalysisComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Analysis History",
      breadcrumb: "History",
    },
  },
  {
    path: "analyses/:id/:org",
    component: AdminAnalysisComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Analysis History",
      breadcrumb: "History",
    },
  },
  {
    path: "graph",
    component: MoAnalysisGraphComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Graph admin",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MocoAdminRoutingModule {}
