export interface SectorAdminInterface {
  id: string;
  name: string;
  synonyms: any[];
}

export class SectorAdmin {
  id: string;
  name: string;
  synonyms: any[];
  tags:string;
  constructor(
    location: SectorAdmin = {
      id: '',
      name: '',
      synonyms: [],
      tags:''
    }
  ) {
    this.id = location.id;
    this.name = location.name;
    this.synonyms = location.synonyms;
    if(this.synonyms.length){
      this.tags= this.synonyms.join();
    }
  }
}
