export interface CountryInterface {
  id: string;
  name: string;
  synonyms: any[];
  color: string;
}

export class Country {
  id: string;
  name: string;
  synonyms: any[];
  color: string;
  absoluteNumber?: number;
  scoreFilled?: number;
  accuracy: number;

  constructor(
    location: CountryInterface = {
      id: '',
      name: '',
      synonyms: [],
      color: ''
    }
  ) {
    this.id = location.id;
    this.name = location.name;
    this.synonyms = location.synonyms;
    this.color = location.color;
  }
}
