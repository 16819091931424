import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  EventData,
  trafficLights,
  attractiveness,
} from "../../../models/analysis";
import { MarketAnalysisService } from "../../../services/market-analysis.service";
import { FormGroup, FormArray, FormControl } from "@angular/forms";
import * as _ from "lodash";
import { Attractiveness } from "../../../models/market-characteristics/attractiveness.model";
import { Response } from "../../../../../shared/model/response";
import { MarketAnalysisStore } from "../../../services/market-analysis.store";
import { SettingsService } from "../../../../../shared/service/settings.service";
import { NotifyService } from "../../../../../shared/service/notify.service";
import { Subscription } from "rxjs";
import { Country } from "../../../models/market-characteristics/country.model";
import { GraphService } from "../../../../../shared/components/real-time-graph/graph.service";
@Component({
  selector: "app-mc-attractiveness",
  templateUrl: "./mc-attractiveness.component.html",
  styleUrls: ["./mc-attractiveness.component.scss"],
})
export class McAttractivenessComponent implements OnInit {
  /**Define step number */
  @Output()
  onStepperChange: EventEmitter<EventData> = new EventEmitter<EventData>();
  marketAnalysisStoreSubscription: Subscription;

  currentTab = "marketCharacteristics";
  isFormValueChanged: boolean = false;
  prevData: any;
  attractivenessData;
  maxWeightFactor: number = 1;
  totalWeightFactor: number;
  leftWeighFactor: number;
  trafficLights = trafficLights;
  initialSteps: EventData = {
    stepNumber: 2,
    locations: 0,
    stateChanged: false,
    isSubmitDisabled: true,
  };
  addNewParameterProgress: boolean = false;
  locations: Country[] = [];
  attr = attractiveness;
  /**From group */
  attractivenessFrom: FormGroup;
  constructor(
    private marketAnalysis: MarketAnalysisService,
    public marketAnalysisStore: MarketAnalysisStore,
    private settingsService: SettingsService,
    private notify: NotifyService,
    private graphService: GraphService
  ) {}

  ngOnInit(): void {
    this.onStepperChange.emit(this.initialSteps);
    this.createAttractivenessForm();
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "attractiveness"});
    
    /**check local storage attractiveness data*/
    this.attractivenessData = this.marketAnalysis.getItemFromStorage(
      "analysis"
    );

    // store attractiveness for before process
    if (
      !this.attractivenessData["marketCharacteristics"]["attractiveness"].length
    ) {
      // console.log(this.attractiveness.value);
      // this.addDefaultParameter();
      // this.storeAttractiveness(false, true);
    }
    this.marketAnalysisStoreSubscription = this.marketAnalysisStore.marketCharacteristics.subscribe(
      (response) => {
        if (Object.keys(response).length) {
          // set to storage
          this.locations = response.location;
          this.attractivenessData["marketCharacteristics"]["attractiveness"] =
            response.attractiveness;
          this.marketAnalysisStore.setItemToStorage(
            "analysis",
            this.attractivenessData
          );
          this.patchValueToForm(response.attractiveness);

          if (!response.attractiveness.length) {
            this.storeAttractiveness(false, true);
          }

          this.marketAnalysisStore.patchCopyableInfo(this.attractivenessFrom);
          this.calculateWeightFactor();
          this.prevData = this.attractivenessFrom.value.attractiveness;
        }
      }
    );

    /**Let's calculate weight factor */
    this.calculateWeightFactor();
    this.attractivenessFrom.valueChanges.subscribe((res) => {
      this.isFormValueChanged = true;
      if (this.attractivenessFrom.dirty) {
        this.initialSteps.isFromDirty = true;
        this.initialSteps.isRefreshOnCancel = true;
        this.onStepperChange.emit(this.initialSteps);
      }
    });
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
    
    if (this.marketAnalysisStoreSubscription) {
      this.marketAnalysisStoreSubscription.unsubscribe();
    }
  }

  createAttractivenessForm() {
    this.attractivenessFrom = new FormGroup({
      attractiveness: new FormArray([]),
    });
    /**initialize parameter */
  }

  accuracy(index, countryIndex = 1) {
    //console.log('trafficLights', this.trafficLights);
    // return 1;
    return parseInt(this.attractiveness.at(index).get("accuracy").value);
  }

  get attractiveness() {
    return this.attractivenessFrom.get("attractiveness") as FormArray;
  }

  patchValueToForm(value) {
    this.attractiveness.clear();
    if (value.length) {
      value.forEach((attr: Attractiveness) => {
        if (!attr.parameterOfHomeMarket) {
          attr.weightFactor = "";
        }
        this.pushParameter();
      });
      this.attractivenessFrom.patchValue({
        attractiveness: value,
      });

      this.calculateWeightFactor();
    } else {
      /**add five parameter by default */
      this.addDefaultParameter();
    }
  }

  addDefaultParameter() {
    /**add five parameter by default */
    this.pushParameter();
    this.pushParameter();
    this.pushParameter();
    this.pushParameter();
    this.pushParameter();
  }

  pushParameter() {
    this.attractiveness.push(
      new FormGroup({
        id: new FormControl(),
        userId: new FormControl(),
        accuracy: new FormControl(3),
        parameterOfHomeMarket: new FormControl(),
        source: new FormControl(),
        weightFactor: new FormControl(),
      })
    );
  }
  actionAble: "parameter" | "saveData" = "saveData";

  addParameter() {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }

    this.pushParameter();
    console.log(this.attractiveness.value);
    this.addNewParameterProgress = true;
    this.actionAble = "parameter";

    this.storeAttractiveness(false, true);
  }
  // deleteParameter(index: number) {
  //   this.attractiveness.removeAt(index);
  //   this.calculateWeightFactor();
  // }

  deleteParameter(index: number) {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }

    const attrId = this.attractiveness.at(index).get("id").value;

    this.removeParameter(index);

    if (attrId) {
      this.settingsService.delete(attrId, "mcAttractiveness");
      this.settingsService.isDeleting.subscribe((response) => {
        console.log(response);
        if (!response) {
          // this.removeParameter(index);
        }
      });
    } else {
      // this.removeParameter(index);
    }
    // if (!this.attractiveness.length) {
    //   this.addDefaultParameter();
    // }
  }

  removeParameter(index) {
    this.attractiveness.removeAt(index);
    this.calculateWeightFactor();
    const attr = this.attractiveness.value;

    this.attractivenessData[this.currentTab]["attractiveness"] = attr;
    const _analysis = this.attractivenessData;

    this.marketAnalysis.setCurrentStep("analysis", _analysis);
  }

  /**calculate weight factor */

  calculateWeightFactor() {
    const attractiveness = this.attractivenessFrom.value.attractiveness;
    const self = this;
    this.totalWeightFactor = _.round(
      _.sumBy(attractiveness, function (attr: Attractiveness) {
        return self.marketAnalysisStore.getValue(attr.weightFactor);
      }),
      2
    );
    this.leftWeighFactor = this.maxWeightFactor - this.totalWeightFactor;
    if (
      this.totalWeightFactor <= this.maxWeightFactor &&
      this.totalWeightFactor === this.maxWeightFactor
    ) {
      this.initialSteps.isSubmitDisabled = false;
    } else {
      this.initialSteps.isSubmitDisabled = true;
    }
    this.onStepperChange.emit(this.initialSteps);
  }

  convertToAbs(value) {
    return Math.abs(value).toFixed(2);
  }

  /**Filter five record */

  filterFirstFive() {
    let filteredAttr = [];

    const attr = this.attractivenessFrom.value.attractiveness;
    if (attr.length) {
      attr.forEach((att: Attractiveness, key) => {
        /**lets first push first five elemnt  */
        if (key <= 4) {
          filteredAttr.push(att);
        } else if (att.parameterOfHomeMarket) {
          filteredAttr.push(att);
        }
      });
    }

    return filteredAttr;
  }

  /**Store Attractiveness */
  storeAttractiveness(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }

    let weightFactorInfo = `Oops! The total weigh isn’t exactly 1. You need to
                  ${
                    this.totalWeightFactor <= this.maxWeightFactor
                      ? "add"
                      : "deduct"
                  }
                  ${this.convertToAbs(this.leftWeighFactor)}`;
    if (!isStoreSilently && this.initialSteps.isSubmitDisabled) {
      this.notify.notifyError(weightFactorInfo, "Weigh factor"); //info
      return;
    }

    // if (!this.isFormValueChanged) {
    //   this.changeToNext();
    //   return false;
    // }
    // Set next step to compare-markets
    /** filter five record */
    const filteredAttr = this.filterFirstFive();

    const market = {
      // filter only valid record
      prevData: this.prevData,
      nextData: this.attractivenessFrom.value.attractiveness,
      attractiveness: this.attractiveness.value,
      type: "attractiveness",
      mode: this.attractivenessData["aMode"],
      analysisId: this.attractivenessData["analysis"]["id"],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1,
      isStoreSilently: isStoreSilently,
      action: this.actionAble,
    };

    this.marketAnalysis
      .storeAnalysis(market)
      .subscribe((response: Response) => {
        if (isRedirect) {
          this.marketAnalysis.exit();
          return;
        }

        this.attractivenessFrom.patchValue({
          attractiveness: this.marketAnalysis.changeDataForPatch(
            response.data.attractiveness
          ),
        });
        if (response["data"]["action"] === "parameter") {
          this.addNewParameterProgress = false;
        }
        this.changeToNext(response.data.attractiveness, isStoreSilently);
      });
  }

  changeDataForPatch(data) {
    if (data?.length) {
      let modified = data.map((res) => {
        return { id: res?.id };
      });
      console.log("ddddnewData", modified);
      return modified;
    }
    return data;
  }

  changeToNext(attractiveness = null, isStoreSilently = false) {
    let _analysis;

    if (this.attractivenessData) {
      if (attractiveness) {
        this.attractivenessData[this.currentTab][
          "attractiveness"
        ] = attractiveness;
      }

      if (!isStoreSilently) {
        this.attractivenessData["currentTab"] = "scoresAttractiveness";
        this.attractivenessData["current"] =
          +this.attractivenessData["current"] + 1;
      }

      _analysis = this.attractivenessData;
    }

    this.marketAnalysis.setCurrentStep("analysis", _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);

    if (isStoreSilently) {
    }
  }

  checkForFirstRow(attractiveness, index: number) {
    return this.marketAnalysis.checkForFirstRow(attractiveness, index);
  }

  storeDataSilently() {
    this.actionAble = "saveData";

    this.initialSteps.isFormChanged = true;
    this.onStepperChange.emit(this.initialSteps);
    this.storeAttractiveness(false, true);
  }

  emitformDirty() {
    this.initialSteps.isRefreshOnCancel = false;
    this.initialSteps.isFromDirty = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  renderGraph() {
    console.log(this.attractivenessFrom.value);
    this.attractivenessData["marketCharacteristics"]["attractiveness"].map(
      (attr) => {
        let filtered = _.filter(this.attractivenessFrom.value, { id: attr.id });
        if (filtered.length) {
          let _filtered = filtered[0];
          attr.weightFactor = _filtered.weightFactor;
        }
        return attr;
      }
    );
    let location = this.graphService.moAnalysisGraphData(
      this.attractivenessData
    );

    this.marketAnalysisStore.updateChart.next({
      isUpdate: true,
      data: location,
    });
    console.log(location);
  }
}
