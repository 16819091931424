export interface SettingsInterface {
  email: string;
  name: string;
  is_email_verified: boolean;
  role: string;
  pics: string;
  plan?: string;
  totalAnalyse: any;
  totalUser: any;
  showStats: any;
  navAnalysis: any;
  personName: string;
  mostUseCountry: string;
  mostRatedCountry: string;
  notifications: any[];
  isCostDisabled: boolean;
}

export class Settings {
  email: string;
  name: string;
  isEmailVerified: boolean;
  role: string;
  plan: string;
  pics: string;
  totalAnalyse: any;
  totalUser: any;
  showStats: any;
  navAnalysis: any;
  personName: string;
  mostUseCountry: string;
  mostRatedCountry: string;
  notifications: any[];
  isCostDisabled: boolean;

  constructor(
    settings: SettingsInterface = {
      email: "",
      name: "",
      is_email_verified: true,
      role: "",
      plan: "",
      pics: "",
      totalAnalyse: 0,
      totalUser: 0,
      showStats: "",
      navAnalysis: [],
      personName: "",
      mostUseCountry: "INDIA",
      mostRatedCountry: "USA",
      notifications: [],
      isCostDisabled: true,
    }
  ) {
    this.email = settings.email;
    this.name = settings.name;
    this.showStats = settings.showStats;
    this.totalAnalyse = settings.totalAnalyse;
    this.totalUser = settings.totalUser;
    this.isEmailVerified = settings.is_email_verified;
    this.role = settings.role;
    this.plan = settings.plan;
    this.pics = settings.pics;
    this.navAnalysis = settings.navAnalysis;
    this.personName = settings.personName;
    this.mostUseCountry =
      settings.mostUseCountry !== "" ? settings.mostUseCountry : "USA";
    this.mostRatedCountry =
      settings.mostRatedCountry !== "" ? settings.mostRatedCountry : "USA";
    this.notifications = settings.notifications;
    this.isCostDisabled = settings.isCostDisabled === true;
  }
}
