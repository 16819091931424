// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.goal {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 3px;
}

.mo-header {
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}

.height {
  padding-left: 15px;
  padding-right: 15px;
}

textarea.form-control {
  font-size: 14px;
  color: #5c6873;
  font-weight: 400;
  margin-bottom: 16px;
}

.same-height {
  width: 500px;
  min-height: 500px;
  margin: auto;
}
.same-height canvas {
  height: 280px !important;
}
@media only screen and (max-width: 991.98px) {
  .same-height {
    width: auto;
    height: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/moco/market-analysis/components/analysis-report/analysis-report.component.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,mBAAA;EACA,sBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,YAAA;EACA,iBAAA;EACA,YAAA;AADJ;AAGI;EACI,wBAAA;AADR;AAKI;EAVJ;IAWQ,WAAA;IACA,YAAA;EAFN;AACF","sourcesContent":["@import \"../../../../../scss/variables\";\r\n\r\n.goal {\r\n    font-size: 14px;\r\n    margin-top: 5px;\r\n    margin-left: 3px;\r\n}\r\n\r\n.mo-header {\r\n    padding-top: 14.5px;\r\n    padding-bottom: 14.5px;\r\n}\r\n\r\n.height{\r\n    padding-left: 15px;\r\n    padding-right: 15px;\r\n}\r\n\r\ntextarea.form-control{\r\n    font-size: 14px;\r\n    color: #5c6873;\r\n    font-weight: 400;\r\n    margin-bottom: 16px;\r\n}\r\n\r\n.same-height {\r\n    width: 500px;\r\n    min-height: 500px;\r\n    margin: auto;\r\n\r\n    canvas {\r\n        height: 280px !important\r\n    }\r\n\r\n\r\n    @media #{$mq-991-up} {\r\n        width: auto;\r\n        height: auto;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
