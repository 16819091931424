import { Injectable } from "@angular/core";
import { MarketFitAnalysisGraph } from "../mo-analysis-graph/graph-data.model";
import { Attractiveness } from "../../../moco/market-analysis/models/market-characteristics/attractiveness.model";
import * as _ from "lodash";
@Injectable({
  providedIn: "root",
})
export class GraphService {
  constructor() {}

  moAnalysisGraphData(moAnalysisData) {
    let marketFitAnalysis = [];
    const locations = moAnalysisData.marketCharacteristics.location;
    const colorList = moAnalysisData.analysis.colorList;

    const graph = moAnalysisData.moAnalysis.graph;
    for (let index = 0; index < locations.length; index++) {
      let attrValue = this.calculateAttractiveness(
        locations[index].name,
        moAnalysisData
      );
      let filtered_location = _.filter(graph, {
        name: locations[index].name,
      });
      if (filtered_location.length) {
        locations[index].color = filtered_location[0]["color"];
      }

      let color;
      try {
        color = colorList[index];
      } catch (error) {
        color = locations[index].color;
      }

      marketFitAnalysis.push({
        name: locations[index].name,
        color: color,
        attractiveness: parseInt(attrValue.attractiveness),
        marketSize: attrValue.marketSize,
        marketFit: attrValue.marketFit,
      });
    }

    return marketFitAnalysis.map(
      (analysis) => new MarketFitAnalysisGraph(analysis)
    );
  }

  private calculateAttractiveness(countryName, moAnalysisData) {
    let score = 0;
    let marketFit = 0;
    let marketSize = 0;

    moAnalysisData.marketCharacteristics.attractiveness.forEach(
      (rate: Attractiveness, key) => {
        let rateEveryMarket = rate.rateEveryMarket?.filter(
          (rem) => rem.name.toLowerCase() === countryName.toLowerCase()
        );
        let filteredRate;
        if (rateEveryMarket?.length) {
          filteredRate = rateEveryMarket[0];
        }

        if (key === 0) {
          marketSize = filteredRate?.absoluteNumber;
        }
        if (rate.parameterOfHomeMarket) {
          let scoreFilled = 0;

          if (filteredRate?.scoreFilled) {
            scoreFilled = parseInt(filteredRate?.scoreFilled);
          }

          score = score + scoreFilled * rate.weightFactor;
        }
      }
    );

    moAnalysisData.marketFit.attractiveness.forEach(
      (rate: Attractiveness, key) => {
        let rateEveryMarket = rate.rateEveryMarket.filter(
          (rem) => rem.name.toLowerCase() === countryName.toLowerCase()
        );
        let filteredRate;
        if (rateEveryMarket.length) {
          filteredRate = rateEveryMarket[0];
        }

        if (rate.parameterOfHomeMarket) {
          let scoreFilled = 0;
          if (filteredRate?.scoreFilled) {
            scoreFilled = parseInt(filteredRate?.scoreFilled);
          }
          marketFit = marketFit + scoreFilled * rate.weightFactor;
        }
      }
    );

    return {
      marketSize: marketSize,
      marketFit: marketFit,
      attractiveness: score.toFixed(2),
    };
  }
}
