import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizationService } from './service/authorization.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthGuard } from './guard/auth.guard';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { RouterModule } from '@angular/router';
import { PasswordResetEmailComponent } from './component/password-reset-email/password-reset-email.component';
import { PasswordResetComponent } from './component/password-reset/password-reset.component';
import { PublicFooterComponent } from './component/public-footer/public-footer.component';
import { CompanyGuard } from './guard/company.guard';
import { LogoutComponent } from './component/logout/logout.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  providers: [AuthorizationService, OAuthService, AuthGuard, CompanyGuard],
  declarations: [
    LoginComponent,
    RegisterComponent,
    PasswordResetEmailComponent,
    PasswordResetComponent,
    PublicFooterComponent,
    LogoutComponent
  ]
})
export class AuthenticationModule {}
