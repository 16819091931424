import {
  GraphData,
  MarketFitAnalysisGraph,
} from "../../../../shared/components/mo-analysis-graph/graph-data.model";
import * as moment from "moment";
import { Company } from "../../../company/model/company.model";

export interface AnalysisChartInterface {
  id: string;
  name: string;
  location: any[];
  moAnalysis: any[];
  user: any;
  date: any;
  allUsers: any;
  totalHistory: number;
  mainId: any;
  moAnalysisMax: any;
  moAnalysisMin: any;
  moAnalysisMaxCost: any;
  createdStrtotime: number;
  status: any;
  statusMo: boolean;
  statusFormated: any;
  created_at: any;
}

export class AnalysisChart {
  id: string;
  name: string;
  location: any[];
  moAnalysis: GraphData[];
  user: any;
  date: any;
  allUsers: any;
  totalHistory: number;
  mainId: any;
  moAnalysisMax: any;
  moAnalysisMin: any;
  moAnalysisMaxCost: any;
  createdStrtotime: number;
  status: any;
  statusMo: boolean;
  statusFormated: any;
  constructor(
    location: AnalysisChartInterface = {
      id: "",
      name: "",
      moAnalysis: [],
      location: [],
      user: {},
      date: "",
      allUsers: [],
      totalHistory: 0,
      mainId: "",
      moAnalysisMax: {},
      moAnalysisMin: {},
      moAnalysisMaxCost: {},
      createdStrtotime: 0,
      status: "",
      statusMo: true,
      statusFormated: "",
      created_at: "",
    }
  ) {
    this.statusMo = location.statusMo;
    this.statusFormated = location.statusFormated;
    this.id = location.id;
    this.name = location.name;
    this.status = location.status;
    this.moAnalysis = location.moAnalysis.map(
      (loc) => new MarketFitAnalysisGraph(loc)
    );
    this.location = location.location;
    this.user = location.user;
    this.date = location.date;
    this.date = moment(location.created_at).format("Do MMM YYYY HH:mm:ss");

    this.allUsers = location.allUsers;
    this.totalHistory = location.totalHistory;
    this.mainId = location.mainId;
    this.moAnalysisMax = location.moAnalysisMax;
    this.moAnalysisMin = location.moAnalysisMin;
    this.moAnalysisMaxCost = location.moAnalysisMaxCost;
    this.createdStrtotime = location.createdStrtotime;
  }
}
