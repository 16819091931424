import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-chart-footer",
  templateUrl: "./chart-footer.component.html",
  styleUrls: ["./chart-footer.component.scss"],
})
export class ChartFooterComponent implements OnInit {
  @Input() user: any;
  constructor() {}

  ngOnInit(): void {}

  getLogo(url) {
    if (url) {
      return `${environment.apiUrl}storage/${url}`;
    }

    return "";
  }
}
