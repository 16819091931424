import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from '@angular/forms';
//import { CategoryAdminStore } from '../../services/category-admin/category-admin.store';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmService } from '../../../../shared/service/confirm.service';
import { ConfirmComponent } from '../../../../shared/components/confirm/confirm.component';
import { ManageCategoryComponent } from './manage-category/manage-category.component';
import { CategoryAdmin } from '../../models/category-admin.model';
import { CategoryAdminStore } from '../../services/category-admin/category-admin.store';
import { NotifyService } from '../../../../shared/service/notify.service';

@Component({
  selector: 'app-category-admin',
  templateUrl: './category-admin.component.html',
  styleUrls: ['./category-admin.component.scss']
})
export class CategoryAdminComponent implements OnInit {
  categorys: CategoryAdmin[] = [];
  synonymsErroMsg = '';
  error = '';
  openPopup = false;
  constructor(
    private fb: FormBuilder,
    public categoryAdminStore: CategoryAdminStore,
    public dialog: MatDialog,
    public confirm: ConfirmService,
    private notify: NotifyService
  ) {}
  submitted = false;
  ngOnInit(): void {
    //categoryForm

    this.categoryAdminStore.getCategorys('');
    this.categoryAdminStore.categorys.subscribe(res => {
      console.log('loc', res);
      this.categorys = res;
    });
  }

  delete(data) {
    this.confirm.open(ConfirmComponent, data.id, 'category', data.name);
  }

  delTag(category, tag) {
    category['remove'] = tag;

    this.confirm.open(ConfirmComponent, category, 'sub-category', tag.name);
  }

  sNo(i) {
    let c = i + 1;
    return c;
  }

  isAdmin() {
    return true;
  }

  manageCats(data = null, id = 0) {
    const dialogRef = this.dialog.open(ManageCategoryComponent, {
      data: {
        data: data,
        categorys: this.categorys,
        id: id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

   addItem(cats,status){
    this.categorys = this.categorys.map((product: any) => {
      if(product.id==cats.id){
        product.editable=status;
        product.subcatEdit={}; 
        product.newSubcat='';
        product.subcatEditId='';
      }
      return product;
    });
  }

  editTag(cats,tag,status){
    this.categorys = this.categorys.map((product: any) => {
      if(product.id==cats.id){
        product.editable=status;
        product.subcatEdit=tag; 
        product.newSubcat=tag?.name;
        product.subcatEditId=tag?.id;
      }else if(status) {
        product.editcat = !status;
      }
      return product;
    });
  }

 editCatName(cats,status){
    this.categorys = this.categorys.map((product: any) => {
      if(product.id==cats.id){
        product.editcat =status;
        //product.subcatEdit=cats; 
        product.newSubcat=cats?.name;
        product.subcatEditId=cats?.id;
        console.log('productEditcat',product);
      }else if(status){
        product.editcat = !status;
      }
      return product;
    });
  }
  
  editCatStatus(event, cats) {
    this.categorys = this.categorys.map((product: any) => {
      if(product.id==cats.id){
          product.newSubcat=event.target.value;
      }
       
      return product;
    });
  }

  UpdateSubcats(cats,type="update-inline"){
    console.log('update', cats,type)
    if(cats?.newSubcat!==null && cats?.newSubcat!=='' ){
      cats['type']= type;
      this.categoryAdminStore.saveCategorys(cats);
    }else{
      this.notify.notifyError('Subcategorie name required');
    }
    
  }
}
