import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { StepsAdminStore } from "../../services/steps-admin/steps-admin.store";
import { MatDialog } from "@angular/material/dialog";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NotifyService } from "../../../../shared/service/notify.service";

@Component({
  selector: "app-steps-text",
  templateUrl: "./steps-text.component.html",
  styleUrls: ["./steps-text.component.css"],
})
export class StepsTextComponent implements OnInit {
  panelOpenState = false;
  openStateA2 = false;
  openStateA3 = false;
  openStateA4 = false;
  openStateA5 = false;
  openStateA6 = false;
  openStateA7 = false;
  openStateA8 = false;
  openStateA9 = false;
  openStateA10 = false;
  openStateA11 = false;
  openStateA12 = false;

  jsonDoc = "dd d";

  stepsForm: FormGroup;
  public location: AbstractControl;
  public rateEveryMarket: AbstractControl;
  public compareMarkets: AbstractControl;
  public mfCompareMarket: AbstractControl;
  public mfAttractiveness: AbstractControl;
  public attractiveness: AbstractControl;
  public mfRateEveryMarket: AbstractControl;
  public moAnalysis: AbstractControl;
  public yourGoal: AbstractControl;
  public estimatedCosts: AbstractControl;
  public mocoAnalysis: AbstractControl;

  stepss = [];
  parentsErroMsg = "";
  error = "";
  page = "Add";
  videoLinkType: any[] = ["youtube", "vimeo"];

  public data: any;
  constructor(
    private fb: FormBuilder,
    public stepsAdminStore: StepsAdminStore,
    public dialog: MatDialog,
    private notify: NotifyService
  ) {}
  submitted = false;
  allstepsTexts: any = [];
  ngOnInit(): void {
    this.createStepsForm();
    this.stepsAdminStore.getSteps();
    this.stepsAdminStore.stepss.subscribe((res) => {
      if (res?.length) {
        this.allstepsTexts = res;

        let newData: [] = [];
        res.map((data) => {
          newData[data?.name] = data?.title;
          newData[`${data?.name}VideoType`] = data?.videoType;
          newData[`${data?.name}VideoLink`] = data?.videoLink;
          newData[`${data?.name}VideoTitle`] = data?.videoTitle;
          newData[`${data?.name}VideoShortTitle`] = data?.videoTitleShort;
        });
        console.log(newData);
        this.stepsForm.patchValue(newData);
      }
    });
  }
  getValue(key, type: string = "title") {
    let rV: any = "";
    if (this.allstepsTexts) {
      const banner = this.allstepsTexts;
      if (banner && banner?.length) {
        banner.forEach((element) => {
          if (element.name === key) {
            rV = element.title;
            //rV = type == 'title'? element.title : element;
          }
        });
      }
    }
    return rV;
  }

  createStepsForm() {
    this.stepsForm = this.fb.group({
      location: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      locationVideoLink: [""],
      locationVideoTitle: [""],
      locationVideoShortTitle: [""],
      locationVideoType: [""],

      attractiveness: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      attractivenessVideoLink: [""],
      attractivenessVideoTitle: [""],
      attractivenessVideoShortTitle: [""],
      attractivenessVideoType: [""],

      compareMarkets: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      compareMarketsVideoLink: [""],
      compareMarketsVideoTitle: [""],
      compareMarketsVideoShortTitle: [""],
      compareMarketsVideoType: [""],

      mfAttractiveness: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      mfAttractivenessVideoLink: [""],
      mfAttractivenessVideoTitle: [""],
      mfAttractivenessVideoShortTitle: [""],
      mfAttractivenessVideoType: [""],

      mfCompareMarket: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      mfCompareMarketVideoLink: [""],
      mfCompareMarketVideoTitle: [""],
      mfCompareMarketVideoShortTitle: [""],
      mfCompareMarketVideoType: [""],

      moAnalysis: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      moAnalysisVideoLink: [""],
      moAnalysisVideoTitle: [""],
      moAnalysisVideoShortTitle: [""],
      moAnalysisVideoType: [""],

      yourGoal: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      yourGoalVideoLink: [""],
      yourGoalVideoTitle: [""],
      yourGoalVideoShortTitle: [""],
      yourGoalVideoType: [""],

      estimatedCosts: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      estimatedCostsVideoLink: [""],
      estimatedCostsVideoTitle: [""],
      estimatedCostsVideoShortTitle: [""],
      estimatedCostsVideoType: [""],

      mocoAnalysis: [
        "",
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      mocoAnalysisVideoLink: [""],
      mocoAnalysisVideoTitle: [""],
      mocoAnalysisVideoShortTitle: [""],
      mocoAnalysisVideoType: [""],

      analysisReport: ["Analysis report"],
      analysisReportVideoLink: [""],
      analysisReportVideoTitle: [""],
      analysisReportVideoShortTitle: [""],
      analysisReportVideoType: [""],
    });
    // this.location = this.stepsForm.controls['location'];

    // this.attractiveness = this.stepsForm.controls['attractiveness'];
  }

  get f() {
    return this.stepsForm.controls;
  }
  onSubmit(value) {
    this.submitted = true;
    if (this.stepsForm.invalid) {
      this.notify.notifyError("All steps are required", "Steps error");
      return;
    }
    //if (this.stepsForm.valid) {

    console.log(" admin", value);
    this.stepsAdminStore.saveStepss(value);
    this.stepsAdminStore.stepss.subscribe((res) => {
      if (res.length) {
        this.dialog.closeAll();
      }
    });
    //}
  }

  closeForm() {
    this.dialog.closeAll();
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      [], //bold
      ["insertImage", "insertVideo"],
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
}
