import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckDataLossModalComponent } from '../check-data-loss-modal/check-data-loss-modal.component';

@Component({
  selector: 'app-edit-confirmation-modal',
  templateUrl: './edit-confirmation-modal.component.html',
  styleUrls: ['./edit-confirmation-modal.component.scss']
})
export class EditConfirmationModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CheckDataLossModalComponent>
  ) {}

  ngOnInit(): void {}

  edit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
