import { Injectable } from '@angular/core';
import { Service } from '../../../../shared/service/service';

import { BehaviorSubject, Observable } from 'rxjs';
import { LocationAdmin } from '../../models/location-admin.model';
import { LocationAdminService } from './location-admin.service';
import { NotifyService } from '../../../../shared/service/notify.service';

@Injectable({
  providedIn: 'root'
})
export class LocationAdminStore extends Service {
  locations: BehaviorSubject<LocationAdmin[]> = new BehaviorSubject([]);
  public loadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject(true);

  public readonly loadingLocation: Observable<
    Boolean
  > = this.loadingSubject.asObservable();

  constructor(private locationService: LocationAdminService ,
    private notify: NotifyService) {
    super();
  }

  getLocations(value) {
    this.loadingSubject.next(true);
    return this.locationService.getLocations(value).subscribe(response => {
      this.locations.next(response);
      this.loadingSubject.next(false);
    });
  }

  saveLocations(value) {
    return this.locationService.saveLocations('', value);
  }

  delLocations(value) {
    return this.locationService.saveLocations('delete', value).subscribe(response => {      
      this.locations.next(response);
    });
  }
}
