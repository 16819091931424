import { Component } from '@angular/core';
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { AuthorizationService } from '../../service/authorization.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  public username: AbstractControl;
  public password: AbstractControl;
  error: string = '';
  isVerify: boolean = false;

  constructor(
    public authorizationService: AuthorizationService,
    private fb: FormBuilder
  ) {
    this.createLoginForm();
  }
  createLoginForm() {
    this.loginForm = this.fb.group({
      username: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)])
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)])
      ]
    });
    this.username = this.loginForm.controls['username'];
    this.password = this.loginForm.controls['description'];
  }

  onSubmit(value) {
    this.isVerify = false;
    this.authorizationService
      .login(value.username, value.password)
      .then(response => {
        if (response.hasOwnProperty('error')) {
          console.log(response);
          if (response.error.data === 'verify') {
            this.isVerify = true;
          }
          this.error = response.error.message;
        }
      }).catch(err => {
        console.log(err);
      });
  }
}
