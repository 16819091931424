export class GraphData {
  name: string;
  y: number;
  x: number;
  pointRadius: number;
  color: string;
  avg?: number;
}

export class MarketFitAnalysisGraphInterface {
  name: string;
  marketFit: number;
  attractiveness: number;
  marketSize: number;
  color: string;
  opportunity?: number;
  total?: number;
}

export class MarketFitAnalysisGraph {
  name: string;
  y: number;
  x: number;
  pointRadius: number;
  color: string;

  constructor(
    analysis: MarketFitAnalysisGraphInterface = {
      name: "",
      marketFit: 0,
      attractiveness: 0,
      marketSize: 0,
      color: "",
    }
  ) {
    this.name = analysis.name;
    this.y = analysis.attractiveness ? analysis.attractiveness : 0;
    this.x = analysis.marketFit ? analysis.marketFit : 0;
    this.pointRadius = analysis.marketSize ? analysis.marketSize : 0;
    this.color = analysis.color;
  }
}

export class MocoAnalysisGraph {
  name: string;
  y: number;
  x: number;
  pointRadius: number;
  color: string;

  constructor(
    analysis: MarketFitAnalysisGraphInterface = {
      name: "",
      marketFit: 0,
      attractiveness: 0,
      marketSize: 0,
      color: "",
      opportunity: 0,
      total: 0,
    }
  ) {
    this.name = analysis.name;
    this.y = analysis.opportunity ? analysis.opportunity : 0;
    this.x = analysis.total ? analysis.total : 0;
    this.pointRadius = analysis.marketSize ? analysis.marketSize : 0;
    this.color = analysis.color;
  }
}
