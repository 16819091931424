import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MocoDashboardComponent } from './moco-dashboard/moco-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { MocoRoutingModule } from './moco.routing';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MarketAnalysisModule } from './market-analysis/market-analysis.module';
import { RouterBreadcrumbComponent } from '../shared/components/router-breadcrumb/router-breadcrumb.component';
import { MocoAdminModule } from './moco-admin/moco-admin.module';
import { CopyableInfoDialogComponent } from './moco-dashboard/copyable-info-dialog/copyable-info-dialog.component';
import { RenameAnalysisComponent } from './moco-dashboard/rename-analysis/rename-analysis.component';

@NgModule({
  declarations: [
    MocoDashboardComponent,
    RouterBreadcrumbComponent,
    CopyableInfoDialogComponent,
    RenameAnalysisComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    SharedModule,
    MocoRoutingModule,
    MarketAnalysisModule,
    MocoAdminModule
  ],
  exports: [RouterBreadcrumbComponent, SharedModule],
  //entryComponents: [CopyableInfoDialogComponent, RenameAnalysisComponent]
})
export class MocoModule {}
