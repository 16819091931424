import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { MocoModule } from './moco/moco.module';
import { BearerTokenInterceptor } from './authentication/interceptors/bearer-token.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSliderModule,
    MatSidenavModule,
    MatToolbarModule,
    ToastrModule.forRoot(),
    AuthenticationModule,
    MocoModule,
    AppRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
