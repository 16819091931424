import { Component, OnInit, Input } from "@angular/core";
import { GraphConfig, graphConfig } from "../mo-analysis-graph/graph-config";
import { MarketAnalysisService } from "../../../moco/market-analysis/services/market-analysis.service";
import { GraphData } from "../mo-analysis-graph/graph-data.model";
import { MarketAnalysisStore } from "../../../moco/market-analysis/services/market-analysis.store";
import { Subscription } from "rxjs";
import { GraphService } from "./graph.service";
import { MocoAnalysis } from "../../../moco/market-analysis/models/moco-analysis.model";
import $ from "jquery";
import * as _ from "lodash";
@Component({
  selector: "app-real-time-graph",
  templateUrl: "./real-time-graph.component.html",
  styleUrls: ["./real-time-graph.component.scss"],
})
export class RealTimeGraphComponent implements OnInit {
  // scatter
  @Input("graphType") public graphType = "mo-analysis";

  updateChartSubscription: Subscription;
  updateChartDataSubscription: Subscription;
  graphDisplayer: boolean = false;

  chartConfig: GraphConfig = graphConfig;
  graphData: GraphData[] = [];
  marketFitAnalysis: any[] = [];
  moAnalysisData;
  display = "none";

  constructor(
    private marketAnalysis: MarketAnalysisService,
    private marketAnalysisStore: MarketAnalysisStore,
    private graphService: GraphService
  ) {}

  ngOnInit() {
    // console.log(this.graphType);
    this.chartConfig.showPointLabel = true;
    this.chartConfig.height = 240;
    this.chartConfig.width = 320;

    if (this.graphType === "mo-analysis") {
      this.getMOAnalysisGraph();
    } else if (this.graphType === "moco-analysis") {
      this.getMOCOAnalysisGraph();
    }
    this.moAnalysisData = this.marketAnalysis.getItemFromStorage("analysis");

    this.updateChartSubscription = this.marketAnalysisStore.updateChart.subscribe(
      (response) => {
        // console.log('Update chart', response);

        if (
          this.moAnalysisData["moAnalysis"]["graph"].length &&
          this.moAnalysisData["mocoAnalysis"]["graph"].length
        ) {
          if (Object.keys(response).length && response.isUpdate) {
            this.show();
            // console.log('AutoUpdate');
            if (this.graphType === "mo-analysis") {
              this.moAnalysisData = this.marketAnalysis.getItemFromStorage(
                "analysis"
              );
              if (response.data.length) {
                this.graphData = response.data;

                this.updateMOAnalysisGraph(true);
              } else {
                this.makeGraphData(this.moAnalysisData, true);
              }
            } else if (
              this.graphType === "moco-analysis" &&
              response.data.length
            ) {
              this.makeMOCOAnalysisGraph(response.data, this.moAnalysisData);
            }
          }
        }
      }
    );
  }

  getMOCOAnalysisGraph() {
    this.chartConfig.label.xLabel = "Cost in 1 year";
    this.chartConfig.label.yLabel = "Market Opportunity";

    let mocoAnalysisData = this.marketAnalysis.getItemFromStorage("analysis");
    let mocoAnalysis = mocoAnalysisData["mocoAnalysis"]["graph"];
    this.makeMOCOAnalysisGraph(mocoAnalysis, mocoAnalysisData);
  }

  makeMOCOAnalysisGraph(mocoAnalysis, mocoAnalysisData) {
    this.graphData = [];
    if (mocoAnalysis.length) {
      mocoAnalysis = mocoAnalysis.map((analysis) => new MocoAnalysis(analysis));
    }
    const colorList = mocoAnalysisData.analysis.colorList;

    if (mocoAnalysis.length) {
      let locations = mocoAnalysisData.marketCharacteristics.location;
      this.graphDisplayer = true;
      locations.forEach((location, index) => {
        const filteredLocation = _.filter(mocoAnalysis, function (e) {
          return e.name.toLowerCase() === location["name"].toLowerCase();
        });
        let analysis = location;
        if (filteredLocation.length) {
          analysis = filteredLocation[0];
        }

        let color;
        try {
          color = colorList[index];
        } catch (error) {
          color = analysis.color;
        }

        this.graphData.push({
          name: analysis.name,
          color: color,
          y: analysis.opportunity,
          x: analysis.total,
          pointRadius: analysis.marketSize,
        });
      });

      // console.log(this.graphData);
      const data = {
        graph: this.graphData,
        isRealTime: true,
        type: this.graphType,
      };
      this.marketAnalysisStore.updateChartDataSet.next(data);
    }
  }

  getMOAnalysisGraph() {
    this.moAnalysisData = this.marketAnalysis.getItemFromStorage("analysis");
    if (this.moAnalysisData["moAnalysis"]["graph"].length) {
      this.graphDisplayer = true;

      this.chartConfig.label.yLabel = "Market attractiveness";
      this.chartConfig.label.xLabel = "Market fit";

      this.makeGraphData(this.moAnalysisData);
    }
  }

  makeGraphData(moAnalysisData, updateSilently = false) {
    this.marketFitAnalysis = [];

    this.graphData = this.graphService.moAnalysisGraphData(moAnalysisData);

    this.updateMOAnalysisGraph(false);
  }

  updateMOAnalysisGraph(updateSilently = false) {
    if (updateSilently) {
      const data = {
        graph: this.graphData,
        isRealTime: true,
        type: this.graphType,
      };
      this.marketAnalysisStore.updateChartDataSet.next(data);
    }
  }

  ngOnDestroy() {
    if (this.updateChartSubscription) {
      this.updateChartSubscription.unsubscribe();
    }
  }

  close() {
    this.hide();
  }

  show() {
    this.moAnalysisData = this.marketAnalysis.getItemFromStorage("analysis");

    if (this.moAnalysisData["current"] <= 6) {
      this.makeGraphData(this.moAnalysisData, true);
    } else if (this.moAnalysisData["current"] > 6) {
      this.makeMOCOAnalysisGraph(
        this.moAnalysisData["mocoAnalysis"]["graph"],
        this.moAnalysisData
      );
    }

    const isRealTimeGraphOpen = this.marketAnalysis.getItemFromStorage(
      "realtime-graph"
    );
    // console.log(isRealTimeGraphOpen);

    if (isRealTimeGraphOpen === null || isRealTimeGraphOpen) {
      this.display = "block";
    }

    $(".graph-container").fadeIn("slow");
  }

  hide() {
    this.marketAnalysis.setItemToStorage("realtime-graph", false);
    this.display = "none";
    $(".graph-container").fadeOut("slow");
  }

  graphOpener() {
    this.marketAnalysis.setItemToStorage("realtime-graph", true);

    this.show();
  }
}
