import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { Service } from '../../shared/service/service';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private oauthService: OAuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.oauthService.setStorage(new Service().getAuthStorage());
    const _isValid = this.oauthService.hasValidAccessToken();

    if (!_isValid) {
      this.router.navigate(['/login']);
    }
    return true;
  }
}
