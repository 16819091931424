import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';

import {MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CategoryAdminStore } from '../../../services/category-admin/category-admin.store';
import { CategoryAdmin } from '../../../models/category-admin.model';

@Component({
  selector: 'app-manage-category',
  templateUrl: './manage-category.component.html',
  styleUrls: ['./manage-category.component.scss']
})
export class ManageCategoryComponent implements OnInit {



    categoryForm: FormGroup;
    public name: AbstractControl; 
    
    categorys:CategoryAdmin[]=[];
    parentsErroMsg='';
    error='';
    page='Add';
    parents = [{id: 0, name: 'Angular',itemName:'Angular'}, {id: 1, name: 'React', itemName:'React'}];
    parent: AbstractControl;
    settings = {
      singleSelection: true,
      text: 'Select Parent',
      classes: 'select--list'
    };
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,
                 private fb: FormBuilder,
                 public categoryAdminStore:CategoryAdminStore,
                 public dialog: MatDialog) { }
    submitted = false;
    ngOnInit(): void {
      //categoryForm
      

     this.categorys=  this.data?.categorys;
     

     if(parseInt(this.data.id)>0){
       this.page= 'Update';
       this.name=this.data?.data?.name;
       this.parents=this.data?.data?.parents;

       this.categorys=this.data?.data?.parentSelect;
       
      
     } 

     if(this.categorys?.length){
      this.categorys= this.categorys.map(res=>{
        res['itemName'] =res['name'];
        return res;
       });
     }

     this.createCategoryForm();
     if(parseInt(this.data.id)>0 && this.data.data!=null){
      this.categoryForm.patchValue(this.data.data);
     }
    }
  
    createCategoryForm() {
      this.categoryForm = this.fb.group({
        name: [ '', Validators.compose([Validators.required, Validators.minLength(2)]) ],
        parents: [ '' ],
      });
      this.name = this.categoryForm.controls['name']; 
     // this.parents = this.categoryForm.controls['parents'];
    }
  
    get f() { return this.categoryForm.controls; }
    onSubmit(value){
      this.submitted = true;
      if (this.categoryForm.invalid) {
        console.log('submit',value['parents']);
       if(value['parents']===""){
        this.parentsErroMsg=" must enter in tag form";
        console.log('submit',value);
       }
        console.log(this.categoryForm);
        return;
       }
      if (this.categoryForm.valid) {
        if(parseInt(this.data.id)>0){
          value['id']=this.data.id;
        }
        console.log(' admin',value);
        this.categoryAdminStore.saveCategorys(value);
        this.categoryAdminStore.categorys.subscribe(res=>{
          if(res.length){
            this.dialog.closeAll()
          }
        });
      }
      
    }
  
    closeForm(){
      this.dialog.closeAll();
    }
    
  
  }