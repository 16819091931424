import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})
export class NotifyService extends Service {
  constructor(private toastr: ToastrService) {
    super();
  }

  notifySuccess(message, title = '') {
    this.toastr.success(message, title);
  }

  notifyError(message, title = '') {
    this.toastr.error(message, title, {
      timeOut: 10000,
    });
  }

  info(message, title = 'Info') {
    this.toastr.info(message, title);
  }
}
