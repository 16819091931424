import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LocationAdminComponent,
  DialogLocationAdd
} from './components/location-admin/location-admin.component';
import { SharedModule } from '../../shared/shared.module';
import { MocoAdminRoutingModule } from './moco-admin.routing';
import { TagInputModule } from 'ngx-chips';
import { CategoryAdminComponent } from './components/category-admin/category-admin.component';
import { ManageCategoryComponent } from './components/category-admin/manage-category/manage-category.component';
import { AdminAnalysisComponent } from './components/admin-analysis/admin-analysis.component';
import { SectorComponent, DialogSectorManage } from './components/sector/sector.component';
import { StepsTextComponent } from './components/steps-text/steps-text.component';

@NgModule({
  declarations: [
    LocationAdminComponent,
    DialogLocationAdd,
    DialogSectorManage,
    CategoryAdminComponent,
    ManageCategoryComponent,
    AdminAnalysisComponent,
    SectorComponent,
    StepsTextComponent,
  ],

  imports: [
    CommonModule, 
    SharedModule, 
    MocoAdminRoutingModule, 
    TagInputModule
  ],
 // entryComponents: [DialogLocationAdd, ManageCategoryComponent,DialogSectorManage]
})
export class MocoAdminModule {}
