import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { SectorAdminStore } from '../../services/sector-admin/sector-admin.store';

import {MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmService } from '../../../../shared/service/confirm.service';
import { ConfirmComponent } from '../../../../shared/components/confirm/confirm.component';
import { SectorAdmin } from '../../models/sector-admin.model';

@Component({
  selector: 'app-sector',
  templateUrl: './sector.component.html',
  styleUrls: ['./sector.component.scss']
})
export class SectorComponent implements OnInit {


    sectorForm: FormGroup;
    public name: AbstractControl; 
    public synonyms: AbstractControl;
    sectors:SectorAdmin[]=[];
    synonymsErroMsg='';
    error='';
    
    constructor( private fb: FormBuilder,
                 public sectorAdminStore:SectorAdminStore,
                 public dialog: MatDialog,
                 public confirm: ConfirmService) { }
    submitted = false;
    ngOnInit(): void {
      //sectorForm
      
      this.sectorAdminStore.getSectors('');
      this.sectorAdminStore.sectors.subscribe(res=>{
        console.log('loc',res);
        this.sectors=res;
      });
    }
  
  
  
    delete(data){
      this.confirm.open(ConfirmComponent, data.id, 'Sector', data.name);
    }
  
    delTag(sector,tag){
      sector['remove']=tag;
      this.confirm.open(ConfirmComponent, sector, 'synonym', tag);
    }
  
    sNo(i){
      let c=i+1;
      return c;
    }
  
    addLoction(data=null,id=0){
      const dialogRef = this.dialog.open(DialogSectorManage, {
        data: {
          data: data,
          id:id
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
  
      
    }
  
  
    
  
  }



  @Component({
    selector: 'dialog-sector-add',
    templateUrl: 'dialog-sector-add.html',
    styleUrls: ['./dialog-sector-add.scss']
  })
  export class DialogSectorManage {
  
  
    sectorForm: FormGroup;
    public name: AbstractControl; 
    public synonyms: AbstractControl;
    sectors:SectorAdmin[]=[];
    synonymsErroMsg='';
    error='';
    page='Add';
    
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,
                 private fb: FormBuilder,
                 public sectorAdminStore:SectorAdminStore,
                 public dialog: MatDialog) { }
    submitted = false;
    ngOnInit(): void {
      //sectorForm
      
     if(parseInt(this.data.id)>0){
       this.page= 'Update';
       this.name=this.data?.data?.name;
       this.synonyms=this.data?.data?.synonyms;
       
      
     } 
     this.createSectorForm();
     if(parseInt(this.data.id)>0 && this.data.data!=null){
      this.sectorForm.patchValue(this.data.data);
     }
    }
  
    createSectorForm() {
      this.sectorForm = this.fb.group({
        name: [ '', Validators.compose([Validators.required, Validators.minLength(4)]) ],       
       
      });
      this.name = this.sectorForm.controls['name']; 
    
    }
  
    get f() { return this.sectorForm.controls; }
    onSubmit(value){
      this.submitted = true;
      if (this.sectorForm.invalid) {
      
      
        console.log(this.sectorForm);
        return;
       }
      if (this.sectorForm.valid) {
        if(parseInt(this.data.id)>0){
          value['id']=this.data.id;
        }
        console.log('sector admin',value);
        this.sectorAdminStore.saveSectors(value);
        this.sectorAdminStore.sectors.subscribe(res=>{
          if(res.length){
            this.dialog.closeAll()
          }
        });
      }
      
    }
  
    closeForm(){
      this.dialog.closeAll();
    }
    
  
  }
  
