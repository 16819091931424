import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatExpansionModule } from '@angular/material/expansion';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ConfirmComponent } from './components/confirm/confirm.component';
import { TimmerComponent } from './components/timmer/timmer.component';
import { SettingsComponent } from './components/settings/settings.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { MoAnalysisGraphComponent } from './components/mo-analysis-graph/mo-analysis-graph.component';
import { RealTimeGraphComponent } from './components/real-time-graph/real-time-graph.component';
import { ChartFooterComponent } from './components/chart-footer/chart-footer.component';
import { BubbleChartComponent } from './components/bubble-chart/bubble-chart.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { InputIncrementDecrementDirective } from './directive/input-increment-decrement.directive';
import { AutofocusDirectiveDirective } from './directive/autofocus-directive.directive';
import { SameHeightDirective } from './directive/same-height.directive';
import { UnsavedInfoGuard } from './guard/unsaved-info.guard';

@NgModule({
  declarations: [
    ConfirmComponent,
    TimmerComponent,
    SettingsComponent,
    MyAccountComponent,
    MoAnalysisGraphComponent,
    RealTimeGraphComponent,
    InputIncrementDecrementDirective,
    AutofocusDirectiveDirective,
    SameHeightDirective,
    ChartFooterComponent,
    BubbleChartComponent,
    VideoPlayerComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    MatAutocompleteModule,
    MatDialogModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    MatExpansionModule,
    MatPaginatorModule,
    AngularEditorModule,
    DragDropModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TimmerComponent,
    AngularMultiSelectModule,
    MatAutocompleteModule,
    TooltipModule,
    MatDialogModule,
    PopoverModule,
    MoAnalysisGraphComponent,
    RealTimeGraphComponent,
    MatPaginatorModule,
    MatExpansionModule,
    AngularEditorModule,
    DragDropModule,
    InputIncrementDecrementDirective,
    AutofocusDirectiveDirective,
    SameHeightDirective,
    ChartFooterComponent,
    BubbleChartComponent,
    VideoPlayerComponent,
  ],
  providers: [UnsavedInfoGuard],
})
export class SharedModule {}
