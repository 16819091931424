import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  AbstractControl,
  FormBuilder,
  FormControl
} from '@angular/forms';
import { AuthorizationService } from '../../service/authorization.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  public name: AbstractControl;
  public password_confirmation: AbstractControl;
  public email: AbstractControl;
  public password: AbstractControl;

  resetPasswordForm: FormGroup;
  mismatch: boolean = false;

  patternMedium: any =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

  public errors: any;
  public errorMessage: string = '';
  private user: string = '';
  constructor(
    private fb: FormBuilder,
    public authorizationService: AuthorizationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createresetPasswordForm();
  }

  ngOnInit(): void {
    this.user = this.route.snapshot.paramMap.get('user');

    this.resetPasswordForm.patchValue({
      user: this.user
    });
  }

  createresetPasswordForm() {
    this.resetPasswordForm = new FormGroup({
      user: new FormControl(''),
      password: new FormControl(''),
      password_confirmation: new FormControl()
    });
  }

  resetPassword(value) {
    this.authorizationService.resetPassword(value);
  }

  checkPasswordsPattern(group: FormGroup) {}

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.password_confirmation.value;
    this.mismatch = pass === confirmPass ? false : true;
  }
}
