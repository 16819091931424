import { Component, OnInit, Input } from '@angular/core';
import { MarketAnalysisStore } from '../../services/market-analysis.store';
import { CurrentStepInfo } from '../../models/analysis';

@Component({
  selector: 'app-static-step-info',
  templateUrl: './static-step-info.component.html',
  styleUrls: ['./static-step-info.component.scss']
})
export class StaticStepInfoComponent implements OnInit {
  
  @Input() info: CurrentStepInfo;
  panelOpenState = false;

  constructor(private marketAnalysisStore: MarketAnalysisStore) {}

  ngOnInit(): void {
    console.log(this.info);
    this.marketAnalysisStore.stepperChange.subscribe(response => {
      console.log(response);
    });
  }
}
