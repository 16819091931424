import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { AuthorizationService } from '../../service/authorization.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {
  // public name: AbstractControl;
  // public password_confirmation: AbstractControl;
  // public email: AbstractControl;
  // public password: AbstractControl;

  registrationForm: FormGroup;
  mismatch: boolean = false;
  token: string = '';
  invitedMail: string = '';
  patternMedium: any =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

  public errors: any;
  public errorMessage: string = '';

  constructor(
    public authorizationService: AuthorizationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createRegistrationForm();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(query => {
      console.log(query.token);
      this.token = query.token;
      this.invitedMail = query.e;
      this.registrationForm.patchValue({
        email: this.invitedMail,
        token: this.token
      });
    });
  }

  createRegistrationForm() {
    this.registrationForm = new FormGroup({
      token: new FormControl(''),
      companyName: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      password_confirmation: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ])
    });
  }

  register(value) {
    console.log(this.registrationForm.valid);
    console.log(value);
    this.errorMessage = '';
    this.authorizationService.register(value).subscribe(
      (response: any) => {
        console.log(response);
        if (response.status === 'success') {
          this.router.navigate(['/login']);
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 422) {
          this.errors = err.error.errors;
        } else {
          this.errorMessage = 'Something went wrong please try again.';
        }
      }
    );
  }

  checkPasswordsPattern(group: FormGroup) {}

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.password_confirmation.value;
    this.mismatch = pass === confirmPass ? false : true;
  }
}
