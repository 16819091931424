import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EventData, trafficLights } from '../../../models/analysis';
import { MarketAnalysisService } from '../../../services/market-analysis.service';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Attractiveness } from '../../../models/market-characteristics/attractiveness.model';
import { MarketAnalysisStore } from '../../../services/market-analysis.store';
import * as _ from 'lodash';

@Component({
  selector: 'app-mf-compare-market',
  templateUrl: './mf-compare-market.component.html',
  styleUrls: ['./mf-compare-market.component.scss']
})
export class MfCompareMarketComponent implements OnInit {
  @Output() onStepperChange: EventEmitter<EventData> = new EventEmitter<
    EventData
  >();
  @Input() stepNo: number = 6;

  constructor(
    private marketAnalysis: MarketAnalysisService,
    private marketAnalysisStore: MarketAnalysisStore
  ) {}

  initialSteps: EventData = {
    stepNumber: 6,
    locations: 0,
    stateChanged: false
  };

  currentTab = 'marketFit';
  isFormValueChanged: boolean = false;

  compareMarketForm: FormGroup;
  compareMarketData;
  trafficLights = trafficLights;
  prevData: any;

  ngOnInit(): void {
    this.createComapreMarketForm();
    this.initialSteps.stepNumber = this.stepNo;
    this.onStepperChange.emit(this.initialSteps);
    this.compareMarketData = this.marketAnalysis.getItemFromStorage('analysis');
    const attractiveness = this.compareMarketData[this.currentTab][
      'attractiveness'
    ];
    let compareMarkets = [];
    if (attractiveness) {
      compareMarkets = _.filter(attractiveness, function(o) {
        return o.compareMarket;
      });
    }

    this.marketAnalysisStore.marketCharacteristics.subscribe(response => {
      if (Object.keys(response).length) {
        this.patchCompareMarketsForm(response.marketFit);
        this.marketAnalysisStore.patchCopyableInfo(
          this.compareMarketForm,
          'mf-compare-market'
        );

        this.prevData = this.compareMarketForm.value.compareMarkets;
      }
    });

    this.compareMarketForm.valueChanges.subscribe(res => {
      this.isFormValueChanged = true;
      if (this.compareMarketForm.dirty) {
        this.initialSteps.isFromDirty = true;
        this.initialSteps.isRefreshOnCancel = true;

        this.onStepperChange.emit(this.initialSteps);
      }
    });
  }

  createComapreMarketForm() {
    this.compareMarketForm = new FormGroup({
      compareMarkets: new FormArray([])
    });
  }
  get compareMarkets() {
    return this.compareMarketForm.get('compareMarkets') as FormArray;
  }

  getName(index: number) {
    return this.compareMarkets.at(index).get('parameterOfHomeMarket').value;
  }

  patchCompareMarketsForm(attractiveness: Attractiveness[]) {
    let filteredAttr = [];
    this.compareMarkets.clear();

    attractiveness.forEach((attr: Attractiveness) => {
      if (attr.parameterOfHomeMarket) {
        if (
          attr?.compareMarket?.cmAccuracy === null ||
          attr.compareMarket.cmAccuracy === undefined
        ) {
          attr.compareMarket.cmAccuracy = 3;
        }
        filteredAttr.push(attr);
        this.compareMarkets.push(
          new FormGroup({
            id: new FormControl(attr.compareMarket.id),
            parameterOfHomeMarket: new FormControl(''),
            cmAccuracy: new FormControl(attr.compareMarket.cmAccuracy),
            score0: new FormControl(attr.compareMarket.score0),
            score25: new FormControl(attr.compareMarket.score25),
            score50: new FormControl(attr.compareMarket.score50),
            score75: new FormControl(attr.compareMarket.score75),
            score100: new FormControl(attr.compareMarket.score100)
          })
        );
      }
    });
    this.compareMarketForm.patchValue({
      compareMarkets: filteredAttr
    });
  }
  accuracy(index: number) {
    return parseInt(this.compareMarkets.at(index).get('cmAccuracy').value);
  }

  storeCompareMarket(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }
    // if (!this.isFormValueChanged) {
    //   this.changeToNext();
    //   return false;
    // }

    const market = {
      compareMarkets: this.compareMarkets.value,
      prevData: this.prevData,
      nextData: this.compareMarketForm.value.compareMarkets,
      type: 'mfCompareMarkets',
      mode: this.compareMarketData['aMode'],
      analysisId: this.compareMarketData['analysis']['id'],
      isStoreSilently: isStoreSilently
    };
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      if (isRedirect) {
        this.marketAnalysis.exit();
        return;
      }

      this.changeToNext(response.data.marketFit, isStoreSilently);
    });
  }

  changeToNext(attractiveness = null, isStoreSilently = false) {
    let _analysis;

    if (this.compareMarketData) {
      if (attractiveness) {
        this.compareMarketData[this.currentTab][
          'attractiveness'
        ] = attractiveness;
      }

      if (!isStoreSilently) {
        this.compareMarketData['currentTab'] = 'moAnalysis';
      }

      _analysis = this.compareMarketData;
    }

    this.marketAnalysis.setCurrentStep('analysis', _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  storeDataSilently() {
    this.initialSteps.isFormChanged = true;
    this.onStepperChange.emit(this.initialSteps);
    this.storeCompareMarket(false, true);
  }
}
