import { Component, Input, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  PRIMARY_OUTLET,
  Router,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { SettingsService } from "../../service/settings.service";
import { MarketAnalysisStore } from "../../../moco/market-analysis/services/market-analysis.store";
import { MarketAnalysisService } from "../../../moco/market-analysis/services/market-analysis.service";

interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
  customUrl: boolean;
}

@Component({
  selector: "app-router-breadcrumb",
  templateUrl: "./router-breadcrumb.component.html",
  styleUrls: ["./router-breadcrumb.component.scss"],
})
export class RouterBreadcrumbComponent implements OnInit {
  @Input() homeUrl = "";
  @Input() breadcrumbTitle: string = "";
  public breadcrumbs: IBreadcrumb[];
  title: string = "";
  analysis: any = null;
  titleSet = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private settings: SettingsService,
    public marketAnalysisStore: MarketAnalysisStore,
    public marketAnalysisService: MarketAnalysisService
  ) {
    this.breadcrumbs = [];
  }

  ngOnInit() {
    // Subscribe to the NavigationEnd event
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((): void => {
        // Set breadcrumbs
        const root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
      });
    this.breadcrumbs = this.getBreadcrumbs(this.router.routerState.root);

    this.marketAnalysisStore.analysisButton.subscribe((response) => {
      const analysis = this.marketAnalysisStore.getItemFromStorage("analysis");
      if (
        (this.router.url.indexOf("/get-analysis") !== -1 ||
          this.router.url.indexOf("/analysis") !== -1) &&
        analysis &&
        analysis["analysis"]
      ) {
        this.title = analysis["analysis"]["name"];
        this.analysis = analysis["analysis"];
        this.titleSet = true;
      }
    });

    // is analysis renamed
    this.marketAnalysisService.analysisRenamed.subscribe((response) => {
      if (response.status) {
        const analysis =
          this.marketAnalysisStore.getItemFromStorage("analysis");
        if (analysis) {
          this.title = response.name;
          analysis["analysis"]["name"] = response.name;

          this.marketAnalysisStore.setItemToStorage("analysis", analysis);
        }
      }
    });
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url = "",
    breadcrumbs: IBreadcrumb[] = []
  ): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = "breadcrumb";
    const ROUTE_DATA_TITLE = "title";
    const ROUTE_DATA_CUSTOM_URL = "url";
    // Get the child routes
    const children: ActivatedRoute[] = route.children;

    // Return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // Iterate over each children
    for (const child of children) {
      // Verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // Verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      let routeURL: string;
      const customUrl = child.snapshot.data.hasOwnProperty(
        ROUTE_DATA_CUSTOM_URL
      );
      // Get the route's URL segment
      if (customUrl === true) {
        routeURL = child.snapshot.data[ROUTE_DATA_CUSTOM_URL];
      } else {
        routeURL = child.snapshot.url.map((segment) => segment.path).join("/");
      }

      // Append route URL to URL
      if (routeURL !== "") {
        if (customUrl) {
          url = routeURL;
        } else {
          url += `/${routeURL}`;
        }
      }
      /**change dashboard url */
      if (url === "/dashboard") {
        this.setDashboardTitle();
      } else {
        // if(!this.titleSet && url!=='/analysis'){
        //   this.title = child.snapshot.data[ROUTE_DATA_TITLE];
        // }

        let elseUrl = this.activatedRoute?.firstChild?.snapshot?.url[0];
        let elseUrl1 = this.activatedRoute?.firstChild?.snapshot;
        let elseUrl2 = this.activatedRoute?.firstChild?.snapshot?.url[2];
        if (elseUrl?.path === "analysis") {
          //console.log("Does  contain Apples");
          const analysis =
            this.marketAnalysisStore.getItemFromStorage("analysis");
          //console.log('analysisFFF',analysis);
          if (
            this.router.url === "/analysis" &&
            analysis &&
            analysis["analysis"]
          ) {
            this.title = analysis["analysis"]["name"];
            this.titleSet = true;
            //console.log('analysisTtl',analysis['analysis'],this.title);
          }
        } else {
          this.title = child.snapshot.data[ROUTE_DATA_TITLE];

          if (this.title === "Analysis History") {
            let subs = this.marketAnalysisStore.analysisChartsPage.subscribe(
              (res) => {
                let data = res["data"];

                if (
                  res["data"] &&
                  data?.main !== "" &&
                  data?.main !== undefined
                ) {
                  this.title = "History: " + data?.main?.name;
                  if (subs) subs.unsubscribe();
                }
              }
            );
            // subs.unsubscribe();
          }
        }

        // console.log('in else route',ROUTE_DATA_TITLE,  this.title ,url,this.titleSet);
      }
      // Add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url,
        customUrl,
      };
      breadcrumbs.push(breadcrumb);

      // Recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    // We should never get here, but just in case
    return breadcrumbs;
  }

  setDashboardTitle() {
    this.settings.actveUser.subscribe((response: any) => {
      if (Object.keys(response).length) {
        if (response.personName && response.name) {
          this.title = `Welcome, ${response.personName}  (<i>${response.name}</i>)`;
        } else if (response.personName) {
          this.title = `Welcome, ${response.personName}`;
        } else {
          this.title = `Welcome, ${response.name}`;
        }
      }
    });
  }

  renameAnalysis() {
    // console.log('Analysis_rename', analysis);
    const analysis = this.marketAnalysisStore.getItemFromStorage("analysis");
    this.marketAnalysisStore.renameAnalysis(analysis["analysis"]);
  }
}
