import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';

import { Service } from '../../shared/service/service';
@Injectable()
export class CompanyGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let service = new Service();
    if (service.isAdmin()) {
      this.router.navigate(['/dashboard']);
    }
    return true;
  }
}
