import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Role } from "../../../moco/company/model/company.model";
import { Sector } from "../../../moco/company/model/sector.model";
import { CompanyService } from "../../../moco/company/services/company.service";
import { ActivatedRoute } from "@angular/router";
import { SettingsService } from "../../service/settings.service";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public errorMessage: string = "";
  theuser = "";
  public company = {
    label: "Edit company",
  };
  errors = {
    name: "",
    email: "",
    password: "",
  };

  email: "";
  role: any = "";

  apiurl = environment.apiUrl + "storage/";
  imageUrlFinal: any = "";
  userForm: FormGroup;
  roles: Role[] = [];
  sectors: Sector[] = [];
  data: any;
  patternMedium: any =
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  mismatch: boolean = false;
  comapanySubscription: Subscription;
  imageUrl: any = "";
  editFile: boolean = true;
  removeUpload: boolean = false;
  /*########################## File Upload ########################*/
  //@ViewChild('fileInput') el: ElementRef;
  isUploadingImage: boolean = false;

  constructor(
    public companyService: CompanyService,
    public route: ActivatedRoute,
    public settingsService: SettingsService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {
    this.createRegistrationForm();
  }

  ngOnInit(): void {
    /**check role */
    const userId = "";
    /* if (this.data?.id === 'new') {
      this.theuser = 'new';
    } else if (this.data?.id) {
      this.theuser = this.data?.id;
    } else {
      this.theuser = this.route.snapshot.paramMap.get('id');
    }

    if (this.theuser === 'new') {
      this.company = {
        label: 'Add new company'
      };
    }*/

    this.company = {
      label: "Add new company",
    };

    this.theuser = "my";

    this.companyService.getCompanies(this.theuser);
    this.companyService.companies.subscribe((response: any) => {
      if (Object.keys(response).length) {
        if (response.list.length && this.theuser !== "new") {
          const user = response.list[0];

          this.email = user.email;
          this.role = user.role === "company" ? "organization" : user.role;
          this.imageUrl = user.pics;
          this.imageUrlFinal = this.apiurl + this.imageUrl;
          let allrole = user["roles"];

          if (allrole !== undefined && allrole.length) {
            user["role"] = allrole.map((res) => {
              res["id"] = res["_id"];
              res["itemName"] = res["name"];
              return res;
            });
          }

          console.log("listUse", user);
          this.userForm.patchValue(user);
        }

        /**Sector  */
        this.roles = response.roles;
        console.log("rrro", this.roles);
        this.sectors = response.sectors;
        this.settings.text = "Select role";
      }
    });

    // this.companyService.roles.subscribe((response: Role[]) => {
    //   if (Object.keys(response).length) {
    //     this.roles = response;

    //     this.settings.text = 'Select role';
    //     console.log(this.roles);
    //   }
    // });
  }

  selectedItems = [{ id: "5eb0251de065000060002ec7", itemName: "company" }];
  settings = {
    singleSelection: true,
    text: "Select role",
    classes: "select--list",
  };

  sectorSettings = {
    singleSelection: true,
    text: "Select sector",
    classes: "select--list",
  };

  createRegistrationForm() {
    this.userForm = new FormGroup({
      id: new FormControl(),
      name: new FormControl("", [Validators.required, Validators.minLength(4)]),
      personName: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl(""),
      role: new FormControl(""),
      password: new FormControl(
        "" //, [ Validators.required,Validators.minLength(4)]
      ),
      password_old: new FormControl(
        "" //, [ Validators.required,Validators.minLength(4)]
      ),
      password_confirmation: new FormControl(
        "" //, [Validators.required,Validators.minLength(4)]
      ),
      file: new FormControl(),
    });
  }
  submitted = false;

  createOrUpdateCompanies(value) {
    this.submitted = true;
    console.log(value);
    if (this.userForm.valid) {
      this.submitted = false;
      value["userUpdate"] = "settingUpdate";
      this.companyService.createOrUpdateCompanies(value);
    } else {
      //console.log(this.userForm);
      return;
    }
  }

  get f() {
    return this.userForm.controls;
  }
  closeForm() {
    this.dialog.closeAll();
  }

  checkPasswordsPattern(group: FormGroup) {}

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.password_confirmation.value;
    this.mismatch = pass === confirmPass ? false : true;
  }

  uploadFile(event) {
    console.log(event.target.files);
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.imageUrlFinal = reader.result; // this.apiurl +this.imageUrl;
        this.userForm.patchValue({
          file: reader.result,
        });
        this.editFile = false;
        this.removeUpload = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  // Function to remove uploaded file
  removeUploadedFile() {
    // let newFileList = Array.from(this.el.nativeElement.files);
    // this.imageUrl = '';
    // this.editFile = true;
    // this.removeUpload = false;
    // this.userForm.patchValue({
    //   file: [null]
    // });
  }

  fileToUpload: any;
  //imageUrl: any;
  handleFileInput(file: FileList) {
    this.isUploadingImage = true;
    let self = this;

    this.fileToUpload = file.item(0);
    console.log("file", this.fileToUpload);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = this.imageUrlFinal = event.target.result;
      let value = [];
      const formData = new FormData();
      formData.append("file", this.fileToUpload);
      formData.append("type", "pics-profile");

      value["pics"] = this.imageUrl;
      value["type"] = "pics-profile";
      value["file"] = this.fileToUpload;
      self.isUploadingImage = true;

      this.companyService.updatePics(formData);
      this.comapanySubscription = this.companyService.pics.subscribe(
        (data) => {
          if (Object.keys(data).length) {
            self.isUploadingImage = false;
            console.log("res", data);
            this.settingsService.getSettings();
            if (data["path"] != "") {
              this.imageUrlFinal = this.apiurl + data["path"];
              this.userForm.patchValue({
                file: data["path"],
              });
            }
            this.companyService.picsSubject.next([]);
          }
          if (this.comapanySubscription)
            this.comapanySubscription.unsubscribe();
        },
        (err) => {
          self.isUploadingImage = false;
        }
      );
    };
    reader.readAsDataURL(this.fileToUpload);
  }
}
