import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, pipe } from "rxjs";
import { tap } from "rxjs/operators";
import { LoaderService } from "../components/loader/loader.service";
import { NotifyService } from "../service/notify.service";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: "root",
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private notify: NotifyService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.body && req.body.isStoreSilently) {
      this.loaderService.silentlyStoreLoader.next(true);
    } else {
      this.showLoader();
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        (error: any) => {
          console.log(error);
          this.onEnd();
          const filterMsg = this.filterMessage(error);
          // Sentry.captureException(error);
          Sentry.withScope((scope) => {
            scope.setExtras(error);
            Sentry.captureException(error);
          });
          if (error instanceof HttpErrorResponse && error.status === 422) {
            this.notify.notifyError(filterMsg, error.statusText);
          }
        }
      )
    );
  }

  private onEnd(): void {
    this.hideLoader();
    this.loaderService.silentlyStoreLoader.next(false);
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

  private filterMessage(error) {
    try {
      const err = error.error;
      if (Object.keys(err.errors).length) {
        const keys = Object.keys(err.errors);
        const message = err.errors[keys[0]].join(",");
        return message;
      }
    } catch (err) {
      if (error.statusText === "Unknown Error") {
        return "Server is not responding properly, please check your server status";
      }
      return error.error?.message;
    }
  }
}
