import { Injectable } from '@angular/core';
import { Service } from '../../../../shared/service/service';

import { BehaviorSubject, Observable } from 'rxjs';
import { CategoryAdmin } from '../../models/category-admin.model';
import { CategoryAdminService } from './category-admin.service';
import { NotifyService } from '../../../../shared/service/notify.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryAdminStore extends Service {
  categorys: BehaviorSubject<CategoryAdmin[]> = new BehaviorSubject([]);

  public loadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject(true);

  public readonly loadingCategory: Observable<
    Boolean
  > = this.loadingSubject.asObservable();

  constructor(private categoryService: CategoryAdminService ,
    private notify: NotifyService) {
    super();
  }

  getCategorys(value) {
    this.loadingSubject.next(true);
    return this.categoryService.getCategorys(value).subscribe(response => {
      this.categorys.next(response);
      this.loadingSubject.next(false);
    });
  }

  saveCategorys(value) {
    return this.categoryService.saveCategorys('', value).subscribe(response => {      
      this.categorys.next(response);
    });
  }

  delCategorys(value) {
    return this.categoryService.saveCategorys('delete', value).subscribe(response => {      
      this.categorys.next(response);
    });
  }
}
