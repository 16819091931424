import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationStart,
  NavigationEnd,
} from "@angular/router";
import { MarketAnalysisStore } from "../../services/market-analysis.store";
import { analysis } from "../../models/analysis";
import { MarketAnalysisService } from "../../services/market-analysis.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-view-analysis",
  templateUrl: "./view-analysis.component.html",
  styleUrls: ["./view-analysis.component.scss"],
})
export class ViewAnalysisComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  queryParams = null;

  constructor(
    private route: ActivatedRoute,
    private marketAnalysis: MarketAnalysisService,
    private marketAnalysisStore: MarketAnalysisStore,
    private router: Router
  ) {}

  ngOnInit(): void {
    let aId = this.route.snapshot.paramMap.get("id");
    let mode = this.route.snapshot.paramMap.get("mode");
    let org = this.route.snapshot.paramMap.get("org");

    // this.route.queryParams.subscribe((params) => {
    //   console.log(params.org);
    // });

    this.route.queryParamMap.subscribe((response: any) => {
      const { params } = response;
      console.log(response, params, org);
      if (params.org) {
        if (params.from_notification) {
          this.marketAnalysis.markAsRead(params.from_notification);
        }
      } else {
        this.queryParams = this.marketAnalysis.objToQs(response.params);
      }
    });
    console.log(this.queryParams);
    this.marketAnalysisStore.removeItem("analysis");
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        console.log("NavigationEnd");
        this.marketAnalysisStore.removeItem("analysis");
        aId = this.route.snapshot.paramMap.get("id");
        mode = this.route.snapshot.paramMap.get("mode");
        org = this.route.snapshot.paramMap.get("org");
        if (aId) {
          this.getAnalysis(aId, mode, "inner", org);
        }
      });

    this.getAnalysis(aId, mode, "outer", org);
  }

  getAnalysis(aId, mode, type, org = null) {
    console.log("AnalysisCAll_", aId, mode, type);
    let qs = "";
    if (this.queryParams) {
      qs = `&${this.queryParams}`;
    }
    let orgQS = '';
    if(org) {
      orgQS = '&org='+org;
    }
    this.marketAnalysisStore.getAnalysis(`q=view&_a=${aId}&mode=${mode}${qs}${orgQS}`);
    this.marketAnalysisStore.marketCharacteristics.subscribe((response) => {
      if (Object.keys(response).length) {
        if (!response.analysis) {
          this.router.navigate(["/dashboard"]);
          return;
        } else if (qs) {
          window.location.href = "/dashboard";
        }

        let _analysis = this.marketAnalysisStore.storeAllAnalysis(
          response,
          mode
        );
        this.marketAnalysis.setItemToStorage("analysis", _analysis);
        this.marketAnalysis.setItemToStorage(
          "analysisSteps",
          response["steps"]
        );
        this.marketAnalysisStore.analysisButton.next({
          isEnableEditButton: false,
        });
        // window.location.href = '/analysis';
        let org = "";
        this.route.queryParams.subscribe((params) => {
          org = params.org;
        });

        let _org = this.route.snapshot.paramMap.get("org");
        if (_org) {
          org = _org;
        }

        this.router.navigate(["/analysis"], {
          relativeTo: this.route,
          queryParams: { org: org },
        });
        // this.router.navigate(["/analysis"]);
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
