const _stepsInfos = [
  {
    step: 1,
    name: "location",
    label: "Markets",
    tab: "markets",
    isCollapsed: false,
    title:
      "What are the _country_ counttries / metropolitian regions /markets you currently feel might be attractive for you business? The order is not important.",
  },
  {
    step: 2,
    label: "Prameters Attractiveness",
    name: "attractiveness",
    tab: "prametersAttractiveness",
    isCollapsed: false,
    title:
      'What characteristics define the market attractiveness for your business? Number 1 is always the market size meausured by amount of potential customers (choose 1 customer segment). Look at the Market Opportunity in the DutchBasecamp Internationalisation Canvas and choose the parameters of market size, competition, regulation and pricing here. Please note: Culture + The Need are part of the 2nd sheet "Market fit". If you know some factors are more important than others, you can take that into account with the weigh factor, make sure it always adds up to 1.',
  },
  {
    step: 3,
    name: "rate-every-market",
    label: "Scores Attractiveness",
    tab: "scoresAttractiveness",
    isCollapsed: false,
    title:
      "In this step you rate every market by using the scale you designed in step 3. Fill in the exact number for your market size and fill out 0, 25, 50, 75, 100 for all the characteristics per market. The idea is that you rate every market in the same way, to bring a rationale behind your gut feeling. So let's start rating your top 5 markets according to your own scores. If you do not know a certain score, this is a good indicator of things you need to do more desk research for. Of course all your findings need to be validated, you could make this the goal of attending a tech conference, or joining a startup mission to the tech ecosystem of your choice.",
  },
  {
    step: 4,
    name: "mf-attractiveness",
    label: "Parameters Fit",
    tab: "parametersFit",
    isCollapsed: false,
    title:
      'Let\'s look at what makes your customers choose your product/service, look into your current value proposition and product/market fit. In the DutchBasecamp Internationalisation Canvas we are looking at the characteristics you came up with at "The need" and "Culture". Choose the 5 most defining ones, which also differ from one market to another. If certain parameters are more important you can give those a higher weigh factor, make sure it still adds up to 1.',
  },
  {
    step: 5,
    name: "mf-rate-every-market",
    tab: "mfScoresAttractiveness",
    label: "Scores Fit",
    isCollapsed: false,
    title:
      "Like in step 3, here you build a scale along which we will rate every market again. You can define this scale in any way you want, what is important is that you rate every market along the same lines.",
  },
  {
    step: 6,
    name: "mo-analysis",
    tab: "moAnalysis",
    label: "Mo Analysis",
    isCollapsed: false,
    title:
      "Here you find your first Market Opportunity analysis. The markets in the top right look like they offer the biggest opportunity for your product / service. We suggest you start working on Go-to-Market Strategies for these markets. You can use the DutchBasecamp Internationalisation Canvas for this, to make sure you consider everything involved. No markets on the top right square? Check your scale in step 3 and step 6, is your scale maybe to wide? Check step 4 and step 7, were there many scores you do not know (yet) and scored with 50? If everything is filled out correctly you might want to change the least interesting market with another one and see if this one scores higher.",
  },
  {
    step: 7,
    name: "your-goal",
    tab: "costs",
    label: "Goal",
    isCollapsed: false,
    title: "What is your goal for year 1 in the new market?",
  },
  {
    step: 8,
    name: "estimated-costs",
    tab: "costs",
    label: "Costs",
    isCollapsed: false,
    title:
      "Now list the estimated costs involved per market. If you want to add a 2nd strategy for a market here you can by selecting a less interesting market and changing it into a 2nd version.",
  },
  {
    step: 9,
    name: "moco-analysis",
    tab: "mocoAnalysis",
    label: "Moco Analysis",
    isCollapsed: false,
    title:
      "Now you can see the Market Opportunity set against the costs involved. Use this input to build your international roadmap. In case you used alternative strategies for the same market you need to manually copy the Market Size and Market Opportunity count.",
  },
  {
    step: 10,
    name: "analysis-report",
    tab: "analysisReport",
    label: "Report",
    isCollapsed: false,
    title:
      "Now you can see the Market Opportunity set against the costs involved. Use this input to build your international roadmap. In case you used alternative strategies for the same market you need to manually copy the Market Size and Market Opportunity count.",
  },
];

export const stepsInfo = _stepsInfos;

export let analysis = {
  aMode: "new",
  stepsInfo: [],
  parentAnalysis: {},
  currentTab: "markets", // for initial
  current: 0,
  analysis: null,
  marketCharacteristics: {
    location: [],
    attractiveness: [],
    compareMarkets: [],
  },
  marketFit: {
    attractiveness: [],
  },
  moAnalysis: {
    graph: [],
  },
  costs: {
    goal: {},
    estimated: [],
  },
  mocoAnalysis: {
    graph: [],
  },
};
export class Step {
  id: number;
  name: string;
}

export const steps = ["location", "attractiveness", "compare-markets"];

export class EventData {
  locations: number = 0;
  stepNumber: number = 0;
  stateChanged: boolean = false;
  isSubmitDisabled?: boolean = false;
  isDataStored?: boolean = false;
  isFormChanged?: boolean = false;
  isFromDirty?: boolean = false;
  isRefreshOnCancel?: boolean = true;
  isDataLoaded?: boolean = false;
}

export const scoreList: any[] = [
  {
    id: 0,
    itemName: 0,
  },
  {
    id: 25,
    itemName: 25,
  },
  {
    id: 50,
    itemName: 50,
  },
  {
    id: 75,
    itemName: 75,
  },
  {
    id: 100,
    itemName: 100,
  },
];

export const trafficLights: any[] = [
  { id: 3, color: "red" },
  { id: 1, color: "green" },
  { id: 2, color: "yellow" },
];

export const colors: any[] = [];

export class CurrentStepInfo {
  tolalLocations: number;
  stepNumber: number;
  isSubmitDisabled: boolean;

  currentTab: string;
  currentStep: string;
  currentStateInfo: string;
  prevStep: string;
  mode: string;
  isContentCollapsed: boolean;
  isFormChanged: boolean;
  maxStepNumber: number;
}

export const attractiveness = [
  {
    title: "e.g. market size",
  },
  {
    title: "e.g. competition",
  },
  {
    title: "e.g. regulation",
  },
  {
    title: "e.g. pricing",
  },
  {
    title: "e.g. market growth",
  },
];

export const component = [
  {
    tab: "location",
    instance: "lc",
    data: "",
    form: "locationForm",
    stepNumber: 1,
  },
  {
    tab: "attractiveness",
    instance: "mca",
    stepNumber: 2,
    data: "",
    form: "attractivenessFrom",
  },

  {
    tab: "rate-every-market",
    instance: "remc",
    data: "",
    stepNumber: 3,
    form: "rateEveryMarketForm",
    child: {
      tab: "compare-markets",
      instance: "mcmc",
      data: "",
      form: "compareMarketForm",
    },
  },
  {
    tab: "mf-attractiveness",
    stepNumber: 4,
    instance: "mfa",
    data: "",
    form: "marketFitStep1From",
  },

  {
    tab: "mf-rate-every-market",
    instance: "mfremc",
    stepNumber: 5,
    data: "",
    form: "rateEveryMarketForm",
    child: {
      tab: "mf-compare-market",
      instance: "mfcc",
      data: "",
      form: "compareMarketForm",
    },
  },
  {
    tab: "mo-analysis",
    instance: "moac",
    data: "",
    stepNumber: 6,
    form: "",
  },
  {
    tab: "your-goal",
    instance: "ygc",
    stepNumber: 7,
    data: "",
    form: "costForm",
  },
  {
    tab: "estimated-costs",
    instance: "ecc",
    stepNumber: 8,
    data: "",
    form: "estimatedCostForm",
  },
  {
    tab: "moco-analysis",
    data: "",
    stepNumber: 9,

    instance: "mocoAnalysis",
    form: "",
  },
  {
    tab: "analysis-report",
    instance: "arc",
    stepNumber: 10,

    form: "reportForm",
    data: "",
  },
];

export const _stepsTitle = [
  {
    step: 1,
    name: "location",
    label: "Markets",
    tab: "markets",
  },
  {
    step: 2,
    label: "Prameters Attractiveness",
    name: "attractiveness",
    tab: "prametersAttractiveness",
    isCollapsed: false,
    title:
      'What characteristics define the market attractiveness for your business? Number 1 is always the market size meausured by amount of potential customers (choose 1 customer segment). Look at the Market Opportunity in the DutchBasecamp Internationalisation Canvas and choose the parameters of market size, competition, regulation and pricing here. Please note: Culture + The Need are part of the 2nd sheet "Market fit". If you know some factors are more important than others, you can take that into account with the weigh factor, make sure it always adds up to 1.',
  },
  {
    step: 3,
    name: "rate-every-market",
    label: "Scores Attractiveness",
    tab: "scoresAttractiveness",
    isCollapsed: false,
    title:
      "In this step you rate every market by using the scale you designed in step 3. Fill in the exact number for your market size and fill out 0, 25, 50, 75, 100 for all the characteristics per market. The idea is that you rate every market in the same way, to bring a rationale behind your gut feeling. So let's start rating your top 5 markets according to your own scores. If you do not know a certain score, this is a good indicator of things you need to do more desk research for. Of course all your findings need to be validated, you could make this the goal of attending a tech conference, or joining a startup mission to the tech ecosystem of your choice.",
  },
  {
    name: "compare-markets",
    label: "Scores Attractiveness (Compare Markets)",
  },
  {
    step: 4,
    name: "market-fit-attractiveness",
    label: "Parameters Fit",
    tab: "parametersFit",
    isCollapsed: false,
    title:
      'Let\'s look at what makes your customers choose your product/service, look into your current value proposition and product/market fit. In the DutchBasecamp Internationalisation Canvas we are looking at the characteristics you came up with at "The need" and "Culture". Choose the 5 most defining ones, which also differ from one market to another. If certain parameters are more important you can give those a higher weigh factor, make sure it still adds up to 1.',
  },
  {
    step: 5,
    name: "mf-rate-every-market",
    tab: "mfScoresAttractiveness",
    label: "Scores Fit",
    isCollapsed: false,
    title:
      "Like in step 3, here you build a scale along which we will rate every market again. You can define this scale in any way you want, what is important is that you rate every market along the same lines.",
  },
  {
    name: "mf-compare-markets",
    label: "Scores Fit (Compare Markets)",
  },
  {
    step: 6,
    name: "mo-analysis-graph",
    tab: "moAnalysis",
    label: "Mo Analysis",
    isCollapsed: false,
    title:
      "Here you find your first Market Opportunity analysis. The markets in the top right look like they offer the biggest opportunity for your product / service. We suggest you start working on Go-to-Market Strategies for these markets. You can use the DutchBasecamp Internationalisation Canvas for this, to make sure you consider everything involved. No markets on the top right square? Check your scale in step 3 and step 6, is your scale maybe to wide? Check step 4 and step 7, were there many scores you do not know (yet) and scored with 50? If everything is filled out correctly you might want to change the least interesting market with another one and see if this one scores higher.",
  },
  {
    step: 7,
    name: "cost-goal",
    tab: "costs",
    label: "Goal",
    isCollapsed: false,
    title: "What is your goal for year 1 in the new market?",
  },
  {
    step: 8,
    name: "estimated-costs",
    tab: "costs",
    label: "Costs",
    isCollapsed: false,
    title:
      "Now list the estimated costs involved per market. If you want to add a 2nd strategy for a market here you can by selecting a less interesting market and changing it into a 2nd version.",
  },
  {
    step: 9,
    name: "moco-analysis",
    tab: "mocoAnalysis",
    label: "Moco Analysis",
    isCollapsed: false,
    title:
      "Now you can see the Market Opportunity set against the costs involved. Use this input to build your international roadmap. In case you used alternative strategies for the same market you need to manually copy the Market Size and Market Opportunity count.",
  },
  {
    step: 10,
    name: "analysis-report",
    tab: "analysisReport",
    label: "Report",
    isCollapsed: false,
    title:
      "Now you can see the Market Opportunity set against the costs involved. Use this input to build your international roadmap. In case you used alternative strategies for the same market you need to manually copy the Market Size and Market Opportunity count.",
  },
];
