import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MarketAnalysisService } from "../market-analysis/services/market-analysis.service";
import { analysis } from "../market-analysis/models/analysis";
import { ActivatedRoute, Router } from "@angular/router";
import { MarketAnalysisStore } from "../market-analysis/services/market-analysis.store";
import { AnalysisChart } from "../market-analysis/models/market-characteristics/analysis-chart.model";
import { SettingsService } from "../../shared/service/settings.service";
import {
  graphConfig,
  GraphConfig,
} from "../../shared/components/mo-analysis-graph/graph-config";
import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { ConfirmService } from "../../shared/service/confirm.service";
import {
  tap,
  startWith,
  debounceTime,
  switchMap,
  catchError,
} from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { empty, of, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-moco-dashboard",
  templateUrl: "./moco-dashboard.component.html",
  styleUrls: ["./moco-dashboard.component.scss"],
})
export class MocoDashboardComponent implements OnInit {
  analysisForm: FormGroup;
  isShowAnanlysisForm: boolean = false;
  totUser = 0;
  totAnylse = 0;
  showStats = "";
  showtable = false;
  analysisChartsPageSubscription: Subscription;
  settingsServiceSubscription: Subscription;
  analysisRenamedSubscription: Subscription;
  storeAnalysisSubscription: Subscription;

  analysisPage: any;
  allAnlysis: AnalysisChart[] = [];

  chartConfig: GraphConfig = graphConfig;
  mostUseCountry: any;
  mostRatedCountry: any;
  constructor(
    public marketAnalysis: MarketAnalysisService,
    public marketAnalysisStore: MarketAnalysisStore,
    public settingsService: SettingsService,
    public confirm: ConfirmService,
    private router: Router,
    public route: ActivatedRoute
  ) {}

  q = "";
  subsChart;
  subsChartSub;
  ngOnInit(): void {
    this.chartConfig.label.yLabel = "Market attractiveness";
    this.chartConfig.label.xLabel = "Market fit";

    this.chartConfig.showPointLabel = false;
    this.chartConfig.height = 260;
    this.chartConfig.width = 373;

    this.analysisForm = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.minLength(2)]),
    });
    // this.marketAnalysis.checkIfStorageHaveAnalysis();
    this.settingsService.removeItem("analysis");

    this.settingsServiceSubscription = this.settingsService.settings.subscribe(
      (res) => {
        if (res["showStats"] == "A") {
          this.totAnylse = res["totalAnalyse"];
          this.totUser = res["totalUser"];
          this.q = "dashboard";
        }

        this.mostUseCountry = res["mostUseCountry"];
        this.mostRatedCountry = res["mostRatedCountry"];

        if (res["showStats"] == "C") {
          this.q = "company";
        }

        if (res["showStats"] == "coach") {
          this.q = "coach";
        }

        if (
          res["showStats"] == "C" ||
          res["showStats"] == "A" ||
          res["showStats"] == "coach"
        ) {
          if (this.marketAnalysis.isAdmin()) {
            this.marketAnalysisStore
              .getRecentAnalysis()
              .subscribe((response: any) => {
                this.showtable = true;
                this.allAnlysis = response;
              });
          }
          if (!this.marketAnalysis.isAdmin()) {
            this.subsChart = this.marketAnalysisStore.getAnalysisCharts(this.q);
            this.subsChartSub =
              this.marketAnalysisStore.analysisCharts.subscribe((res) => {
                this.showtable = true;
                this.allAnlysis = res;
                // this.subsChartSub.unsubscribe;
              });
          }

          this.analysisChartsPageSubscription =
            this.marketAnalysisStore.analysisChartsPage.subscribe(
              (response) => {
                if (Object.keys(response).length) {
                  this.analysisPage = response;
                }
              }
            );
        }
      }
    );

    this.checkDuplicateAnalysis();

    // is analysis renamed
    this.analysisRenamedSubscription =
      this.marketAnalysis.analysisRenamed.subscribe((response) => {
        if (response.status) {
          this.subsChart = this.marketAnalysisStore.getAnalysisCharts(this.q);
        }
      });
  }
  storeAble: boolean = false;
  loadingAnalyisCheck: boolean = false;
  checkDuplicateAnalysis() {
    this.analysisForm
      .get("name")
      .valueChanges.pipe(
        tap((value) => {
          return value;
        }),
        startWith(""),
        debounceTime(500),
        switchMap((value) => {
          this.storeAble = false;
          if (value) {
            this.loadingAnalyisCheck = true;
            return this.marketAnalysis.checkAnalysisName(value).pipe(
              catchError((error: HttpErrorResponse) => {
                if (error.status === 422) {
                  this.storeAble = false;
                  this.loadingAnalyisCheck = false;
                }
                return empty();
              })
            );
          }
          // don't call
          return of();
        })
      )

      .subscribe(
        (response) => {
          this.storeAble = true;
          this.loadingAnalyisCheck = false;
        },
        (err) => {
          this.loadingAnalyisCheck = false;
          throw new Error(err);
        }
      );
  }

  showAnanlysisForm() {
    this.isShowAnanlysisForm = !this.isShowAnanlysisForm;
  }

  submitAnalysisForm(value) {
    if (!this.storeAble) {
      return;
    }
    const market = {
      analysis: value,
      type: "analysis",
      isStoreSilently: false,
    };

    this.storeAnalysisSubscription = this.marketAnalysis
      .storeAnalysis(market)
      .subscribe(
        (response: any) => {
          this.settingsService.removeItem("analysis");

          // analysis['analysis'] = response.data.analysis;

          // this.marketAnalysis.setItemToStorage('analysis', analysis);
          // this.marketAnalysis.setItemToStorage(
          //   'analysisSteps',
          //   response.data?.steps
          // );
          this.marketAnalysisStore.analysisButton.next({
            isEnableEditButton: false,
          });

          // this.router.navigate(['/analysis', 'new', response.data.analysis.id]);
          window.location.href = `/analysis/new/${response.data.analysis.id}?org=${response.data.analysis.userId}`;
        },
        (error) => {
          console.log(error.error);
        }
      );
  }

  viewHistory(analysisId: string) {
    //this.router.navigate(['/analysis', 'copy', analysisId]);

    // this.router.navigate(['/admin/analyses/'+analysisId]);

    window.location.href = "/admin/analyses/" + analysisId;
  }

  delAnalysis(gdata) {
    gdata["typeRequest"] = this.q;
    this.confirm.open(ConfirmComponent, gdata, "analysis", gdata.name);
  }

  loadMore() {
    //this.router.navigate(['/admin/analyses']);
    window.location.href = "/admin/analyses/";
  }

  viewEdit(gdata) {
    if (gdata?.status === "in_progress" && this.marketAnalysis.isCompany()) {
      this.router.navigate(["/get-analysis/new/" + gdata.id], {
        relativeTo: this.route,
        queryParams: { org: gdata.user._id },
      });
    } else {
      this.router.navigate(["/get-analysis/view/" + gdata.id], {
        relativeTo: this.route,
        queryParams: { org: gdata.user._id },
      });
    }
  }

  ngOnDestroy() {
    if (this.subsChart) {
      this.subsChart.unsubscribe();
    }

    if (this.subsChartSub) {
      this.subsChartSub.unsubscribe();
    }

    if (this.analysisChartsPageSubscription) {
      this.analysisChartsPageSubscription.unsubscribe();
    }

    if (this.settingsServiceSubscription) {
      this.settingsServiceSubscription.unsubscribe();
    }

    if (this.analysisRenamedSubscription) {
      this.analysisRenamedSubscription.unsubscribe();
    }

    if (this.storeAnalysisSubscription) {
      this.storeAnalysisSubscription.unsubscribe();
    }
  }

  copyAbleConfirmation(analyses) {
    // [routerLink] = "['/analysis', 'copy', gdata.id]";
    this.marketAnalysisStore.copyAbleConfirmation(analyses);
  }

  renameAnalysis(analysis) {
    this.marketAnalysisStore.renameAnalysis(analysis);
  }

  getLogo(url) {
    if (url) {
      return `${environment.apiUrl}storage/${url}`;
    }

    return "";
  }
}
