import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, Observable, of, empty } from 'rxjs';
import {
  startWith,
  debounceTime,
  switchMap,
  catchError,
  tap
} from 'rxjs/operators';
import { MarketAnalysisService } from '../../market-analysis/services/market-analysis.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-copyable-info-dialog',
  templateUrl: './copyable-info-dialog.component.html',
  styleUrls: ['./copyable-info-dialog.component.scss']
})
export class CopyableInfoDialogComponent implements OnInit {
  formGroup: FormGroup;
  duplicateChecker: Subscription;
  nameCheckerMessage = '';
  loading: boolean = false;
  storeAble: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CopyableInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private marketAnalysisService: MarketAnalysisService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.formGroup = new FormGroup({
      analysisName: new FormControl()
    });

    this.duplicateChecker = this.formGroup
      .get('analysisName')
      .valueChanges.pipe(
        tap(value => {
          this.loading = true;
          return value;
        }),
        startWith(''),
        debounceTime(1000),
        switchMap(value => {
          this.nameCheckerMessage = '';
          this.storeAble = false;
          if (value) {
            this.loading = true;
            this.nameCheckerMessage = 'Checking for duplicate analysis...';
            return this.marketAnalysisService.checkAnalysisName(value).pipe(
              catchError((error: HttpErrorResponse) => {
                console.log(error);
                this.loading = false;
                this.nameCheckerMessage = '';
                if (error.status === 422) {
                  this.nameCheckerMessage =
                    'The name already exists, Please try a different name.';
                }
                return empty();
              })
            );
          }
          // don't call
          return of();
        })
      )

      .subscribe(
        response => {
          console.log(response);
          this.nameCheckerMessage = 'Great, Analysis name is available.';
          this.loading = false;
          this.storeAble = true;
        },
        err => {
          throw new Error(err);
        }
      );

    // this.duplicateChecker
  }

  copyAsNewAnalysis() {
    if (!this.storeAble) {
      return;
    }

    this.dialogRef.close();
    this.router.navigate(['/analysis', 'copy', this.data.id], {
      queryParams: this.formGroup.value
    });
  }

  ngOnDestroy() {
    if (this.duplicateChecker) {
      this.duplicateChecker.unsubscribe();
    }
  }
}
