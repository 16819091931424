import { Component, ElementRef, Input, OnInit } from "@angular/core";
import * as d3 from "d3";
import { SettingsService } from "../../service/settings.service";
import { GraphConfig } from "../mo-analysis-graph/graph-config";
import { GraphData } from "../mo-analysis-graph/graph-data.model";
import * as _ from "lodash";

@Component({
  selector: "bubble-chart",
  template: ` <svg class="bubble"></svg> `,
})
export class BubbleChartComponent implements OnInit {
  @Input() datasets: GraphData[];
  @Input() xLabel: string;
  @Input() yLabel: string;
  @Input() config: GraphConfig;

  data = {
    children: [
      {
        name: "ABC",
        children: [],
      },
    ],
  };

  private xAxis: any;
  private yAxis: any;
  private circle: any;
  private node: any;

  private svg: any;
  private margin = { top: 10, right: 100, bottom: 60, left: 60 };
  width: number = 373;
  height: number = 260;
  xMin: number = 0;
  xMax: number = 100;

  constructor(
    private container: ElementRef,
    private settingService: SettingsService
  ) { }

  ngOnInit(): void {
    this.graphRunner()
  }

  ngOnChanges() {
    if (this.node) {
      this.prepaireData();
      this.node.remove();
      this.renderChart();
    }
  }

  resetGraph() {
    this.svg = null;
    this.xAxis = null;
    this.yAxis = null;
    this.circle = null;
  }

  graphRunner() {
    this.width = this.config.width;
    this.height = this.config.height;
    this.prepaireData();
    this.initChart();
    this.initAxis();
    this.renderChart();
  }

  prepaireData() {
    let data = this.datasets.map((data: GraphData) => {
      let _data = {
        Name: data.name,
        Count: data.pointRadius,
        X: data.x,
        Y: data.y,
        color: data.color,
      };
      return _data;
    });
    if (this.xLabel == "Cost in 1 year") {
      this.xMin = Math.floor(d3.min(data, function (d) { return d.X; }) / 1000) * 1000;
      this.xMax = Math.ceil(d3.max(data, function (d) { return d.X; }) / 1000) * 1000;
    } else {
      this.xMax = 100;
    }
    this.data["children"][0]["children"] = _.orderBy(data, ['Count'], ['desc']);

  }

  initChart() {
    this.svg = d3
      .select(this.container.nativeElement)
      .select("svg")
      .attr("width", this.width)
      .attr("height", this.height)

      .attr("class", "bubble")
      .attr(
        "viewBox",
        `-75 -8 ${this.width + this.margin.right} ${this.height + this.margin.top + this.margin.bottom
        }`
      )
      ;

    this.svg
      .attr("width", '100%')
      .attr("height", '100%')
      // .attr('viewBox','0 0 '+Math.min(this.width,this.height) +' '+Math.min(this.width,this.height) )
      .attr('preserveAspectRatio', 'xMinYMin')
      .append("g")
      .attr("transform", "translate(" + Math.min(this.width, this.height) / 2 + "," + Math.min(this.width, this.height) / 2 + ")");
  }

  initAxis() {
    this.xAxis = d3
      .scaleLinear()
      .domain([this.xMin, this.xMax])
      .range([0, this.width + 15]);

    this.yAxis = d3.scaleLinear().domain([0, 100]).range([this.height, 0]);

    let xAxis = d3.axisBottom(this.xAxis).scale(this.xAxis).ticks(5);
    let yAxis = d3
      .axisLeft(this.yAxis)
      .scale(this.yAxis)
      .tickValues([0, 25, 50, 75, 100]);

    this.svg.append("g").attr("class", "axis-color").call(yAxis);

    // X - axis label
    this.svg
      .append("g")
      .attr("class", "axis-color axis-rotate-text")
      .attr("transform", "translate(0," + this.height + ")")
      .call(xAxis);

    this.svg
      .append("text")
      .attr("class", "x axis-label")
      .attr("text-anchor", "middle")
      .attr("x", this.width / 2 - 20)
      .attr("y", this.height + 50)
      .attr("fill", "#8a8a8a")
      .attr("transform", "translate(5, 10)")
      .text(this.xLabel);
    // Y - axis label
    this.svg
      .append("text")
      .attr("class", "y axis-label")
      .attr("text-anchor", "end")
      .attr("y", -60)
      .attr("x", -60)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .attr("fill", "#8a8a8a")
      .text(this.yLabel);
  }

  renderChart() {
    let height = 220;
    let width = 373;
    let color = d3.scaleOrdinal(d3.schemeCategory10);

    let bubble = d3.pack().size([width, height]).padding(10);

    let nodes = d3.hierarchy(this.data).sum(function (d: any) {
      return d.Count;
    });
    this.node = this.svg
      .selectAll(".bubble")
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function (d) {
        return !d.children;
      })
      .append("g")
      // .attr("class", "node")
      .style("fill", function (d, i: any) {
        return color(i);
      });

    this.node.append("title").text(function (d: any) {
      return d.data.Name + ": " + (d.data.X + "," + d.data.Y);
    });

    let self = this;

    this.circle = this.node
      .append("circle")
      .attr("class", "bubbles")
      // .attr('fill-opacity',  0.45)
      .attr("cx", function (d) {
        return self.xAxis(d.data.X);
      })
      .attr("cy", function (d) {
        return self.yAxis(d.data.Y);
      })
      .attr("r", function (d) {
        return d.r ? d.r : 0;
      });
    this.circle.attr("fill", function (d: any) {

      return self.gradient(d);
    });

    this.circle.style("filter", function (d: any) {
      return self.shadow(d);
    });
  }
  gChartIndex: number = 0;
  gradient(d) {
    this.gChartIndex = Math.random()
    var gradient = this.svg
      .append("svg:defs")
      .append("svg:linearGradient")
      .attr("id", "gradient" + this.gChartIndex + this.getTrimedName(d.data.Name))
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "100%")
      .attr("spreadMethod", "pad");

    gradient
      .append("svg:stop")
      .attr("offset", "0%")
      .attr("stop-color", this.settingService.hexToRgbA(d.data.color))
      .attr("stop-opacity", 1);

    // gradient
    //   .append("svg:stop")
    //   .attr("offset", "50%")
    //   .attr("stop-color", "#ffffff")
    //   .attr("stop-opacity", 0.5);

    gradient
      .append("svg:stop")
      .attr("offset", "100%")
      .attr("stop-color", d.data.color)
      .attr("stop-opacity", 1);
    return `url(#gradient${this.gChartIndex}${this.getTrimedName(d.data.Name)})`;
  }
  sChartIndex: number = 0;
  shadow(d) {
    this.sChartIndex = Math.random()
    // filters go in defs element
    var defs = this.svg.append("defs");

    // create filter with id #drop-shadow
    // height=130% so that the shadow is not clipped
    var filter = defs
      .append("filter")
      .attr("id", "drop-shadow" + this.sChartIndex + this.getTrimedName(d.data.Name))
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", "400%")
      .attr("width", "400%");

    // SourceAlpha refers to opacity of graphic that this filter will be applied to
    // convolve that with a Gaussian with standard deviation 3 and store result
    // in blur
    filter
      .append("feGaussianBlur")
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 2)
      .attr("result", "blur");

    // translate output of Gaussian blur to the right and downwards with 2px
    // store result in offsetBlur
    filter
      .append("feOffset")
      .attr("in", "blur")

      .attr("dx", 2)
      .attr("dy", 2)
      .attr("result", "offsetBlur");

    filter
      .append("feFlood")
      .attr("in", "offsetBlur")
      .attr("flood-color", d.data.color)
      .attr("flood-opacity", "0.5")
      .attr("result", "offsetColor");

    filter
      .append("feComposite")
      .attr("in", "offsetColor")
      .attr("in2", "offsetBlur")
      .attr("operator", "in")
      .attr("result", "offsetBlur");

    // overlay original SourceGraphic over translated blurred opacity by using
    // feMerge filter. Order of specifying inputs is important!
    var feMerge = filter.append("feMerge");

    feMerge.append("feMergeNode").attr("in", "offsetBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");
    return `url(#drop-shadow${this.sChartIndex}${this.getTrimedName(this.getTrimedName(d.data.Name))})`;
  }

  getTrimedName(country) {
    return country.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }
}
