import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  SecurityContext,
  ViewChild,
  HostListener,
} from "@angular/core";
import { EventData, scoreList, trafficLights } from "../../../models/analysis";
import { MarketAnalysisService } from "../../../services/market-analysis.service";
import { MarketAnalysisStore } from "../../../services/market-analysis.store";
import { Country } from "../../../models/market-characteristics/country.model";
import { Attractiveness } from "../../../models/market-characteristics/attractiveness.model";
import { FormGroup, FormArray, FormControl } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { McCompareMarketsComponent } from "../mc-compare-markets/mc-compare-markets.component";
import { MarketFitAnalysisGraph } from "../../../../../shared/components/mo-analysis-graph/graph-data.model";
import { NotifyService } from "../../../../../shared/service/notify.service";

@Component({
  selector: "app-rate-every-market",
  templateUrl: "./rate-every-market.component.html",
  styleUrls: ["./rate-every-market.component.scss"],
})
export class RateEveryMarketComponent implements OnInit {
  @Output()
  onStepperChange: EventEmitter<EventData> = new EventEmitter<EventData>();

  @ViewChild(McCompareMarketsComponent) public mcmc: McCompareMarketsComponent;
  display;
  initialSteps: EventData = {
    stepNumber: 3,
    locations: 0,
    stateChanged: false,
  };
  trafficLights: any[] = trafficLights;
  isFormValueChanged: boolean = false;
  locations: Country[] = [];
  attractiveness: Attractiveness[] = [];
  rateEveryMarketForm: FormGroup;
  rateMarketsData;
  compareMarketData;

  currentTab = "marketCharacteristics";
  prevData: any;

  constructor(
    private marketAnalysis: MarketAnalysisService,
    private marketAnalysisStore: MarketAnalysisStore,
    public sanitizer: DomSanitizer,
    private notify: NotifyService
  ) {}

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "rate-every-market"});
    
    this.onStepperChange.emit(this.initialSteps);
    this.createRateEveryMarketForm();
    this.rateMarketsData = this.marketAnalysisStore.getItemFromStorage(
      "analysis"
    );

    this.marketAnalysisStore.marketCharacteristics.subscribe((response) => {
      if (Object.keys(response).length) {
        this.locations = response.location;
        this.attractiveness = response.attractiveness;
        this.patchRateEveryMarketForm(this.attractiveness);
        this.marketAnalysisStore.patchCopyableInfo(
          this.rateEveryMarketForm,
          "rate-every-market"
        );

        this.prevData = this.rateEveryMarketForm.value.rateMarkets;
      }
    });

    this.rateEveryMarketForm.valueChanges.subscribe((res) => {
      this.isFormValueChanged = true;

      if (this.rateEveryMarketForm.dirty) {
        this.initialSteps.isFromDirty = true;
        this.initialSteps.isRefreshOnCancel = true;
        this.onStepperChange.emit(this.initialSteps);
      }
    });
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  /**Detect compare market change */

  changeState(event: EventData) {
    if (event.isFromDirty) {
      this.initialSteps.isFromDirty = true;
      this.initialSteps.isRefreshOnCancel = true;
      this.onStepperChange.emit(this.initialSteps);
    }
  }

  createRateEveryMarketForm() {
    this.rateEveryMarketForm = new FormGroup({
      rateMarkets: new FormArray([]),
    });
  }

  get rateMarkets() {
    return this.rateEveryMarketForm.get("rateMarkets") as FormArray;
  }

  getName(index: number) {
    return this.rateMarkets.at(index).get("parameterOfHomeMarket").value;
  }

  getCountryName(index, countryIndex) {
    return this.getCountry(index).at(countryIndex).get("name").value;
  }

  calculateAttractiveness(countryIndex, countryName) {
    let score = 0;
    let absoluteNumber = 0;
    const rateMarkets = this.rateMarkets.value;
    rateMarkets.forEach((rate: any, key) => {
      let scoreFilled = 0;
      try {
        scoreFilled = rate.country[countryIndex].scoreFilled;
      } catch (error) {
        scoreFilled = 0;
      }

      score = score + scoreFilled * rate.weightFactor;
      if (key === 0) {
        let _country = rate.country.filter(
          (c) => c.name.toLowerCase() === countryName.toLowerCase()
        );
        let absNumber = 0;
        if (_country.length) {
          absNumber = _country[0].absoluteNumber;
        } else {
          try {
            absNumber = rate.country[countryIndex].absoluteNumber;
          } catch (error) {
            absNumber = 0;
          }
        }
        absoluteNumber = absoluteNumber + absNumber;
      }
    });

    /** set location with absolute no and total attractivenes */
    const moAnalysis = this.rateMarketsData["moAnalysis"]["graph"];
    this.locations.map((location: any) => {
      if (location.name === countryName) {
        (location.attractiveness = score),
          (location.marketSize = absoluteNumber);
      }
      return location;
    });
    this.locations = this.marketAnalysisStore.mapGraphDataToLocation(
      this.locations,
      moAnalysis
    );
    return score.toFixed(2);
  }

  accuracy(index, countryIndex) {
    return parseInt(
      this.getCountry(index).at(countryIndex).get("accuracy").value
    );
  }

  getCountry(index) {
    return this.rateMarkets.at(index).get("country") as FormArray;
  }

  /**implement select */
  scoreList = scoreList;
  selectedItems = [
    {
      id: 0,
      itemName: 0,
    },
  ];
  settings = {
    singleSelection: true,
    text: "Select score",
    classes: "score-selection-container",
  };

  patchRateEveryMarketForm(attractiveness: Attractiveness[]) {
    this.rateMarkets.clear();
    attractiveness.forEach((attr: Attractiveness, key: number) => {
      this.rateMarkets.push(
        new FormGroup({
          id: new FormControl(attr.id),
          weightFactor: new FormControl(attr.weightFactor),
          parameterOfHomeMarket: new FormControl(attr.parameterOfHomeMarket),
          country: new FormArray([]),
        })
      );
      this.setCountryForm(key);
    });
  }

  setCountryForm(index) {
    const countryForm = this.getCountry(index);
    this.locations.forEach((location: Country, key) => {
      let rem = [];
      if (this.attractiveness[index].rateEveryMarket) {
        rem = this.attractiveness[index].rateEveryMarket.filter(
          (rem) => rem.name.toLowerCase() === location.name.toLowerCase()
        );
      }

      if (rem.length) {
        location = rem[0];
        location.scoreFilled = +location.scoreFilled;
      }

      countryForm.push(
        new FormGroup({
          id: new FormControl(location.id),
          name: new FormControl(location.name),
          absoluteNumber: new FormControl(
            index === 0 ? location.absoluteNumber : 0
          ),
          scoreSelected: new FormControl([
            {
              id: 0,
              itemName: 0,
            },
          ]),
          scoreFilled: new FormControl(location.scoreFilled),
          accuracy: new FormControl(location.accuracy ? location.accuracy : 3),
        })
      );
    });
    // if (this.attractiveness[index].rateEveryMarket) {
    //   countryForm.patchValue(this.attractiveness[index].rateEveryMarket);
    // }
  }

  storeMarketRate(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }

    // if (!this.isFormValueChanged) {
    //   this.changeToNext();
    //   return false;
    // }
    if (!isStoreSilently) {
      this.mcmc.storeCompareMarket(isRedirect, isStoreSilently);
    }

    // check absolute no. for non zero nd greater than zero value

    if (!isStoreSilently) {
      let first_attractiveness = this.rateMarkets.value[0];
      for (
        let index = 0;
        index < first_attractiveness.country.length;
        index++
      ) {
        const element = first_attractiveness.country[index];
        if (element.absoluteNumber <= 0) {
          this.notify.notifyError(
            "The absolute number value is required",
            "OK"
          );
          return;
        }
      }
    }

    const market = {
      rateEveryMarket: this.rateMarkets.value,
      prevData: this.prevData,
      nextData: this.rateEveryMarketForm.value.rateMarkets,
      graph: this.locations,
      type: "rateEveryMarket",
      analysisId: this.rateMarketsData["analysis"]["id"],
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1,
      isStoreSilently: isStoreSilently,
    };
    this.marketAnalysis.storeAnalysis(market).subscribe((response: any) => {
      const data = {
        attractiveness: response.data.attractiveness,
        moAnalysis: response.data.analysis.moAnalysis,
      };
      if (isRedirect) {
        this.marketAnalysis.exit();
        return;
      }
      this.changeToNext(data, isStoreSilently);
    });
  }

  changeToNext(data = null, isStoreSilently = false) {
    let _analysis;
    if (this.rateMarketsData) {
      if (data) {
        this.rateMarketsData[this.currentTab]["attractiveness"] =
          data.attractiveness;

        this.rateMarketsData["moAnalysis"]["graph"] = data.moAnalysis;
      }

      if (!isStoreSilently) {
        this.rateMarketsData["currentTab"] = "parametersFit";
        this.rateMarketsData["current"] = +this.rateMarketsData["current"] + 1;
      }

      _analysis = this.rateMarketsData;
    }

    this.marketAnalysis.setCurrentStep("analysis", _analysis);
    this.initialSteps.stateChanged = true;
    this.onStepperChange.emit(this.initialSteps);
  }

  storeDataSilently() {
    this.initialSteps.isFormChanged = true;
    this.onStepperChange.emit(this.initialSteps);
    this.storeMarketRate(false, true);
  }

  renderGraph() {
    let score;
    this.locations.forEach((el, index) => {
      this.calculateAttractiveness(index, el.name);
    });

    let location = this.locations.map(
      (analysis: any) => new MarketFitAnalysisGraph(analysis)
    );
    this.marketAnalysisStore.updateChart.next({
      isUpdate: true,
      data: location,
    });
  }
}
