import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmService } from '../../service/confirm.service';
import { SettingsService } from '../../service/settings.service';
import { CompanyService } from '../../../moco/company/services/company.service';
//import { BsModalRef } from 'ngx-bootstrap/modal'; //
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  mediaId: number;
  type: string;
  action: string;
  title: string;
  identifier: any,
  cancelReturn: boolean;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  public identifier: any;
  public type: string;
  public title: string;

  deleteClick=false;
  totalHistory=0;

  constructor(
    public confirm: ConfirmService,
    public settings: SettingsService,
    private companyService: CompanyService,
  //  public modalRef: BsModalRef,
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.identifier =this.data.identifier;
    this.type =this.data.type;
    this.title =this.data.title;
    this.deleteClick=false;
    if(this.type==='analysis'){
      console.log('identifier',this.type,this.identifier);
     this.totalHistory= this.identifier?.totalHistory;
    }
    
  }

  delele() {
    this.deleteClick=true;
    this.settings.delete(this.identifier, this.type,this.dialogRef);
   
  }

  deleleAnalysis(){
    this.identifier['history']='delete';
    this.confirm.open(ConfirmComponent, this.identifier, 'analysis-width-history','');
  }
  

  closePopup(){
    
    if(this.type==='organization-status'){
      this.companyService.getCompaniesAll('', false, this.companyService.getCoach());
    }
   // this.modalRef.hide();
    //this.confirm.close();
    this.dialogRef.close();

    
    
  }
  generatePassword() {
    this.dialogRef.close('generate');
  }
}
