import * as moment from 'moment';

export class Moco {
  /**
   * convert date to local time
   * @param date
   * @param format
   */
  toLocale(date, format = 'DD-MM-YYYY HH:mm:ss') {
    if(!date) {
      return '';
    }
    
    return moment(new Date(date)).format(format);
  }
}
