import { RouterModule, Routes } from '@angular/router';


// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { LoginComponent } from "./authentication/component/login/login.component";
import { RegisterComponent } from "./authentication/component/register/register.component";
import { PasswordResetEmailComponent } from "./authentication/component/password-reset-email/password-reset-email.component";
import { NgModule } from '@angular/core';
 
export const routes: Routes = [
  
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },

  {
    path: "password-reset-mail",
    component: PasswordResetEmailComponent,
    data: {
      title: "Login Page",
    },
  },

  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page",
    },
  },

  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      
      {
        path: "logs/:type",
        loadChildren: () => import("./log/log.module").then((m) => m.LogModule),
      },
      {
        path: "recover-analysis",
        loadChildren: () =>
          import("./recover-analysis/recover-analysis.module").then(
            (m) => m.RecoverAnalysisModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
