// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label{
    margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/moco/moco-admin/components/steps-text/steps-text.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB","sourcesContent":["label{\r\n    margin-bottom: 0.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
