import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn:
      'https://47d3f07a245b4c5f84a2aa5583c65f12@o177337.ingest.sentry.io/5265736',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'moco.tools',
          'api.moco.tools/api',
          'http://127.0.0.1:8000/api',
          /^\//
        ],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: true
  })
  .catch(err => console.log(err));
