import { CompareMarket } from './compare-market.model';

export interface AttractivenessInterface {
  id: string;
  analysisId: string;
  accuracy: number;
  parameterOfHomeMarket: string;
  source: number;
  weightFactor: number;
  compareMarket: any;
  rateEveryMarket: any[];
}

export class Attractiveness {
  id: string;
  analysisId: string;
  accuracy: number;
  parameterOfHomeMarket: string;
  source: number;
  weightFactor: any;
  compareMarket: CompareMarket;
  rateEveryMarket: any[];
  rateAccuracy: number;
  constructor(
    attr: AttractivenessInterface = {
      accuracy: 1,
      parameterOfHomeMarket: '',
      source: 0,
      weightFactor: 0,
      id: '',
      analysisId: '',
      compareMarket: {},
      rateEveryMarket: []
    }
  ) {
    this.accuracy = attr.accuracy;
    this.parameterOfHomeMarket = attr.parameterOfHomeMarket;
    this.source = attr.source;
    this.weightFactor = attr.weightFactor;
    this.id = attr.id;
    this.analysisId = attr.analysisId;
    this.compareMarket = new CompareMarket(attr.compareMarket);
    this.rateEveryMarket = attr.rateEveryMarket;
  }
}
