import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { LocationStore } from '../../../services/market-characteristics/location.store';
import { Country } from '../../../models/market-characteristics/country.model';
import { startWith, map } from 'rxjs/operators';
import { LocationService } from '../../../services/market-characteristics/location.service';
import { Observable } from 'rxjs';
import { MarketAnalysisService } from '../../../services/market-analysis.service';
import { MarketAnalysisStore } from '../../../services/market-analysis.store';
import { analysis, EventData } from '../../../models/analysis';
import { Response } from '../../../../../shared/model/response';
import * as _ from 'lodash';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  @Output('onStepperChange') state: EventEmitter<EventData> = new EventEmitter<
    EventData
  >();
  currentTab = 'marketCharacteristics';

  initialSteps: EventData = {
    stepNumber: 1,
    locations: 0,
    stateChanged: false
  };
  prevData: any;

  locationForm: FormGroup;
  locationsList: Country[] = [];
  filteredOptions: Observable<Country[]>[] = [];
  locationData;
  isFormValueChanged: boolean = false;
  isFormDirty: boolean = false;
  isAddable: any[] = [];
  popOver: any[] = [];
  isStoreSilently: boolean = false;

  constructor(
    public locationStore: LocationStore,
    public marketAnalysis: MarketAnalysisService,
    public marketAnalysisStore: MarketAnalysisStore
  ) {}

  ngOnInit(): void {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: true, step: "location"});
    
    this.initialSteps.isRefreshOnCancel = true;
    /**Set current step */
    analysis['currentTab'] = this.currentTab;
    analysis['current'] = 0;
    this.createLocationForm();

    this.locationStore.getLocations('');
    this.locationStore.locations.subscribe((response: Country[]) => {
      if (Object.keys(response).length) {
        this.locationsList = response;
      }
    });
    /**Get item from local storage */

    // const currentStep = this.marketAnalysis.currentStep();
    // this.changeValue();
    this.initialSteps.locations = this.locations.length;
    this.state.emit(this.initialSteps);
    /** get data from local storage */
    this.locationData = this.marketAnalysisStore.analysisFromStorage();

    if (this.locationData) {
      this.patchLocations(this.locationData[this.currentTab]['location']);
      this.marketAnalysisStore.patchCopyableInfo(this.locationForm);
    } else {
      this.marketAnalysisStore.marketCharacteristics.subscribe(response => {
        if (Object.keys(response).length) {
          console.log(response);
          this.patchLocations(response.location);
          this.marketAnalysisStore.patchCopyableInfo(this.locationForm);

          analysis['analysis'] = response.analysis;
          analysis['currentTab'] = 'markets';
          analysis['current'] = 0;
          analysis['marketCharacteristics']['location'] = response.location;
          analysis['marketCharacteristics']['attractiveness'] =
            response.attractiveness;
          // analysis['marketCharacteristics']['location'] = response.location;

          this.marketAnalysis.setItemToStorage('analysis', analysis);
        }
      });
    }

    /**check if value changed */
    this.locationForm.valueChanges.subscribe(res => {
      this.isFormValueChanged = true;
    });

    this.prevData = this.locationForm.value.location;
  }

  ngOnDestroy() {
    this.marketAnalysisStore.videoPlayer.next({isPlayable: false, step: ""});
  }

  /**Popover */
  countryName(index: number) {
    return this.locations.at(index).value;
  }

  createLocationForm() {
    this.locationForm = new FormGroup({
      location: new FormArray([])
    });
    this.changeValue(0);
  }
  get locations() {
    return this.locationForm.get('location') as FormArray;
  }

  pushNewLocation() {
    const controls = <FormArray>this.locationForm.controls['location'];
    this.locations.push(new FormControl(''));
    this.initialSteps.locations = this.locations.length;
    this.state.emit(this.initialSteps);
    this.changeValue(controls.length - 1);
  }

  addNewLocation() {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }

    this.pushNewLocation();
  }

  deleteLocation(index: number) {
    if (this.marketAnalysisStore.isViewMode()) {
      this.emitformDirty();
      return;
    }

    if (this.locations.length > 1) {
      const lc = this.locations.at(index).value;
      this.locations.removeAt(index);
      this.initialSteps.locations = this.locations.length;
      if (lc) {
        this.storeLocation(false, true);
      }
      /**check  */
      this.state.emit(this.initialSteps);
    }
  }

  displayFn(location: Country): string | undefined {
    return location ? location.name : undefined;
  }

  changeValue(index: number) {
    let arrayControl = this.locationForm.get('location') as FormArray;
    if (arrayControl.length) {
      this.filteredOptions[index] = arrayControl.at(index).valueChanges.pipe(
        startWith<string | Country>(''),
        map(value => (typeof value === 'string' ? value : value.name)),
        map(name =>
          name ? this._filter(name, index) : this.locationsList.slice()
        )
      );
    }
  }

  private _filter(name: string, index: number): Country[] {
    const filterValue = name.toLowerCase();
    
    const locations = this.locationsList.filter(
      (option: Country) => {
        return option.name.toLowerCase().indexOf(filterValue) === 0 ||
        option.synonyms.toString().toLowerCase().match(filterValue)
      }
    );
    if (name.length >= 3 && !locations.length) {
      this.isAddable[index] = true;
    } else {
      this.isAddable[index] = false;
    }
    return locations;
  }

  isAdd(type: string, index: number) {
    if (type === 'no') {
      this.deleteLocation(index);
      this.isAddable[index] = false;
    } else if (type === 'yes') {
      this.isAddable[index] = false;
      this.storeLocation(false, true);
    }
  }
  /**Patch loccation */
  patchLocations(location) {
    this.locations.clear();
    if (location.length) {
      location.forEach(location => {
        this.pushNewLocation();
      });
      this.locationForm.patchValue({
        location: location
      });
    } else {
      this.pushNewLocation();
      this.pushNewLocation();
      this.pushNewLocation();
      this.pushNewLocation();
      this.pushNewLocation();
    }
  }

  addLocationError = '';

  storeLocation(isRedirect = false, isStoreSilently = false) {
    /**Check analysis open mode view mode is not updateable */
    if (this.marketAnalysisStore.isViewMode()) {
      return;
    }
    this.locationData = this.marketAnalysisStore.analysisFromStorage();
    this.addLocationError = '';
    /** check if form changed if form value not change then go to next step */
    const value = this.locationForm.value;
    console.log('valLoc', value);
    /**define step */
    const isAddable = this.isAddable.indexOf(true) !== -1;
    if (isAddable) {
      setTimeout(() => {
        this.addLocationError = 'error';
      }, 100);
      return false;
    }
    const v = value.location.map(value => {
      if (value !== null) {
        if (typeof value === 'object') {
          return value;
        } else {
          return {
            id: '',
            name: value
          };
        }
      }
      return '';
    });
    const _filter = v.filter(t => t.name !== '');

    const market = {
      prevData: this.prevData,
      nextData: this.locationForm.value.location,
      location: _filter,
      type: 'location',
      mode: this.locationData['aMode'],
      analysisId: this.locationData.analysis.id,
      isStoreSilently: isStoreSilently,
      stepNumber: isStoreSilently
        ? this.initialSteps.stepNumber
        : this.initialSteps.stepNumber + 1
    };
    this.marketAnalysis
      .storeAnalysis(market)
      .subscribe((resposne: Response) => {
        if (isRedirect) {
          this.marketAnalysis.exit();
          return;
        }
        this.changeToNext(resposne.data.locations, isStoreSilently);
      });
  }

  changeToNext(locations = null, isStoreSilently = false) {
    let _analysis;

    if (this.locationData) {
      if (locations) {
        this.locationData[this.currentTab]['location'] = locations;
      }

      if (!isStoreSilently) {
        this.locationData['currentTab'] = 'prametersAttractiveness';
        this.locationData['current'] = +this.locationData['current'] + 1;
      }

      _analysis = this.locationData;
    }

    this.marketAnalysis.setCurrentStep('analysis', _analysis);
    this.initialSteps.stateChanged = true;
    this.state.emit(this.initialSteps);
  }
  addedMessage = '';

  storeSilently(pop) {
    this.addedMessage = `Market has been added`;
    pop.show();
    setTimeout(() => {
      pop.hide();
    }, 1000);
    this.initialSteps.isFormChanged = true;
    this.initialSteps.isFromDirty = true;
    this.initialSteps.isRefreshOnCancel = true;
    this.state.emit(this.initialSteps);
    this.storeLocation(false, true);
  }

  emitformDirty() {
    this.initialSteps.isRefreshOnCancel = false;
    this.initialSteps.isFromDirty = true;
    this.state.emit(this.initialSteps);
  }
}
