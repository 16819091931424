import { Injectable } from '@angular/core';
import { Service } from '../../../../shared/service/service';

import { BehaviorSubject, Observable } from 'rxjs';
import { StepsAdmin } from '../../models/steps-admin.model';
import { StepsAdminService } from './steps-admin.service';
import { NotifyService } from '../../../../shared/service/notify.service';

@Injectable({
  providedIn: 'root'
})
export class StepsAdminStore extends Service {
  stepss: BehaviorSubject<StepsAdmin[]> = new BehaviorSubject([]);

  public loadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject(true);

  public readonly loadingSteps: Observable<
    Boolean
  > = this.loadingSubject.asObservable();

  constructor(private stepsService: StepsAdminService ,
    private notify: NotifyService) {
    super();
  }

  getSteps(value='') {
    this.loadingSubject.next(true);
    return this.stepsService.getStepss(value).subscribe(response => {
      this.stepss.next(response);
      this.loadingSubject.next(false);
    });
  }

  saveStepss(value) {
    return this.stepsService.saveStepss('', value).subscribe(response => {      
      this.stepss.next(response);
    });
  }

  delStepss(value) {
    return this.stepsService.saveStepss('delete', value).subscribe(response => {      
      this.stepss.next(response);
    });
  }
}
