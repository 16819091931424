import { isDevMode } from "@angular/core";
import { navItems } from "../../_nav";
import { environment } from "../../../environments/environment";
import * as CryptoJS from "crypto-js";

import {
  navItemsForTop,
  navItemsForSuperAdmin,
  navItemsForAdminAndSuperAdmin,
  navItemsForAll,
  navItemsForCoach,
  navItemsForCoachBelow,
} from "../../moco/moco.navbar";

const pwd = "application-pwd-kurtkhrjfgdg";

export class Storage {
  /**
   * Implement storage functionalities
   */

  ecncrypt(value) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), pwd).toString();
  }

  decrypt(value) {
    const decryptedData = CryptoJS.AES.decrypt(value, pwd);
    return JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
  }

  get storage() {
    return environment.storageType;
  }

  setItemToStorage(key: string, value): void {
    value = this.ecncrypt(value);
    this.storage.setItem(key, value);
  }

  getItemFromStorage(key: string) {
    let item = this.storage.getItem(key);
    if (item) {
      item = this.decrypt(item);
    }
    return item;
  }

  removeItem(key: string) {
    return this.storage.removeItem(key);
  }

  getAuthStorage() {
    return this.storage;
  }
}

export class RoleService extends Storage {
  getRoleFromStorage(role) {
    if (role) {
      return role;
    }
    environment;

    let storageRole = this.getItemFromStorage("user-role");
    return storageRole ? storageRole : "";
  }

  hasSuperAdmin(role = null) {
    return this.getRoleFromStorage(role).indexOf("super-admin") !== -1;
  }

  isAdmin(role = null) {
    return this.getRoleFromStorage(role).indexOf("admin") !== -1;
  }

  isCompany(role = null) {
    return this.getRoleFromStorage(role).indexOf("company") !== -1;
  }

  isCoach(role = null) {
    return this.getRoleFromStorage(role).indexOf("coach") !== -1;
  }

  hasTeamMember(role = null) {
    return this.getRoleFromStorage(role)
      ? this.getRoleFromStorage(role).indexOf("team-member") !== -1
      : false;
  }

  hasManager(role = null) {
    return this.getRoleFromStorage(role).indexOf("manager") !== -1;
  }

  isModifiable() {
    return this.isAdmin() || this.hasSuperAdmin() || this.hasManager();
  }

  displayNavBarAccourdingToRole(role) {
    let _navItems: any[] = [];
    if (this.isAdmin(role)) {
      _navItems = [
        ...navItemsForTop,
        ...navItemsForSuperAdmin,
        ...navItemsForAdminAndSuperAdmin,
        ...navItemsForAll,
      ];
    }

    if (this.isCompany(role)) {
      _navItems = [...navItemsForTop, ...navItemsForAll];
    }

    if (this.isCoach(role)) {
      _navItems = [...navItemsForTop, ...navItemsForCoach];
    }

    /**
     * display all nav for development environment due to refrences of page
     */
    // if (isDevMode()) {
    //   _navItems = [..._navItems, ...navItems];
    // }
    return _navItems;
  }

  navBelow() {
    return [...navItemsForCoachBelow, ...navItemsForAll];
  }
}
