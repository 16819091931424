// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host #playerButton[disabled] {
  opacity: 0;
}
:host .icon- {
  width: 36px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  -webkit-text-stroke: 5px #fff !important;
  color: #000 !important;
  background-color: white !important;
  outline: none;
}

.align-vcenter {
  display: flex;
  align-items: center;
}

.modal-header {
  display: flex;
  align-items: center;
}

.heding-middle {
  padding-top: 10px !important;
}

@media (max-width: 575px) {
  .align-vcenter {
    width: 69%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/video-player/video-player.component.scss"],"names":[],"mappings":"AACI;EACI,UAAA;AAAR;AAGI;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,wCAAA;EACA,sBAAA;EACA,kCAAA;EACA,aAAA;AADR;;AAKA;EAAgB,aAAA;EAAe,mBAAA;AAA/B;;AACA;EAAe,aAAA;EAAc,mBAAA;AAI7B;;AAFA;EAAgB,4BAAA;AAMhB;;AAJA;EACM;IAAiB,UAAA;EAQrB;AACF","sourcesContent":[":host {\r\n    #playerButton[disabled] {\r\n        opacity: 0;\r\n    }\r\n\r\n    .icon- {\r\n        width: 36px;\r\n        height: 48px;\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: center;\r\n        font-size: 32px;\r\n        -webkit-text-stroke: 5px #fff !important;\r\n        color: #000 !important;\r\n        background-color: white !important;\r\n        outline: none;\r\n    }\r\n}\r\n\r\n.align-vcenter{ display: flex; align-items: center;}\r\n.modal-header{ display:flex; align-items: center;}\r\n\r\n.heding-middle{ padding-top: 10px !important;}\r\n\r\n@media (max-width:575px){\r\n      .align-vcenter{  width: 69%;}\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
