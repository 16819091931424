import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NotifyService } from '../../../shared/service/notify.service';
import { ExceptionService } from '../../../shared/service/exception.service';
import { Response } from '../../../shared/model/response';
import { Company, Role } from '../model/company.model';
import { Service } from '../../../shared/service/service';
import { Sector } from '../model/sector.model';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends Service {
  private apiUrl: string = `${environment.apiUrl}api`;

  public companySubject: BehaviorSubject<any> = new BehaviorSubject({});
  public rolesSubject: BehaviorSubject<Role[]> = new BehaviorSubject([]);
  public picsSubject: BehaviorSubject<Role[]> = new BehaviorSubject([]);
  public loadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject(true);
  public loadingAllSubject: BehaviorSubject<Boolean> = new BehaviorSubject(
    true
  );
  public allCompanySubject: BehaviorSubject<any> = new BehaviorSubject(true);

  public readonly companies: Observable<
    any
  > = this.companySubject.asObservable();

  public readonly allcompanies: Observable<
    any
  > = this.allCompanySubject.asObservable();

  public readonly roles: Observable<Role[]> = this.rolesSubject.asObservable();
  public readonly pics: Observable<Role[]> = this.picsSubject.asObservable();

  public readonly loadingCompanies: Observable<
    Boolean
  > = this.loadingSubject.asObservable();

  public readonly loadingAllCompanies: Observable<
    Boolean
  > = this.loadingAllSubject.asObservable();
  // isCoach: boolean = false;
  constructor(
    private httpClient: HttpClient,
    private notify: NotifyService,
    private exception: ExceptionService,
    public dialog: MatDialog,
    private router: Router
  ) {
    super();
  }

  getCoach() {
    if (this.router.url === '/coach/list') {
      return true;
    }

    return false;
  }

  getCompanies(id = '') {
    this.loadingSubject.next(true);

    let url = `${this.apiUrl}/companies`;

    if (id) {
      url = `${this.apiUrl}/companies/${id}`;
    }

    if (id && id !== 'new') {
      url = `${this.apiUrl}/company-edit/${id}`;
    }

    return this.httpClient
      .get(url)
      .pipe(
        map((response: Response) => {
          const bs = response.data;
          const data = {
            list: bs.companies.map(user => new Company(user)),
            roles: bs.roles.map(role => new Role(role)),
            sectors: bs.sectors.map(sector => new Sector(sector))
          };
          return data;
        })
      )
      .subscribe(
        response => {
          this.loadingSubject.next(false);
          this.companySubject.next(response);

          this.rolesSubject.next(response.roles);
          if (id !== 'my') {
            this.notify.notifySuccess('Organizations fetched successfully');
          }
        },
        err => {
          this.loadingSubject.next(false);
          this.exception.handleError(err);
        }
      );
  }

  getCompaniesAll(id = '', sillent = false, isCoach = false) {
    this.loadingAllSubject.next(true);
    let url = `${this.apiUrl}/companies?type=${isCoach}`;

    return this.httpClient
      .get(url)
      .pipe(
        map((response: Response) => {
          const bs = response.data;
          const data = {
            list: bs.companies.map(user => new Company(user)),
            roles: bs.roles.map(role => new Role(role)),
            sectors: bs.sectors.map(sector => new Sector(sector))
          };
          return data;
        })
      )
      .subscribe(
        response => {
          this.loadingAllSubject.next(false);
          this.allCompanySubject.next(response);
          this.rolesSubject.next(response.roles);
          if (!sillent) {
            let title = 'Organizations';
            if (isCoach) {
              title = 'Coaches';
            }
            this.notify.notifySuccess(`${title} fetched successfully`);
          }
        },
        err => {
          this.loadingSubject.next(false);
          this.exception.handleError(err);
        }
      );
  }

  getOrganization() {
    let url = `${this.apiUrl}/organizations`;
    return this.httpClient.get(url).pipe(
      map((response: Response) => {
        const bs = response.data;
        const data = {
          organizations: bs.organizations.map(user => new Company(user))
        };
        return data;
      })
    );
  }

  createOrUpdateCompanies(value) {
    return this.httpClient.post(`${this.apiUrl}/company`, value).subscribe(
      response => {
        let msg = 'User information updated successfully.';
        if (response['message'] != null) {
          msg = response['message'];
        }
        if (response['code'] == 201) {
          this.notify.notifyError(msg, 'User');
        } else {
          this.notify.notifySuccess(msg, 'User');
          this.getCompaniesAll('', true, this.getCoach());
        }

        this.dialog.closeAll();
      },
      err => {
        this.exception.handleError(err);
      }
    );
  }

  updatePics(value) {
    return this.httpClient.post(`${this.apiUrl}/update-pics`, value).subscribe(
      response => {
        let msg = 'User pics updated successfully.';
        if (response['message'] != null) {
          msg = response['message'];
        }
        if (response['code'] == 201) {
          this.notify.notifyError(msg, 'User');
        } else {
          this.notify.notifySuccess(msg, 'User');
        }
        this.picsSubject.next(response['data']);
        return response['data'];
      },
      err => {
        this.exception.handleError(err);
      }
    );
  }

  savePassord(data) {
    return this.httpClient.post(`${this.apiUrl}/generate-password`, data);
  }
}
