import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { MarketAnalysisComponent } from "../../moco/market-analysis/components/market-analysis/market-analysis.component";
import { SettingsService } from "../service/settings.service";

@Injectable({
  providedIn: "root",
})
export class UnsavedInfoGuard
  implements CanDeactivate<MarketAnalysisComponent>
{
  constructor(
    private settingService: SettingsService,
    private router: Router
  ) {}
  canDeactivate(
    component: MarketAnalysisComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log(component);
    if (
      component &&
      !this.settingService.isAdmin() &&
      !component.isNavigateTo &&
      component.info.isFormChanged &&
      component.info.mode !== "view"
    ) {
      component.checkUnsavedInfo(nextState.url);
    }
    let isNavigateTo;

    if (component && component.isNavigateTo) {
      isNavigateTo = component.isNavigateTo;
    }
    /**Check if admin */
    if (
      this.settingService.isAdmin() ||
      this.settingService.isCoach() ||
      (component && component.info.mode === "view")
    ) {
      isNavigateTo = true;
    }

    if (!component) {
      isNavigateTo = true;
    }

    return isNavigateTo || !(component && component.info.isFormChanged);
  }
}
