import { Moco } from "../shared/model/moco";

export interface LoggerInterface {
  title: string;
  created_at: string;
  logs: any[];
}
export interface LogInterface {
  title: string;
  created_at: string;
  data: string;
  _data?: any[];
}

export class Logger extends Moco {
  title: string;
  loggedInTime: string;
  logs: any[];
  isDisplayable: boolean;

  constructor(
    log: LoggerInterface = {
      title: "",
      created_at: "",
      logs: [],
    }
  ) {
    super();
    this.isDisplayable = false;
    this.title = log.title;
    this.loggedInTime = this.toLocale(log.created_at);
    this.logs = log.logs.map((log) => new Log(log));
  }
}

export class Log extends Moco {
  title: string;
  loggedInTime: string;
  data: string | boolean;
  _data: any[];
  isDisplayable: boolean;

  constructor(
    log: LogInterface = {
      title: "",
      created_at: "",
      data: "",
      _data: [],
    }
  ) {
    super();

    this.title = log.title;
    this.loggedInTime = this.toLocale(log.created_at);
    this.data = log.data ? JSON.stringify(log.data) : false;
    this._data = log._data ? log._data.map((data) => new LogData(data)) : [];
    this.isDisplayable = false;
  }
}

export interface LogDataInterface {
  created_at: string;
  data: any;
  title: string;
}

export class LogData extends Moco {
  createdAt: string;
  data: any;
  title: string;
  isDisplayable: boolean;

  constructor(
    log: LogDataInterface = {
      created_at: "",
      data: {},
      title: "",
    }
  ) {
    super();
    this.createdAt = this.toLocale(log.created_at);
    this.title = log.title;
    this.data = log.data;
    this.isDisplayable = false;
  }
}
